import PubNub from 'pubnub';
import { v4 as uuid } from 'uuid'; 

class NewtorkComponent {
  constructor(componentName) {
  	this.componentName = componentName;
    this.subscribedChannels = {};
    this.requests = {};
    this.MessageType = {
      REQUEST:  'REQUEST',
      RESPONSE: 'RESPONSE',
    };
  }

  async connectToNetworkWithHandlers(requestHandlers) {
    this.requestHandlers = requestHandlers;

    this.pubnub = new PubNub({
      publishKey:   'pub-c-04468f16-b235-481e-8856-ee3a03d02bef',
      subscribeKey: 'sub-c-d48b0fae-faf0-11ea-8db0-569464a6854f',
      uuid:         this.componentName, 
    });

    const requestChannel = this.getRecipientRequestChannel(this.componentName);
    const responseChannel = this.getRecipientResponseChannel(this.componentName);

    this.pubnub.subscribe({
      channels: [requestChannel, responseChannel],
    })

    this.pubnub.addListener({
      message: async msg => {
        const messageType = msg.message.messageType;
        if (messageType === this.MessageType.REQUEST) {
          await this.processRequestMessage(msg.message);
        } else if (messageType === this.MessageType.RESPONSE) {
          this.processResponseMessage(msg.message);
        } else {
          console.log(`Unknown Message Type ${messageType}`); 
        }
      },
    });
  }

  sendRequest(recipient, requestType, body) {
    const requestId = uuid();
    return new Promise((resolve) => {
      this.requests[requestId] = resolve;
      const channelRecipient = this.getRecipientRequestChannel(recipient);
      const channelMessage = {
        messageType: this.MessageType.REQUEST,
        requestId:   requestId,
        sender:      this.componentName,
        requestType: requestType, 
        body:        body,
      };
      this.sendPubNubMessage(channelRecipient, channelMessage);
    });
  }

  async disconnectFromNetwork() {
    this.pubnub.unsubscribe({
      channels: Object.keys(this.subscribedChannels), 
    }); 
  }

  // ///////////////////////////////////////////////////////////////////////////
  // Private Functions
  async processRequestMessage(message) {
    const {
      requestId,
      requestType,
      body,
      sender,
    } = message;
    console.log(`${this.componentName} Request: ${requestType}`);
    const requestHandler = this.requestHandlers[requestType];
    if (!requestHandler) {
      console.log(
        `Unknown Request Type: ${requestType} for component ${this.componentName}`
      );
      return;
    }

    const response = await requestHandler(body);
    this.sendResponseToComponent(sender, requestId, response);
  }

  processResponseMessage(message) {
    const {
      requestId,
      response,
    } = message;
    const existingRequest = this.requests[requestId];
    if (!existingRequest) {
      console.log(
        `No Request for id: ${requestId} for component ${this.componentName}`
      );
      return;
    }
    existingRequest(response);
    delete this.requests[requestId];
  }

  sendResponseToComponent(recipient, requestId, response) {
    const channelRecipient = this.getRecipientResponseChannel(recipient);
    const channelMessage = {
      messageType: this.MessageType.RESPONSE,
      requestId:   requestId,
      sender:      this.componentName,
      response:    response,
    };
    this.sendPubNubMessage(channelRecipient, channelMessage);
  }

  getRecipientResponseChannel(recipient) {
    return recipient + '_response';
  }

  getRecipientRequestChannel(recipient) {
    return recipient + '_request';
  }

  sendPubNubMessage(channelRecipient, channelMessage) {
    this.pubnub.publish({
      channel: channelRecipient,
      message: channelMessage,
    }, function(status, response) {
    });
  }
}

export default NewtorkComponent;
