import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AccountHeader from './AuthenticatedWrapper/AccountHeader/AccountHeader';
import ToolTypeSelector from './ToolManager/ToolTypeSelector';

import {ToolType} from './../Shared/TapestryConstants/Enums';
import UrlServiceServer from './../Shared/Network/UrlService/UrlServiceServer';
import MapServiceServer from './../Shared/Network/MapService/MapServiceServer';
import PersonalLibrary from './Tmp/PersonalLibrary';

import wrapperWithDimensions from './../Shared/HOC/wrapperWithDimensions';

import WorkspaceManager from './WorkspaceManager/WorkspaceManager';
import MapTimelineTool from './MapTimelineTool';
// import Workspace from './Workspace';

import Api from './../MockApi/Api.js';

class AuthenticatedWrapper extends React.Component {
  static propTypes = {
    userId:        PropTypes.string.isRequired,
    tabId:         PropTypes.string.isRequired,
    logoutHandler: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	toolType: undefined,
    };
  }

	async componentDidMount() {
		const userTabState = await Api.getUserTabState(this.props.userId,
																									 this.props.tabId);
		this.setState({toolType: userTabState.toolType});
    this.urlServiceServer = new UrlServiceServer(); 
    this.mapServiceServer = new MapServiceServer(); 
	}

  async componentWillUnmount() {
    if (this.urlServiceServer) {
      await this.urlServiceServer.destroy();
    }
    if (this.mapServiceServer) {
      await this.mapServiceServer.destroy();
    }
  }

  selectToolType = async toolType => {
  	// Set Tool Type To Tab State
  	// set it to component state
  	await Api.editToolTypeForUserTabState(this.props.userId, this.props.tabId,
  																				toolType);
  	this.setState({toolType: toolType});
  }

  render() {
  	const {
  		logoutHandler,
  	} = this.props;
  	let visibleChild;
    if (!this.state.toolType) {
      visibleChild = (
        <ToolTypeSelector 
			    goToChooseAWorkspace={() => this.selectToolType(ToolType.WORKSPACE_MANAGER)}
			    goToSourceLibrary={() => this.selectToolType(ToolType.SOURCE_LIBRARY)}
        />
      );
    } else if (this.state.toolType === ToolType.WORKSPACE_MANAGER) {
      visibleChild = (
        <WorkspaceManager
          goToChooseAWorkspace={() => this.selectToolType(ToolType.WORKSPACE)}
          goToSourceLibrary={() => this.selectToolType(ToolType.SOURCE_LIBRARY)}
        />
      );
    } else if (this.state.toolType === ToolType.WORKSPACE) {
      visibleChild = (
        <MapTimelineTool
          userId={this.props.userId}
          tabId={this.props.tabId}
        />
      );
      /*
      visibleChild = (
        <Workspace
          userId={this.props.userId}
          tabId={this.props.tabId}
        />
      );
      */
    } else if (this.state.toolType === ToolType.SOURCE_LIBRARY) {
      visibleChild = (
        <PersonalLibrary
        />
      );
    }
  	const headerPxHeight = 53;
  	const mapPxHeight = this.props.parentHeight - headerPxHeight;
    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}>
      	<div className='header' style={{height: `${headerPxHeight}px`}}>
		    	<AccountHeader 
		    	  toolType={this.state.toolType}
	          logoutHandler={logoutHandler}
	          removeToolTypeHandler={() => this.selectToolType(null)}/>
		    </div>
        <div 
          className={classnames({
            'fill-parent-width':  true,
          })}
          style={{height: `${mapPxHeight}px`}}>
		    	{visibleChild}
		    </div>
    	</div>
    );
  }
}

export default wrapperWithDimensions(AuthenticatedWrapper);
