import { v4 as uuid } from 'uuid'; 

import IndexedDbCollection from './../../../Shared/IndexedDb/IndexedDbCollection'; 
import BasicQueue from './../../../Shared/Utilities/BasicQueue'; 

class AuditTrailService {
  constructor() {
		// this.serverName = serverName;
		this.auditTrailCollection = new IndexedDbCollection('', 'audit_trail', 'id');	
    this.collectionEditQueue = new BasicQueue();
		this.lockService = {};
  }

  isDocumentLocked(collectionName, docId) {
  	return this.lockService[collectionName + docId];
  }

  lockDocWithId(collectionName, docId) {
  	this.lockService[collectionName + docId] = true;
  }

  unlockDocWithId(collectionName, docId) {
  	this.lockService[collectionName + docId] = false;
  }

  addTrailEntry(editorId, changeDateTime, recordType, recordId, fieldName,
  					    oldValue, newValue) {
  	const newDoc = {
  		id: uuid(),// Any CRUD operation will need to have a unique transaction code (like GUID) (mandatory)
  		editorId,  // Application user unique identifier representing who made the change. (mandatory)
			changeDateTime, // Transaction date. (mandatory)
			recordType,     // Entity (table) that is being manipulated.(mandatory)
			recordId,       // Entity that is being manipulated primary key.
			fieldName,     // Entity field name.
			oldValue,      // Entity field old value.
			newValue,      // Entity field new value
			// OperationType: CRUD operation discriminator. (mandatory)
  	};
    const promiseGenerator = () => {
      return this.auditTrailCollection.add(newDoc);  
    };
    return this.collectionEditQueue.addToQueue(promiseGenerator);
  }
}

const instance = new AuditTrailService();
Object.freeze(instance);

export default instance;

