import CryptoUtil from './CryptoUtil';

const WebCrypto = {
	importPublicKey,
	verifySignature,
};

async function verifySignature(publicKey, stringSignature, data) {
  const signature = CryptoUtil.getSignatureFromString(stringSignature);
  return verifySignatureWithKey(publicKey, signature, data);
}

async function verifySignatureWithKey(publicKey, signature, data) {
  const dataString = data.toString();
  const encodedMessage = CryptoUtil.getMessageEncoding(dataString);
  const signingAlgorithm = {
    name: 'ECDSA',
    hash: 'SHA-512', 
  };
  return window.crypto.subtle.verify(signingAlgorithm, publicKey,
                                     signature, encodedMessage);
}

async function importPublicKey(jwk) {
  return window.crypto.subtle.importKey(
    "jwk",
    jwk,
    {
      name: "ECDSA",
      namedCurve: "P-384"
    },
    jwk.ext,
    jwk.key_ops 
  );
}

export default WebCrypto;
