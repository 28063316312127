import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectAndHide from './SelectAndHide';

// import './AccountHeader.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

class PersonalLibraryHeader extends React.Component {

  static propTypes = {
    selectedViewOption: PropTypes.string.isRequired,
    viewOptionSelected: PropTypes.func.isRequired,
    viewOptions:        PropTypes.array.isRequired,
    switchAllowed:      PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidUpdate(prevProps) {

  }

  render() {
    const {
      viewOptions,
      selectedViewOption,
      viewOptionSelected,
      switchAllowed,
    } = this.props;
  	return (
  		<div className="account-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
          <SelectAndHide
            options={viewOptions}
            selectValue={viewOptionSelected}
            selectedValue={selectedViewOption}
            switchAllowed={switchAllowed}
          />
      	</div>
        <div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'right'}}>
          <button
            onClick={() => this.props.showAddNoteForm()}>
            Organize Your Library With Workspaces 
          </button>
        </div>
  		</div>
	 	);
  }
};
export default PersonalLibraryHeader;
