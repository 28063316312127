import React from 'react';
import PropTypes from 'prop-types';

import EditEventForm from '../EditEventForm/EditEventForm';
import FeatureGroupSelection from '../FeatureGroupSelection/FeatureGroupSelection';
import HeaderWithButton from '../../Common/Header/HeaderWithButton';
import ScrollableWithHeader from '../../Common/ScrollableWithHeader/ScrollableWithHeader';
import StoryDetailsSidepanel from '../StoryDetailsSidepanel/StoryDetailsSidepanel';

import CCM from '../../../Shared/Utilities/CommonClassMethods';
import Api from '../../../MockApi/Api';

import './EventsPanel.css';


class EventsPanel extends React.Component {

  static propTypes = {
    draftCreated:               PropTypes.func.isRequired,
    userTabStateId:             PropTypes.string.isRequired,
    selectedStory:              PropTypes.object.isRequired,
    eventDraftUpdated:          PropTypes.func.isRequired,
    selectedStoryEventsUpdated: PropTypes.func.isRequired,
    eventDraft:                 PropTypes.object,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	creatingEvent:        false,
      editingEvent:         false,
      eventDraft:           null,
      selectingMapFeatures: false,
    };

    if (props.eventDraft) {
      if (props.eventDraft.typeId) {
        this.state.editingEvent = true;
      } else {
        this.state.creatingEvent = true;
      }
      this.state.eventDraft = props.eventDraft;
      if (props.eventDraft.intermediateState.featureGroup) {
        this.state.selectingMapFeatures = true;
        // Delete These On Cancellation Of Location Selection
        this.state.initialFeatureGroup = props.eventDraft.intermediateState.featureGroup;
        this.state.featureGroupHash = this.state.initialFeatureGroup.hash;
      }
    }


    this.toggleVar = CCM.toggleVar.bind(this);
    this.toggleVarWithLoadingTimeout = CCM.toggleVarWithLoadingTimeout.bind(this);
  }

  addEventToStoryAndHideForm = async eventId => {
    await Api.addEventIdToStory(this.props.selectedStory.id, eventId);
    await (this.deleteEventDraft('creatingEvent'))();
    this.props.selectedStoryEventsUpdated('event_added');
  }

  updateEventInStoryAndHideForm = async eventId => {
    await (this.deleteEventDraft('editingEvent'))();
    this.props.selectedStoryEventsUpdated('event_updated', eventId);
  }

  openEventForm = (formType, eventId) => {
    return async() => {
      let eventDraft;
      if (formType === 'creatingEvent') {
        eventDraft = await Api.createEventDraft({});
      } else {
        const eventToEdit = this.props.selectedStory.events.find(event => {
          return event.id === eventId;
        });
        // How To Do Drafts
        eventDraft = await Api.createEventDraft(eventToEdit);
      }
      await this.toggleVar(formType, {eventDraft});
      await this.props.draftCreated(eventDraft.id);
    }  
  }

  showMapFeatureGroupForm = async existingGroupHash => {
    console.log(existingGroupHash);
    const draftId = this.state.eventDraft.id;
    const options = {};
    if (existingGroupHash) {
      options.existingGroupHash = existingGroupHash;
    } else if (this.props.selectedStory.mapPaths &&
               this.props.selectedStory.mapPaths.length > 0) {
      options.defaultMapPath = this.props.selectedStory.mapPaths[0];
    }
    const updatedDraft = await Api.setEventDraftLocationGroupWithHash(draftId,
                                                                      options);

    const initialFeatureGroup = updatedDraft.intermediateState.featureGroup;
    const updatedState = {
      initialFeatureGroup,
      eventDraft: updatedDraft,
    };
    this.toggleVar('selectingMapFeatures', updatedState);
    await this.props.eventDraftUpdated();
  }
  removeEventIdFromStory = async eventId => {
    await Api.removeEventIdFromStory(this.props.selectedStory.id, eventId);
    this.props.selectedStoryEventsUpdated('event_removed');
  }

  deleteEventDraft = eventVar => {
    return async () => {
      const draftId = this.state.eventDraft.id;
      console.log(draftId);
      await Api.deleteDraftWithId(draftId);
      await this.toggleVar(eventVar, {eventDraft: null});
      await this.props.eventDraftUpdated('draft_deleted');
    }
  }

  cancelSelection = async () => {
    const draftId = this.state.eventDraft.id;
    const uDraft = await Api.removeEventDraftLocationGroupFromInter(draftId);
    await this.toggleVar('selectingMapFeatures', {eventDraft: uDraft});
    await this.props.eventDraftUpdated();
  }

  saveSelection = async () => {
    const draftId = this.state.eventDraft.id;
    const uDraft = await Api.removeEventDraftLocationGroupFromInter(draftId,
                                                                    true);
    await this.toggleVar('selectingMapFeatures', {eventDraft: uDraft});
    await this.props.eventDraftUpdated();
  }

  render() {
    if (this.state.loading) {
      return (<div>Loading</div>);
    }
    if (this.state.selectingMapFeatures) {
      let featureGroupHash;
      if (this.state.eventDraft.intermediateState.featureGroup) {
        featureGroupHash = this.state.eventDraft.intermediateState.featureGroup.hash;
      }
      return (
        <FeatureGroupSelection
          featureGroupHash={featureGroupHash}
          eventDraftId={this.state.eventDraft.id}
          eventDraftUpdated={this.props.eventDraftUpdated}
          featureGroup={this.state.initialFeatureGroup}
          mapPaths={this.props.selectedStory.mapPaths}
          cancelSelection={this.cancelSelection}
          saveSelection={this.saveSelection}
        />
      );
    } else if (this.state.creatingEvent) {
      return (
        <ScrollableWithHeader 
          headerTitle="Creating Event"
          headerButtonHandler={
            () => this.toggleVarWithLoadingTimeout(this.deleteEventDraft('creatingEvent'))
          }
          headerButtonName="Cancel"
          noScrolling={true}
        >
          <EditEventForm
            showMapFeatureGroupForm={this.showMapFeatureGroupForm}
            mapPaths={this.props.selectedStory.mapPaths}
            hideForm={
              () => this.toggleVarWithLoadingTimeout(this.deleteEventDraft('creatingEvent'))
            }
            storyId={this.props.selectedStory.id}
            eventDraftUpdated={this.props.eventDraftUpdated}
            eventDraftId={this.state.eventDraft.id}
            event={this.state.eventDraft.editedState}
            saveEventChangesHandler={() => {}}
            createEventHandler={this.addEventToStoryAndHideForm}
          />
        </ScrollableWithHeader>
      );
    } else if (this.state.editingEvent) {
      return (
        <div>
          <HeaderWithButton
            title='Editing Event'
            height={45}
          />
          <EditEventForm
            showMapFeatureGroupForm={this.showMapFeatureGroupForm}
            mapPaths={this.props.selectedStory.mapPaths}
            hideForm={
              () => this.toggleVarWithLoadingTimeout(this.deleteEventDraft('editingEvent'))
            }
            storyId={this.props.selectedStory.id}
            eventDraftUpdated={this.props.eventDraftUpdated}
            eventDraftId={this.state.eventDraft.id}
            event={this.state.eventDraft.editedState}
            saveEventChangesHandler={this.updateEventInStoryAndHideForm}
            createEventHandler={() => {}}
          />
        </div>
      );
    } else {
      return (
        <StoryDetailsSidepanel
          events={this.props.selectedStory.events}
          openEventForm={
            (formType, eventId) => this.toggleVarWithLoadingTimeout(this.openEventForm(formType, eventId))
          }
          removeEventFromStory={this.removeEventIdFromStory}
        />
      );
    }
  }
};
export default EventsPanel;
