import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './../../Shared/Icons/Icon';
import TapestryClient from './../../Shared/Network/Tapestry/TapestryClient';
import BrowserStorage from './../../Shared/Utilities/BrowserStorage';
import CommonClassMethods from './../../Shared/Utilities/CommonClassMethods';

class TapestryLogin extends React.Component {
  static propTypes = {
  	smartKeysSet:                      PropTypes.bool.isRequired,
  	authenticatedWithIdentityProvider: PropTypes.bool.isRequired,
    loggedInWithUserId:                PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      authenticatedWithTapestry: false,
      rememberMe:                BrowserStorage.local.getItemOrDefault('tapestry_rememberMe', false),
    };
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

	async componentDidMount() {
    this.tapestryClient = new TapestryClient(); 
	}

  async componentWillUnmount() {
    await this.tapestryClient.destroy();
  }

  async beginLogin() {
    await this.toggleVar('authenticatingWithTapestry');
    // TODO: Request Nonce from tapestry server
    let extResponse;
    try {
      const body = {
        payload: {
          nonce:       1,
          serviceName: 'Tapestry',
        },
      };
      extResponse = await this.tapestryClient.getUserIdFromIdentityProvider(body);
    } catch (e) {
      console.log(e);
      // Signature request denied
      await this.updateStringField('messageType', '');
      return;
    }

    let tapResponse;
    try {
      const body = extResponse.payload;
      tapResponse = await this.tapestryClient.requestTokenForPartyId(body);
    } catch (e) {
      console.log(e);
      await this.updateStringField('messageType', '');
      return;
    }

    console.log(tapResponse);

    this.props.loggedInWithUserId(tapResponse.payload.partyId);
  }

  updateRememberMe = async () => {
    await this.toggleVar('rememberMe');
    BrowserStorage.local.setItem('tapestry_rememberMe', this.state.rememberMe);
    if (!this.state.rememberMe) {
      BrowserStorage.local.setItem('userInfo', null);
    }
  }

  render() {
    const wrapperClasses = {
      'base-padding':       true,
      'fill-parent-height': true,
      'white-border':       true,
    };
    const wrapperHeader = (
      <div style={{fontSize: '45px', 'marginBottom': '10px'}}>
        <Icon iconType="tapestry-logo"/>
        Tapestry
      </div>
    );
  	const {
  		authenticatedWithIdentityProvider,
  		smartKeysSet,
  	} = this.props;
  	if (!authenticatedWithIdentityProvider) {
	    return (
	      <div className={classnames(wrapperClasses)}>
          {wrapperHeader}
          <div className={classnames({'inline-display': true})}>
            <div>
              Login to Identity Provider to begin 
            </div>
          </div>
	      </div>
      );
  	} else if (!smartKeysSet) {
      return (
        <div className={classnames(wrapperClasses)}>
          {wrapperHeader}

          <div className={classnames({'inline-display': true})}
            >
            <div>
              Set Smart Keys (signing and ecryption) to login or redeem invitation
            </div>
          </div>
        </div>
      );
    } else if (this.state.authenticatingWithTapestry) {
      return (
        <div className={classnames(wrapperClasses)}>
          {wrapperHeader}

          <div className={classnames({'inline-display': true})}>
            <div style={{'marginBottom': '10px'}}>
            </div>
            <div>
              Authenticating 
            </div>
          </div>
        </div>
      );
    } else if (!this.state.authenticatedWithTapestry) {
      return (
        <div className={classnames(wrapperClasses)}>
          {wrapperHeader}

          <div className={classnames({'inline-display': true})}>
            <div style={{'marginBottom': '10px'}}>
            </div>
            <div> 
             <label>
                Remember Me 
                <input
                  name="tapestryLoginRememberMe"
                  type="checkbox"
                  checked={this.state.rememberMe}
                  onChange={this.updateRememberMe} />
              </label>
            </div>
            <button
              onClick={() => this.beginLogin()}>
              Login 
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classnames(wrapperClasses)}>
          {wrapperHeader}

          <div className={classnames({'inline-display': true})}>
            <div style={{'marginBottom': '10px'}}>
            </div>
            <div>
              Logged In 
            </div>
          </div>
        </div>
      );

    }
  }
};

export default TapestryLogin;
