import React from 'react';
import PropTypes from 'prop-types';

// import './HeaderWithButton.css';

class EditActionButton extends React.Component {

  static propTypes = {
  	type:    PropTypes.string.isRequired,
  	title:   PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
  	const {
  		title,
  		onClick,
  	} = this.props;
  	return (
      <button
        style={{lineHeight: '20px'}}
        onClick={onClick}>
        {title} 
      </button>
	 	);
  }
}
export default EditActionButton;
