import React from 'react';
import PropTypes from 'prop-types';

import './HeaderWithButton.css';

class HeaderWithButton extends React.Component {

  static propTypes = {
  	title:         PropTypes.string.isRequired,
    height:        PropTypes.number.isRequired,
    marginTop:     PropTypes.number,
    buttonHandler: PropTypes.func,
    buttonName:    PropTypes.string,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
  	const {
  		title,
  		height,
  		marginTop,
  		buttonHandler,
  		buttonName
  	} = this.props;
    const headerStyles = {
      border:    'solid 1px white',
      padding:   '10px',
      height:    `${height}px`,
      fontSize:  '20px',
      color:     'white',
      boxSizing: 'border-box',
    };
    if (marginTop) {
      headerStyles.marginTop = `${marginTop}px`;
    }
  	return (
      <div style={headerStyles}>
      	{title}
       	{buttonHandler &&
          <button
            style={{float: 'right', position: 'absolute', lineHeight: '20px', right: '10px'}}
            onClick={buttonHandler}>
            {buttonName} 
          </button>
       	}
      </div>
	 	);
  }
}
export default HeaderWithButton;
