import { v4 as uuid } from 'uuid'; 

import BrowserCardManager from './../KeyManagers/BrowserCardManager';
import BrowserStorage from './../Shared/Utilities/BrowserStorage';
// Store Smart Cards As ID and Name Objects.
// When One Is Loaded, create the BrowserCard Manager With the id and then use
// it from there


class SmartCardService {
  constructor() {
    this.BrowserCardStorageEnum = 'BrowserCards';
    this.SelectedKeysLS = {
    	enum:    'SelectedKeys',
    	default: {
  			encrypt: {cardId: '', keyId: ''},
  			sign:    {cardId: '', keyId: ''},
  		},
    };
    this.signatureRequests = {};
    this.SignatureRequests = {
      enum:    'SignatureRequests',
      default: {
      },
    };
    this.handlers = {};
    this.handlers[this.SelectedKeysLS.enum] = {};
    this.handlers[this.SignatureRequests.enum] = {};
  }

  async addPublicKeyAndRequestSignature(payload, name) {
    const exportablePublicKey = await this.getPublicSigningKey();
    payload.publicSigningKey = exportablePublicKey;
    let stringSignature;
    try {
      stringSignature = await this.requestSignature(payload, name);
    } catch (e) {
      throw new Error('Signature Rejected');
    }
    payload.signature = stringSignature;
    return {
      payload,
      signature: stringSignature,
    };
  }

  async approveSignatureForRequestWithId(requestId) {
    const signatureRequests = this.getSignatureRequests();
    const signatureRequest = signatureRequests[requestId];
    const requestPayload = signatureRequest.payload;
    const stringSignature = await this.signPayload(requestPayload);
    signatureRequest.approved(stringSignature);
    signatureRequest.addressed = true;
    delete signatureRequests[requestId];
    this.updateSignatureRequests(signatureRequests);
  }

  rejectSignatureRequestWithId(requestId) {
    const signatureRequests = this.getSignatureRequests();
    const signatureRequest = signatureRequests[requestId];
    signatureRequest.reject();
    delete signatureRequests[requestId];
    this.updateSignatureRequests(signatureRequests);
  }

  requestSignature(payload, signatureName) {
    return new Promise((resolve, reject) => {
      const signatureRequests = this.getSignatureRequests();
      const requestId = uuid();

      signatureRequests[requestId] = {
        payload,
        addressed: false,
        name:      signatureName,
        approved:  stringSignature => {
          resolve(stringSignature);
        },
        reject: reject
      };
      this.updateSignatureRequests(signatureRequests);
    });
  }

  getDisplayId(id) {
  	return id.split('-')[0]; 
  }

	setHandlerForKeyChanges(handlerName, handler) {
		this.handlers[this.SelectedKeysLS.enum][handlerName + '_selectedKeys'] = handler;
	}

  setHandlerForSignatureRequests(handlerName, handler) {
    this.handlers[this.SignatureRequests.enum][handlerName + '_signatureRequests'] = handler;
  }

	removeHandlerForKeyChanges(handlerName) {
		delete this.handlers[this.SelectedKeysLS.enum][handlerName + '_selectedKeys'];
	}

  removeHandlerForSignatureRequests(handlerName) {
    delete this.handlers[this.SelectedKeysLS.enum][handlerName + '_signatureRequests'];
  }

  unselectKeyForType(keyType) {
  	const selectedKeys = this.getSelectedKeys();
  	selectedKeys[keyType] = {
  		cardId: '',
  		keyId:  '',
  	};
  	this.saveSelectedKeys(selectedKeys);
  }

  getKeyForType(keyType) {
    // TODO: Ensure key is set or throw error
    const selectedKeys = this.getSelectedKeys();
    return selectedKeys[keyType];
  }

  async signPayload(payload) {
    const keyInfo = this.getKeyForType('sign');
    // TODO: Save Smartcard type in info to diversify lookup 
    const cardId = keyInfo.cardId;
    const smartCard = new BrowserCardManager(cardId);
    const keyId = keyInfo.keyId;
    const signature = await smartCard.sign(keyId, payload);
    const stringSignature = btoa(String.fromCharCode(...new Uint8Array(signature)));
    return stringSignature;
  }

  async getPublicSigningKey() {
    const keyInfo = this.getKeyForType('sign');
    // TODO: Save Smartcard type in info to diversify lookup 
    const cardId = keyInfo.cardId;
    const smartCard = new BrowserCardManager(cardId);
    const keyId = keyInfo.keyId;
    const publicKey = await smartCard.getStoredKeyOfTypeForIdExportable(keyId, 'public');
    return publicKey;
  }
  async deleteCardWithId(cardId) {
  	const selectedKeys = this.getSelectedKeys();
  	Object.keys(selectedKeys).forEach(keyType => {
  		if (selectedKeys[keyType].cardId === cardId) {
	  		this.unselectKeyForType(keyType);	
  		}
  	});
  	const smartCard = new BrowserCardManager(cardId);
  	const keyIds = await smartCard.getKeyIdentifiers();
  	const promiseChain =  keyIds.reduce((promise, keyId) => {
  		return promise.then(() => {
				return smartCard.deleteKeySetForIdentifier(keyId);
  		});
  	}, Promise.resolve());
  	await promiseChain;
    const browserCards = this.getBrowserCards(); 
    delete browserCards[cardId]; 
    this.saveBrowserCards(browserCards);
  }

  selectKeyForType(smartCardId, keyType, keyId) {
  	const selectedKeys = this.getSelectedKeys();
  	selectedKeys[keyType] = {
  		cardId: smartCardId,
  		keyId:  keyId,
  	};
  	this.saveSelectedKeys(selectedKeys);
  }

  getSignatureRequests(filterAddressed = false) {
    return this.signatureRequests;
    /*
    return BrowserStorageService.local.getItemOrDefault(this.SignatureRequests.enum,
                                                        this.SignatureRequests.default);
                                                        */
  }

  getSelectedKeys() {
  	return BrowserStorage.local.getItemOrDefault(this.SelectedKeysLS.enum,
  																							 this.SelectedKeysLS.default);
  }

  updateSignatureRequests(signatureRequests) {
    this.signatureRequests = signatureRequests;
    // BrowserStorageService.local.setItem(this.SignatureRequests.enum, signatureRequests);
    Object.values(this.handlers[this.SignatureRequests.enum]).forEach(handler => {
      handler();
    });
  }


  saveSelectedKeys(selectedKeys) {
  	BrowserStorage.local.setItem(this.SelectedKeysLS.enum, selectedKeys);
  	Object.values(this.handlers[this.SelectedKeysLS.enum]).forEach(handler => {
  		handler();
  	});
  }

  createSigningKeyPair(cardId, keyName = '') {
  	const smartCard = new BrowserCardManager(cardId);
  	return smartCard.createSigningKeyPair(keyName);
  }

  createEncryptingKeyPair(cardId, keyName = '') {
  	const smartCard = new BrowserCardManager(cardId);
  	return smartCard.createEncryptingKeyPair(keyName);
  }

  async getKeysForSmartCard(cardId) {
  	const smartCard = new BrowserCardManager(cardId);
  	return smartCard.getKeyIdsWithNames();
  }

  async deleteCardKeyWithId(cardId, keyId) {
  	const smartCard = new BrowserCardManager(cardId);
  	return smartCard.deleteKeySetForIdentifier(keyId);
  }

  async getExistingCards() {
  	return Object.values(this.getBrowserCards());
  }

  getBrowserCards() {
  	return BrowserStorage.local.getItemOrDefault(this.BrowserCardStorageEnum, {});
  }

  createNewBrowserCard(cardName) {
    const smartCard = {
      id:   uuid(),
      name: cardName,
    };
    const browserCards = this.getBrowserCards(); 
    browserCards[smartCard.id] = smartCard; 
    this.saveBrowserCards(browserCards);
  }

  saveBrowserCards(browserCards) {
  	return BrowserStorage.local.setItem(this.BrowserCardStorageEnum,
  																			browserCards);
  }

  createNewBrowserKeyPair(keyName) {
  	return BrowserCardManager.createEncryptingKeyPair(keyName);
  }

  async listCards() {
  	// 
  	return this.returnMockCards();
  }

  returnMockCards() {
  	return [
  		{
  			usbSlot:        1,
  			cardIdentifier: 2,
  			keyId:          3,
  		}
  	];

  }

  async generateCard() {
  	//
  }
  // ///////////////////////////////////////////////////////////////////////////
  // Testing Functions
  async testExportAndImportSignature(payload) {
    const keyInfo = this.getKeyForType('sign');
    // TODO: Save Smartcard type in info to diversify lookup 
    const cardId = keyInfo.cardId;
    const smartCard = new BrowserCardManager(cardId);
    const keyId = keyInfo.keyId;
    const signature = await smartCard.sign(keyId, payload);
    console.log('original signature');
    console.log(signature);
    const stringSignature = btoa(String.fromCharCode(...new Uint8Array(signature)));
    console.log('String signature');
    console.log(stringSignature);
    console.log(typeof stringSignature);
    console.log(stringSignature.length);
    console.log('encoded signature');
    const reencodedSignature = Uint8Array.from(atob(stringSignature), c => c.charCodeAt(0))
    console.log(reencodedSignature);
    console.log(reencodedSignature === signature);
    const response = await smartCard.verifySignature(keyId, reencodedSignature, payload);
    console.log('verification');
    console.log(response);
    return signature;

  }
}

export default new SmartCardService();
