import RecordWithHistory from './../RecordTypes/RecordWithHistory';
import SourceService from './../AtomicContent/SourceService';

import AuthService from './../../AuthService';

const UserSourceDef = {
  userId:    { type: 'id', collection: 'User', hashable: true },
  sourceId:  { type: 'id', collection: 'Source', hashable: true },
  dateAdded: { type: 'date' },
};

class UserSourceService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'user_source', UserSourceDef, true);
  }

  async getAllUserSources() {
    const userId = AuthService.getUserId();
    const sources = await super.findWithField('userId', userId);
    return sources.sort((a,b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      } else if (a.createdAt > b.createdAt) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  async getUserSourcesWithSearchParameters({searchString, startDateTime, endDateTime}) {
    // Match Search String To Description
    // Match Search String To Title
    // Think about user dictionary and dictionary settings
    const sources = await this.getAllUserSources();
    const filtered = sources.filter(source => {
      return this.filterSourceWithSearchParameters(source, {searchString, startDateTime, endDateTime});
    }); 
    return filtered;
  }

  filterSourceWithSearchParameters(source, {searchString, startDateTime, endDateTime}) {
    console.log('#####################');
    console.log(source.createdAt);
    if (startDateTime && source.createdAt < startDateTime) {
      console.log('No Go');
      console.log(startDateTime);
      return false;
    }
    if (endDateTime && source.createdAt > endDateTime) {
      console.log('No Go 2');
      console.log(endDateTime);
      return false;
    }
    if (!searchString) {
      console.log('Should be good');
      return true;
    }
    if (source._link.includes(searchString)) {
      return true;
    } else if (source._openGraphInfo) {
      const openGraphInfo = source._openGraphInfo;
      const optionalFields = ['ogSiteName', 'ogTitle'];
      const someMatch = optionalFields.some(fieldName => {
        if (openGraphInfo[fieldName] && openGraphInfo[fieldName].includes(searchString)) {
          return true;
        } 
        return false;
      });
      return someMatch;
    }
      console.log('No Go x');
    return false;
  }

  async findOrCreateSourceWithLink(link) {
  	const userId = AuthService.getUserId();
		const source = await SourceService.findOrCreateSourceWithLink(link);
		const queryObj = {
			userId,
			sourceId: source.id,
		};
    const existingUserSource = await super.findOneWithFields(queryObj)
    if (existingUserSource) {
    	return {
    		status: 'found',
    		existingUserSource,
    	};
    }
    // Notes: Add Source Hash to track version
		const userSourceObj = {
			userId:         userId,
			sourceId:       source.id,
			// Duplicate Fields For Queries
			_link:          source.uri,
			_openGraphInfo: source.openGraphInfo,
		};
		const newUserSource = await super.createNewRecord(userSourceObj);
  	return {
  		status: 'created',
  		newUserSource,
  	};
  }
}

export default (new UserSourceService());
