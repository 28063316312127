import React from 'react';
import PropTypes from 'prop-types';

import CommonClassMethods from '../../../Shared/Utilities/CommonClassMethods';

import Api from '../../../MockApi/Api.js';

import './SelectedStoryHeader.css';

class SelectedStoryHeader extends React.Component {

  static propTypes = {
  	selectedStory:           PropTypes.object.isRequired,
  	unselectStory:           PropTypes.func.isRequired,
    deleteStory:             PropTypes.func.isRequired,
    selectingStoryMaps:      PropTypes.bool.isRequired,
    toggleStoryMapSelection: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	deleteStoryModalIsOpen: false,
      editingEvent:           false,
      creatingEvent:          false,
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  deleteStory = async () => {
    // TODO: When allowed to add story to multiple workspace,
    // This will have to delete them from all those other workspaces
    await Api.deleteStoryWithId(this.props.selectedStory.id);
    this.props.deleteStory('deleted', this.props.selectedStory.id);
  }


  render() {
  	return (
  		<div className="selected-story-header">
      	<div
      		style={{color: 'white', height: '20px', paddingBottom: '5px'}}>
          <div style={{textAlign: 'left', display: 'inline-block', width: '50%'}}>
      		  Story: {this.props.selectedStory.name}
            <button
              style={{marginLeft: '20px'}}
              onClick={this.props.unselectStory}>
              Close Story  
            </button>
            { !this.props.selectingStoryMaps && 
              <button
                onClick={this.props.toggleStoryMapSelection}>
                Change Map
              </button>
            }
          </div>
          <div style={{textAlign: 'right', display: 'inline-block', width: '50%'}}>
            <button
              style={{ background: 'red', color: 'white'}}
              onClick={() => this.toggleVar('deleteStoryModalIsOpen')}>
              Delete Story  
            </button>
          </div>
      	</div>
        {/*
        <ReactModal 
           isOpen={this.state.deleteStoryModalIsOpen}
           contentLabel="onRequestClose Example"
           className="clarity-modal"
           overlayClassName="clarity-overlay"
           onRequestClose={() => this.toggleVar('deleteStoryModalIsOpen')}
           shouldCloseOnOverlayClick={true}
        >
          <button
            onClick={() => this.deleteStory()}>
            Confirm Delete 
          </button>
           
        </ReactModal>
      */}
  		</div>
	 	);
  }
};
export default SelectedStoryHeader;
