import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import EditActionButton from '../../Common/Button/EditActionButton';
import MultiSelectableList from '../../Common/List/MultiSelectableList';
import ScrollableWithHeader from '../../Common/ScrollableWithHeader/ScrollableWithHeader';

import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';
import MapServiceClient from './../../../Shared/Network/MapService/MapServiceClient';
import Api from '../../../MockApi/Api';

import './FeatureGroupSelection.css';

class FeatureGroupSelection extends React.Component {

  static propTypes = {
    eventDraftId:      PropTypes.string.isRequired,
    eventDraftUpdated: PropTypes.func.isRequired,
    cancelSelection:   PropTypes.func.isRequired,
    saveSelection:     PropTypes.func.isRequired,
    mapPaths:          PropTypes.array.isRequired,
    featureGroup:      PropTypes.object,
    featureGroupHash:  PropTypes.string,
  }

  static defaultProps = {
  	mapPaths: [],
  }

  constructor(props) {
    super(props);
    this.state = {
    	parentHeight:        0,
    	selectedMapPath:     null,
    	selectedMapFeatures: [],
      pathOptions:         [],
    };
    if (props.featureGroup) {
      this.state.selectedMapPath = props.featureGroup.mapPath;
      this.state.selectedMapFeatures = props.featureGroup.features;
      this.state.selectedColor = props.featureGroup.color;
    }
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  componentDidMount() {
    this.mapServiceClient = new MapServiceClient();
  }

  async componentWillUnmount() {
    await this.mapServiceClient.destroy();
  }

  async componentDidUpdate(prevProps) {
    // Check the feature group hash
    if (prevProps.featureGroupHash !== this.props.featureGroupHash) {
      const draftId = this.props.eventDraftId;
      const updatedDraft = await Api.getDraftWithId(draftId);
      const newFeatures = updatedDraft.intermediateState.featureGroup.features; 
      this.setState({
        selectedMapFeatures: newFeatures,
      });
    }
  }

  updateParentHeight = parentHeight => {
    this.setState({parentHeight}); 
  }

  addMapFeatureToGroup = async mapFeature => {
  	const selectedMapFeatures = [...this.state.selectedMapFeatures];
  	selectedMapFeatures.push(mapFeature);
  	this.setState({selectedMapFeatures});
    await Api.addMapFeatureIdToEventDraftLocation(this.props.eventDraftId, mapFeature);
    this.props.eventDraftUpdated();
  }

  removeMapFeatureFromGroup = async mapFeature => {
  	const selectedMapFeatures = [...this.state.selectedMapFeatures];
  	const mapFeatureIndex = selectedMapFeatures.indexOf(mapFeature);
  	selectedMapFeatures.splice(mapFeatureIndex, 1);
  	this.setState({selectedMapFeatures});
    await Api.removeMapFeatureIdFromEventDraftLocation(this.props.eventDraftId, mapFeature);
    this.props.eventDraftUpdated();
  }

  selectMapPath = option => {
  	this.setState({selectedMapPath: option.value}, async () => {
      const mapPath = this.state.selectedMapPath;
      await Api.updateLocationFeatureGroupMap(this.props.eventDraftId, mapPath);
      const features = await this.getFeaturesForMapPath(option.value);
      this.setState({pathOptions: features});
      this.props.eventDraftUpdated();
    });
  }

  getFeaturesForMapPath = async (mapPath) => {
    const body = {payload: {mapPath}};
    const response = await this.mapServiceClient.requestFeatureOptionsForMapPath(body);
    // const updatedState = {};
    if (response.success) {
      return response.features;
    } else {
      return [];
    }
  }

  selectColor = option => {
    this.setState({selectedColor: option.value}, async () => {
      const color = this.state.selectedColor;
      await Api.updateLocationFeatureGroupColor(this.props.eventDraftId, color);
      this.props.eventDraftUpdated();
    });
  }

  searchStringUpdated = async (options, newSearchString) => {
    const newOptions = options.reduce((selectable, option) => {
      const nameMatch = option.label.toLowerCase().includes(newSearchString.toLowerCase());
      if (nameMatch) {
        selectable.push(option);
      }
      return selectable;
    }, []); 
    return newOptions;
  };


  render() {
  	const options = this.state.pathOptions;
    // this.getFeaturesForMapPath(this.state.selectedMapPath);
    const colorOptions = ['red', 'green', 'blue', 'orange', 'purple'];

    return (
      <ScrollableWithHeader 
        headerTitle="Location Group"
        headerButtonHandler={this.props.cancelSelection}
        headerButtonName="Close"
        updateHeight={this.updateParentHeight}
        noScrolling={true}
      >
        <div>
          <Dropdown
            options={this.props.mapPaths}
            onChange={this.selectMapPath}
            value={this.state.selectedMapPath}
            placeholder="Select Map" />
          <Dropdown
            options={colorOptions}
            onChange={this.selectColor}
            value={this.state.selectedColor || 'green'}
            placeholder="" />
        </div>
        { (this.state.parentHeight && this.state.selectedMapPath) &&
          <MultiSelectableList
            selectOption={this.addMapFeatureToGroup}
            unselectOption={this.removeMapFeatureFromGroup}
            options={options}
            parentHeight={this.state.parentHeight - 150}
            searchFunction={this.searchStringUpdated}
            selectedOptions={this.state.selectedMapFeatures}
            optionsTitle="Available Maps"
          />
        }
        { (this.state.selectedMapPath && this.state.selectedMapFeatures.length > 0) &&
          <EditActionButton 
            type="save"
            onClick={this.props.saveSelection}
            title="Save"
          /> 
        }
      </ScrollableWithHeader>
    );
  }
};
export default FeatureGroupSelection;
