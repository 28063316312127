import RecordWithHistory from './../RecordTypes/RecordWithHistory';
import AuthService from './../../AuthService';

const WorkspaceDef = {
  userId:  { type: 'id', collection: 'User', hashable: true, isEditor: true },
  name:    { type: 'string', hashable: true },
  stories: { type: ['id'], collection: 'Story', hashable: true },
};
class WorkspaceService extends RecordWithHistory {
  constructor() {
  	super('tapestry', 'workspace', WorkspaceDef, true);
  }

  async addStoryIdToWorkspace(workspaceId, workspaceHash, storyId) {
    const editorId = AuthService.getUserId();
    const addToArray = {
      stories: [storyId],
    };
    const response = await super.updateRecord(editorId, workspaceId,
                                              workspaceHash, {addToArray});
    return response;
  }

  async createWorkspace(userId, name, options = {}) {
    const workspaceObj = {
      user:    userId,
      name:    name,
      stories: [],
    };
    if (options.isDefault !== undefined) {
      workspaceObj.isDefault = options.isDefault;
    }

    return super.createNewRecord(workspaceObj);
  }

  async defaultWorkspaceExists(userId) {
    const userWorkspaces = await super.findWithField('user', userId);
    const defaultWorkspace = userWorkspaces.find(workspace => {
      return workspace.isDefault;
    });
    return !!defaultWorkspace;
  }

  async getDefaultWorkspaceId(userId) {
    const userWorkspaces = await super.findWithField('user', userId);
    const defaultWorkspace = userWorkspaces.find(workspace => {
      return workspace.isDefault;
    });
    return defaultWorkspace.id;
  }

  async getDefaultWorkspace(userId) {
    const userWorkspaces = await super.findWithField('user', userId);
    const defaultWorkspace = userWorkspaces.find(workspace => {
      return workspace.isDefault;
    });
    return defaultWorkspace;
  }

  async getLatestWorkspaceHashWithId(workspaceId) {
    const workspace = await super.findOneWithPrimaryField(workspaceId);
    return workspace.hash;
  }

  async getWorkspaceWithId(workspaceId) {
    return super.findOneWithPrimaryField(workspaceId);
  }

  // ///////////////////////////////////////////////////////////////////////////
  // Unverified

  // TODO: When Removing Workspace, prohibit the removal of the last workspace
  async removeStoryIdFromWorkspace(workspaceId, storyId) {
    return super.removeIdFromItemArrayField(workspaceId, 'stories', storyId);
  }


  getAllWorkspaces() {
  	return super.getAllItemsFromStore();
  }

  async getWorkspacesWithIds(workspaceIds) {
  	// TODO: Try to find a better way to do this
    return super.getItemsWithKeys(workspaceIds);
  }
}

export default (new WorkspaceService());
