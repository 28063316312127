import React from 'react';
import PropTypes from 'prop-types';

import ExpandableTextarea from './ExpandableTextarea.js';

import './LimitedTextarea.css';


class LimitedTextarea extends React.Component {

  static propTypes = {
  	updateValue: PropTypes.func.isRequired,
    charsPerRow: PropTypes.number.isRequired,
  	value:       PropTypes.string,
  	rows:        PropTypes.number,
  }

  static defaultProps = {
  	value: '',
  	rows:  2,
  }

  constructor(props) {
    super(props);
    this.state = {
    	content: this.props.initialValue,
    };
  }

  setFormattedContent = text => {
  	const limit = this.props.limit;
  	let trimmedValue = text.length > limit ? text.slice(0, limit) : text;
    this.props.updateValue(trimmedValue);
  };

  render() {
  	const {
  		limit,
  		value,
  		charsPerRow,
  	} = this.props; 
  	return (
	    <div>
	      <ExpandableTextarea
          charsPerRow={charsPerRow}
	        updateValue={this.setFormattedContent}
	        value={value}
	      />
	      <p style={{margin: '0', textAlign: 'right'}}>
	        {value.length}/{limit}
	      </p>
	    </div>
	 	);
  }
};
export default LimitedTextarea;
