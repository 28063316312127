import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from 'react-dropdown';
import { endOfWeek, startOfWeek, subDays, startOfDay, endOfDay } from 'date-fns'


import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';
import {CommonColloquialDateRanges} from './../../../Shared/TapestryConstants/Enums';
import {DisplayColoquialDR} from './../../../Shared/TapestryConstants/DisplayText';

import 'react-dropdown/style.css';
import './DateRangePickerDropdown.css';

class DateRangePickerDropdown extends React.Component {

  static propTypes = {
  	updateDateTimeRange: PropTypes.func.isRequired,
    dateTimeRangeType:   PropTypes.string,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	selectedDateRange: this.props.dateTimeRangeType,
    };
    this.reduceMapToOptions = CommonClassMethods.reduceMapToOptions.bind(this);
    this.colloquaialDateRangeOptions = this.reduceMapToOptions(DisplayColoquialDR);
  }

  selectDateRangeOption = dateRangeOption => {
  	let startDateTime;
  	let endDateTime;
  	if (dateRangeOption.value === CommonColloquialDateRanges.TODAY_IN_TZ) {
  		const now = new Date();
  		startDateTime = startOfDay(now);
  		endDateTime = endOfDay(now);
    } else if (dateRangeOption.value === CommonColloquialDateRanges.YESTERDAY_IN_TZ) {
      const now = new Date();
      startDateTime = startOfDay(subDays(now, 1));
      endDateTime = endOfDay(subDays(now, 1));
    } else if (dateRangeOption.value === CommonColloquialDateRanges.CALENDAR_WEEK_IN_TZ) {
      const now = new Date();
      startDateTime = startOfWeek(now);
      endDateTime = endOfWeek(now);
    } else if (dateRangeOption.value === CommonColloquialDateRanges.LAST_7_DAYS) {
      const now = new Date();
      startDateTime = startOfDay(subDays(now, 6));
      endDateTime = endOfDay(now);
    }
  	this.props.updateDateTimeRange(startDateTime, endDateTime,
                                   dateRangeOption.value);
  	this.setState({selectedDateRange: dateRangeOption.value});
  };

  render() {
  	const {
      wrapperClasses,
  	} = this.props; 
  	return (
	    <div className={classnames({...wrapperClasses})}>
        <Dropdown
          className="dropdown-wrapper"
          controlClassName="dropdown-container"
          options={this.colloquaialDateRangeOptions}
          onChange={this.selectDateRangeOption}
          value={this.state.selectedDateRange}
          placeholder="Select Date Range" />
	    </div>
	 	);
  }
};
export default DateRangePickerDropdown;
