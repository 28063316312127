import { get, set } from 'idb-keyval';

const IndexedDbService = {
  saveInIndexDBStore,
  getFromIndexDBStoreOrDefault,
};

function saveInIndexDBStore(store, value) {
  return set(store, value);
}

async function getFromIndexDBStoreOrDefault(store, defaultValue) {
  const storedValue = await get(store);
  return storedValue || defaultValue;
}

export default IndexedDbService;
