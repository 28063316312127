import React, { Component } from "react";
import "./App.css";

import TapestryUIWrapper from './Pages/TapestryUIWrapper.jsx';

import TabService from './Services/TabService';
import 'react-date-range/dist/styles.css'; // main css file

// Need To Add A Loading Screen
class App extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	initialStateLoaded: false,
      showApp:            'tapestryUI',
    };
	}
	async componentDidMount() {
		const existingTabId = TabService.getTabId();
		if (existingTabId) {
			this.setState({
				initialStateLoaded: true,
				tabId:              existingTabId,
			});
		} else {
			const newTabId = TabService.createNewTabId();
			this.setState({
				initialStateLoaded: true,
				tabId:              newTabId,
			});
		}
	}

  render() {
    return (
        <div className="App">
          { !this.state.initialStateLoaded &&
            <div>
              Loading
            </div>
          }
          { !this.state.initialStateLoaded &&
            <div style={{position: 'absolute', top: '0'}}>
              <button
                onClick={() => this.setState({showApp: 'ledger'})}>
                Ledger 
              </button>
              <button
                onClick={() => this.setState({showApp: 'tapestryUI'})}>
                Tapestry UI 
              </button>
              <button
                onClick={() => this.setState({showApp: 'tapestryService'})}>
                Tapestry Server 
              </button>
              <button
                onClick={this.diffeHellmanExample}>
                Component Communication Visualization 
              </button>
            </div>
          }
          { (this.state.initialStateLoaded && this.state.showApp === 'tapestryUI') &&

            <TapestryUIWrapper  
              userId={this.state.userId}
              tabId={this.state.tabId}
              {...this.props} />
          }
        </div>
    );
  }
}
// Notes:  
/*
	Configuring a workspace is meant to 
*/
export default App;
// export default withAuthenticator(App, { includeGreetings: true }, false);
