import React from 'react';
import PropTypes from 'prop-types';

import './Image.css';
import './SmallImage.css';


SmallRectangularImage.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string
}

function SmallRectangularImage(props) {
  let {
    imageSrc,
    imageAlt,
    height,// width
  } = props;

  return (
    <div style={{height}}>
      <img
        style={{border: '2px lightgrey solid', height: '100%'}}
        alt={imageAlt}
        src={imageSrc}
      />
    </div>
  )
}

export default SmallRectangularImage;
