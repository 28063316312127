import React from 'react';
import PropTypes from 'prop-types';

import './ExpandableTextArea.css';

class ExpandableTextArea extends React.Component {

  static propTypes = {
  	updateValue: PropTypes.func.isRequired,
  	charsPerRow: PropTypes.number,
  	value:       PropTypes.string,
    focusOnLoad: PropTypes.bool,
  }

  static defaultProps = {
  	value: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  componentDidMount(){
    if (this.props.focusOnLoad) {
      this.textArea.focus();
    }
    const width = this.textArea.clientWidth;
    this.setState({ width });
  }

  getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas = this.textArea.canvas || (this.textArea.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }

  render() {
  	const {
  		value,
      charsPerRow,
      className,
  	} = this.props; 
    let rows = 1;
    if (charsPerRow && value && value.length) {
      if (value) {
        rows = Math.floor(value.length / charsPerRow) + 1;
      }
    } else if (value && value.length) {
      if (this.state.width) {
        const fontSize = this.textArea.style.fontSize;
        const font = `${fontSize} Arial`;
        const textWidth = this.getTextWidth(value, font);
        rows = Math.floor(textWidth / this.state.width) + 1;
      }
    }
  	return (
      <textarea
        className={className}
        ref={(input) => { this.textArea = input; }} 
      	style={{width: '100%', boxSizing: 'border-box', fontSize: '16px'}}
        rows={rows}
        onChange={event => this.props.updateValue(event.target.value)}
        value={value || ''}
      />
	 	);
  }
};
export default ExpandableTextArea;
