import BrowserStorage from './../Shared/Utilities/BrowserStorage';

const AuthService = {
	getUserId,
	setUserId,
};

const userIdName = 'USER_ID';

function getUserId() {
	const userId = BrowserStorage.local.getItemOrDefault(userIdName, null, false);
	return userId;
}

function setUserId(userId) {
	BrowserStorage.local.setItem(userIdName, userId);
}

export default AuthService
