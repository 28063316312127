
class RestApiBase {

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(pathUrl) {
    const headers = this.getHeaders();
    const response = await fetch(`${this.baseUrl}/${pathUrl}`, {
      method:  'GET',
      headers: headers,
    })
    if (response.status < 205) {
      return response.json();
    }
  }

  getHeaders() {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    return headers;
  }

  async post(pathUrl, body) {
    const headers = this.getHeaders();
    const response = await fetch(`${this.baseUrl}/${pathUrl}`, {
      method:  'POST',
      body:    JSON.stringify(body),
      headers: headers,
    })
    return response.json();
  }

  async deleteCall(pathUrl, body) {
    const headers = this.getHeaders();
    const response = await fetch(`${this.baseUrl}/${pathUrl}`, {
      method:  'DELETE',
      body:    JSON.stringify(body),
      headers: headers
    })
    return response.json();
  }
}

export default RestApiBase;
