
const CommonClassMethods = { 
	reduceMapToOptions,
	toggleVar,
	toggleVarWithLoadingTimeout,
	updateStateFieldWithValue,
	updateStringField,
};

function reduceMapToOptions(displayTypeMap) {
  return Object.keys(displayTypeMap).reduce((array, optionKey) => {
    array.push({
      value: optionKey,
      label: displayTypeMap[optionKey],
    });
    return array; 
  }, []);
}

function toggleVar(varName, additionalStateUpdates = {}) {
  const updatedState = Object.assign({}, additionalStateUpdates);
 	if (typeof varName === 'string') {
    updatedState[varName] = !this.state[varName];
 	} else {
 		varName.forEach(variableName => {
      updatedState[variableName] = !this.state[variableName];
 		});
 	}
  return new Promise((resolve, reject) => {
  	this.setState(updatedState, () => {
	  	return resolve(updatedState);
	  });  
  });
}

function toggleVarWithLoadingTimeout(promiseGenerator) {
  return new Promise((resolve, reject) => {
		this.setState({loading: true}, () => {
			console.log('Loading Started');
		 	setTimeout(async () => {
				const promise = promiseGenerator();
		 		await promise;
		  	this.setState({loading: false}, () => {
					console.log('Loading Ended');
			  	return resolve();
			  });  
		 	}, 500);
		});
  });
}

function updateStateFieldWithValue(fieldName, fieldValue) {
  const updatedState = {};
  updatedState[fieldName] = fieldValue;
  this.setState(updatedState);
}

function updateStringField(fieldName, fieldValue) {
  const updatedState = {};
  updatedState[fieldName] = fieldValue;
  return new Promise((resolve, reject) => {
  	this.setState(updatedState, () => {
	  	return resolve();
	  });  
  });
}

export default CommonClassMethods;
