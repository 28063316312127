import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EventsPanel from './MapTimelineTool/EventsPanel/EventsPanel';
import MapLoadingManager from './MapTimelineTool/Map/MapLoadingManager';
import MapSelection from './MapTimelineTool/MapSelection/MapSelection';
import SelectedStoryHeader from './MapTimelineTool/SelectedStoryHeader/SelectedStoryHeader';
import Timelines from './MapTimelineTool/Timelines/Timelines';

import WorkspaceHeader from './Workspace/WorkspaceHeader/WorkspaceHeader';
// import NetworkVisualizer from './MapTimelineTool/NetworkVisualizer/NetworkVisualizer';

import DisplayS from '../Services/DisplayService';

import CommonClassMethods from '../Shared/Utilities/CommonClassMethods';
import BrowserStorage from '../Shared/Utilities/BrowserStorage';

import Api from '../MockApi/Api.js';

import './MapTimelineTool.css';

// TODO: Save Selected Story Id and Load Into settings
class MapTimelineTool extends React.Component {
  static propTypes = {
    userId:        PropTypes.string.isRequired,
    tabId:         PropTypes.string.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	width:                   null,
    	height:                  null,
    	storyIdMap:              {},
    	timelinesPxHeight:       200,
      selectedStory:           null,
      selectingStoryMaps:      false,
      highlightedFeatureGroup: null,
      dataLoaded:              false,
      workspaceId:             null,
      userTabState:            {}, 
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

	componentDidMount() {
    console.log(
      'Local Storage Used: '+ BrowserStorage.local.getStoragePercentage() + '%'
    );
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.loadSettingsForUserTab(this.props.userId, this.props.tabId);
	}

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {

    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ width: width, height: height });
  }

  updateVar = (varName, varValue) => {
    if (this.state[varName] === varValue) {
      return;
    }
    const updatedState = {};
    updatedState[varName] = varValue;
    this.setState(updatedState);  
  }


	loadSettingsForUserTab = async (userId, tabId) => {
		const userTabState = await Api.getUserTabState(userId, tabId);
    const workspaceId = userTabState.workspace;
    const storyIdMap = await DisplayS.getStoryIdMapForWorkspaceId(workspaceId);
    // TODO: Need to track changes to the workspace to update it
    const workspaceHash = await Api.getLatestWorkspaceHashWithId(workspaceId);
    const initialState = {
      userTabState:   userTabState,
      workspaceId:    workspaceId,
      workspaceHash:  workspaceHash,
      dataLoaded:     true,
      storyIdMap,
    };
		if (userTabState.selectedStory) {
      const selectedStoryId = userTabState.selectedStory;
			initialState.selectedStory = storyIdMap[userTabState.selectedStory];
      const configId = userTabState.storyViewConfig;
      if (configId) {
        initialState.storyViewConfig = await Api.getStoryViewConfig(configId);
      } else {
        // Look For Story View Config 
        const existingConfig = await Api.getViewConfigForStoryId(userId,
                                                                 selectedStoryId);
        // Create If Not Found
        if (existingConfig) {
          await Api.setStoryViewConfigForTabState(userTabState.id,
                                                  existingConfig.id);
          initialState.storyViewConfig = existingConfig;
        } else {
          const newStoryViewConfig = await Api.createStoryViewConfig(userId,
                                                                     selectedStoryId);

          initialState.storyViewConfig = newStoryViewConfig;
        }
      }
		}

    if (userTabState.eventDraft) {
      const draftId = userTabState.eventDraft;
      initialState.eventDraft = await Api.getDraftWithId(draftId);
    }

		this.setState(initialState);
	}

  storyViewConfigUpdated = async updateType => {
    const configId = this.state.userTabState.storyViewConfig;
    const updatedConfig = await Api.getStoryViewConfig(configId);
    const updatedState = {
      storyViewConfig: updatedConfig,
    };
    this.setState(updatedState);
  }

  eventDraftUpdated = async updateType => {
    const draftId = this.state.userTabState.eventDraft;
    const updatedDraft = await Api.getDraftWithId(draftId);
    const updatedState = {
      eventDraft: updatedDraft,
    };
    if (updateType) {
      if (updateType === 'draft_deleted') {
        const userTabState = await Api.setEventDraftForTabState(this.state.userTabState.id,
                                                                null);
        updatedState.userTabState = userTabState;
      }
    }
    this.setState(updatedState);
  }

  workspaceUpdated = async (updateType, params) => {
    const workspaceId = this.state.workspaceId;
    const storyIdMap = await DisplayS.getStoryIdMapForWorkspaceId(workspaceId);
    const updatedState = {
      storyIdMap,
    };
    const userTabStateId = this.state.userTabState.id;
    if (updateType === 'new_story') {
      const selectedStoryId = params;
      const userTabState = await Api.setSelectedStoryForTabState(userTabStateId,
                                                                 selectedStoryId);
      updatedState.userTabState = userTabState;
      updatedState.selectedStory = storyIdMap[selectedStoryId];
    } else if (updateType === 'draft_created') {
      const draftId = params;
      const eventDraft = await Api.getDraftWithId(draftId);
      updatedState.eventDraft = eventDraft;
      const userTabState = await Api.setEventDraftForTabState(userTabStateId,
                                                              draftId);
      updatedState.userTabState = userTabState;
    }
    this.setState(updatedState);
  } 

  selectStoryWithId = async selectedStoryId => {
    const userTabStateId = this.state.userTabState.id;
    const userTabState = await Api.setSelectedStoryForTabState(userTabStateId,
                                                               selectedStoryId);
    const userId = userTabState.user; 
    // TODO: Here

    let storyViewConfig = await Api.getViewConfigForStoryId(userId,
                                                            selectedStoryId);
    if (!storyViewConfig) {
      storyViewConfig = await Api.createStoryViewConfig(userId,
                                                        selectedStoryId);
    }
    await Api.setStoryViewConfigForTabState(userTabStateId, storyViewConfig.id);
    this.setState({
      storyViewConfig,
      userTabState,
      selectedStory: this.state.storyIdMap[selectedStoryId],
    }); 
  }

  unselectStory = async () => {
    const userTabStateId = this.state.userTabState.id;
    const userTabState = await Api.setSelectedStoryForTabState(userTabStateId,
                                                               null);
    this.setState({
      userTabState,
      selectedStory: null,
      storyViewConfig: null,
    }); 
  }

  selectedStoryUpdated = async (updateType) => {
    const workspaceId = this.state.workspaceId;
    // Remove Story Id From Workspace
    const selectedStoryId = this.state.userTabState.selectedStory;
    const selectedStoryUpdateEvents = [
      'event_added', 'event_updated', 'event_removed', 'mappath_added',
      'mappath_removed',
    ];
    if (updateType === 'deleted' || updateType === 'removed_from_workspace') {
      await Api.removeStoryIdFromWorkspace(workspaceId, selectedStoryId);
      const storyIdMap = await DisplayS.getStoryIdMapForWorkspaceId(workspaceId);
      const userTabStateId = this.state.userTabState.id;
      const userTabState = await Api.setSelectedStoryForTabState(userTabStateId, null);
      this.setState({userTabState, selectedStory: null, storyIdMap}); 
    } else if (selectedStoryUpdateEvents.includes(updateType)) {
      const updatedState = {};
      /*
      if (['event_added', 'event_updated'].includes(updateType)) {
        const userTabStateId = this.state.userTabState.id;
        const userTabState = await Api.setEventDraftForTabState(userTabStateId,
                                                                null);
        updatedState.eventDraft = null;
        updatedState.userTabState = userTabState;
      }
      */
      const storyIdMap = await DisplayS.getStoryIdMapForWorkspaceId(workspaceId);
      updatedState.selectedStory = storyIdMap[selectedStoryId];
      updatedState.storyIdMap = storyIdMap;
      this.setState(updatedState);
    }
  }

  addMapPath = async mapPath => {
    const selectedStoryId = this.state.userTabState.selectedStory;
    await Api.addMapPathToStory(selectedStoryId, mapPath);
    return this.selectedStoryUpdated('mappath_added');
  }

  removeMapPath = async oldMapPath => {
    const selectedStoryId = this.state.userTabState.selectedStory;
    await Api.removeMapPathFromStory(selectedStoryId, oldMapPath);
    return this.selectedStoryUpdated('mappath_removed');
  }

  selectMapFeature = async (mapType, featureId) => {
    const eventDraftId = this.state.userTabState.eventDraft;
    if (this.state.eventDraft.intermediateState.featureGroup.features.includes(featureId)) {
      await Api.removeMapFeatureIdFromEventDraftLocation(eventDraftId,
                                                         featureId);
    } else {
      await Api.addMapFeatureIdToEventDraftLocation(eventDraftId, featureId);
    }
    this.eventDraftUpdated();
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div
          className={classnames({'fill-parent-height': true, 'fill-parent-width': true})}
          ref={ (divElement) => { this.divElement = divElement } }>
          Loading
        </div>
      );
    }
  	const workspaceHeaderHeight = 53;
  	const storyHeaderHeight = 70;// this.state.userTabState.selectedStory ? 70 : 0;
  	const headerPxHeight = workspaceHeaderHeight + storyHeaderHeight;
  	const mapPxHeight = this.state.height - headerPxHeight - this.state.timelinesPxHeight;
  	const eventsPxWith = 300;
  	const mapPxWidth = this.state.width - eventsPxWith - 2;
    return (
      <div
        className={classnames({
          'map-timeline-tool':  true,
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}
        ref={ (divElement) => { this.divElement = divElement } }>
      	<div className='header' style={{height: `${headerPxHeight}px`}}>
          { this.state.workspaceId &&
            <WorkspaceHeader 
              workspaceId={this.state.workspaceId}
              workspaceHash={this.state.workspaceHash}
              numStories={Object.keys(this.state.storyIdMap).length}
              stories={Object.values(this.state.storyIdMap)}
              addNewStoryHandler={newStoryId => this.workspaceUpdated('new_story', newStoryId)}
              selectStoryWithIdHandler={this.selectStoryWithId}
              showSourceLibrary={()=>{}}
            />
          }
	      	{this.state.userTabState.selectedStory &&
	      		<SelectedStoryHeader
	      			selectedStory={this.state.selectedStory}
	      			unselectStory={this.unselectStory}
	      			deleteStory={() => this.selectedStoryUpdated('deleted')}
              selectingStoryMaps={this.state.selectingStoryMaps}
              toggleStoryMapSelection={() => this.toggleVar('selectingStoryMaps')}
	      		/>
	      	}
	      	{!this.state.userTabState.selectedStory &&
	        	<div
              style={{height: `${headerPxHeight - workspaceHeaderHeight}px`, fontSize: '30px'}}
              className={classnames({
                'vertically-align-text': true,
              })}>
	        		{ (Object.keys(this.state.storyIdMap).length && 'Select A Story To Begin') ||
                'Create A Story To Begin'
              }
	        	</div>
	      	}
      	</div>
      	<div className="map-events-container"
      		style={{height: `${mapPxHeight}px`}}>
            <MapLoadingManager
              mapPxWidth={mapPxWidth}
              eventDraftId={this.state.userTabState.eventDraft}
              eventDraft={this.state.eventDraft}
              selectedStoryId={this.state.userTabState.selectedStory}
              selectedStory={this.state.selectedStory}
              selectMapFeature={this.selectMapFeature}
            />
        	<div className="events-container" style={{width: `${eventsPxWith}px`}}>
            { !this.state.userTabState.selectedStory &&
              <div style={{color: 'white', fontSize: '22px', paddingTop: '20px'}}>
                Story Events Show Up Here
              </div>
            }
            { (this.state.userTabState.selectedStory && !this.state.selectingStoryMaps) &&
              <EventsPanel 
                eventDraft={this.state.eventDraft}
                userTabStateId={this.state.userTabState.id}
                eventDraftUpdated={this.eventDraftUpdated}
                selectedStory={this.state.selectedStory}
                draftCreated={draftId => this.workspaceUpdated('draft_created', draftId)}
                selectedStoryEventsUpdated={this.selectedStoryUpdated}
              />
            }
            { (this.state.userTabState.selectedStory && this.state.selectingStoryMaps) &&
              <MapSelection
                selectedMapPaths={this.state.selectedStory.mapPaths}
                addMapPath={this.addMapPath}
                removeMapPath={this.removeMapPath}
                closeMapSelection={() => this.toggleVar('selectingStoryMaps')}
              />
            }
        	</div>
      	</div> 	
        { !this.state.userTabState.selectedStory &&
        	<div 
              className={classnames({
                'green-border':          true,
                'fill-parent-width':     true,
                'vertically-align-text': true,
              })}
              style={{fontSize: '30px', height: `${this.state.timelinesPxHeight}px`}}>
        	  Reference Timelines Show Up Here	
        	</div>
        }
        { this.state.userTabState.selectedStory &&
        	<Timelines
            selectedStory={this.state.selectedStory}
            eventDraft={this.state.eventDraft}
        		updateTimelinesHeight={newHeight => this.updateVar('timelinesPxHeight', newHeight)}
            storyViewConfig={this.state.storyViewConfig}
            storyViewConfigUpdated={this.storyViewConfigUpdated}
        	/>
        }
        {/*
          <NetworkVisualizer
            userId={this.props.userId + this.props.tabId}
            userTabStateId={this.state.userTabState.id}
            networkEnabled={this.state.userTabState.networkEnabled}
          />
        */}
      </div>
    );
  }
};

export default MapTimelineTool;
