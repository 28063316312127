// Stores The State That Is Necessary to Render the UI
import RecordWithHistory from './../RecordTypes/RecordWithHistory';

const StoryViewConfigDef = {
  user:       { type: 'id', collection: 'User', hashable: true },
  story:      { type: 'id', collection: 'Story', hashable: true },
  timeFrames: { type: 'object', hashable: true },
};

class StoryViewConfigService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'storyViewConfig', StoryViewConfigDef, true);
  }

  async createStoryViewConfig(userId, storyId, options = {}) {
    const storyViewConfigObj = {
      user:  userId,
      story: storyId,
      timeFrames: {},
      // Id Fields Initially Null
      // selectedStory
      // selectedStoryViewConfig
      // storyViewConfig
      // eventDraft: DraftId
      
    };
    return super.addItemToStore(storyViewConfigObj);
  }

  // Unverified
	async getStoryViewConfig(configId) {
		return super.getItemWithId(configId);
	}

	async getViewConfigForStoryId(userId, storyId) {
		return super.findItemWithFunc(item => {
			return item.user === userId && item.story === storyId;
		});
	}

  async updateTimeFrameFieldOfType(configId, timeFrameType, fieldName, fieldValue) {
  	const allowedTimeFrameTypes = ['event', 'perspective', 'observer'];
  	const allowedFieldNames = ['start', 'end'];
  	const updateFunction = config => {
  		if (!allowedTimeFrameTypes.includes(timeFrameType) ||
  				!allowedFieldNames.includes(fieldName)) {
  			return config;
  		}
  		if (!config.timeFrames[timeFrameType]) {
  			config.timeFrames[timeFrameType] = {};
  		}
 			config.timeFrames[timeFrameType][fieldName] = fieldValue;
  		return config;
  	}
		return super.updateItemWithKey(configId, updateFunction);
  }

  async setSelectedStoryForTabState(userTabStateId, storyId) {
  	const updateFunction = (userTabState) => {
  		userTabState.selectedStory = storyId;
  		return userTabState;
  	}
		return super.updateItemWithKey(userTabStateId, updateFunction);
  }
}

export default (new StoryViewConfigService());
