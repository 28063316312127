import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
// import './SourceSidepanel.css';

class TimelineLabel extends React.Component {
  static propTypes = {
    handleDateChange: PropTypes.func.isRequired,
  	dateMoment:       PropTypes.instanceOf(moment)
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	isHovered: false,
      editing: false,
    };
  }

  handleDateChange = date => {
    this.props.handleDateChange(moment(date).format('MM-DD-YYYY'));
  }

  toggleHover = () => {
    if (this.state.editing) {
      return;
    }
	  this.setState(prevState => ({isHovered: !prevState.isHovered}));
	}

  render() {
    const labelStyles = {
      position: 'absolute'
    };
    if (this.props.left) {
      labelStyles.left = 0;
    } else if (this.props.right) {
      labelStyles.right = 0;
    }
  	return (
  		<div
  			className='timeline-label'
  			style={labelStyles}
  			onMouseEnter={this.toggleHover}
  			onMouseLeave={this.toggleHover}>
        { (this.state.isHovered || this.state.editing) &&
          <div style={{display: 'inline-block'}} onClick={() => this.setState({editing: !this.state.editing})}>
            {(this.state.editing && 'Save') || 'Edit'}
          </div>
        }
  			{ !this.state.editing &&
		      <span>{this.props.dateMoment.format('YYYY')}</span>
  			}
  			{ this.state.editing &&
  				<div style={{display: 'inline-block'}}>
            <DatePicker
              selected={this.props.dateMoment.toDate()}
              onChange={this.handleDateChange}
              dateFormat="yyyy"
              showYearPicker
            />
          </div>
  			}
  		</div>
  	);
  }
}

export default TimelineLabel;
