import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartCardService from './../../../../Services/SmartCardService';
import Icon from './../../../../Shared/Icons/Icon';

function KeySetPreview(props) {
	const {
		id,
		type,
		name,
		deleteHandler,
		selectHandler,
		unselectHandler,
		selected,
	} = props;
	return (
		<div className={classnames({
			'top-margin':         true,
			'rounded-box-border': true,
			'green-border':       true,
		})}>
			<div>
	      <Icon iconType="key"/>
			  Key
				{name &&
					<span>Name: {name}</span>
				}
				{!name &&
					<span>Id: {SmartCardService.getDisplayId(id)}</span>
				}
			</div>
			<div>Key Type: {type}</div>
			<div>
				{ selected &&
	        <button
	          onClick={() => unselectHandler(type)}>
	          Unselect 
	        </button>
				}	
				{!selected &&
	        <button
	          onClick={() => selectHandler(type, id)}>
	          Select 
	        </button>
				}	
        <button
          onClick={() => deleteHandler(id)}>
          Delete 
        </button>
			</div>
		</div>
 	);
}

KeySetPreview.propTypes = {
	id:              PropTypes.string.isRequired,
	type:            PropTypes.string.isRequired,
	deleteHandler:   PropTypes.func.isRequired,
	selectHandler:   PropTypes.func.isRequired,
	unselectHandler: PropTypes.func.isRequired,
	name:            PropTypes.string,
};

export default KeySetPreview;
