import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './../../../Shared/Icons/Icon';
import Spacer from './../../../Shared/Utilities/Spacer';
import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';
import {ToolType} from './../../../Shared/TapestryConstants/Enums';

import './AccountHeader.css';

class AccountHeader extends React.Component {

  static propTypes = {
    logoutHandler:         PropTypes.func.isRequired,
    removeToolTypeHandler: PropTypes.func.isRequired,
    toolType:              PropTypes.string,
    workspaceName:         PropTypes.string,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  async componentDidUpdate(prevProps) {
  }

  render() {
    let toolDiv;
    if (this.props.toolType === ToolType.WORKSPACE) {
      toolDiv = (
        <div
          className={classnames({
            'fill-parent-height': true,
            'vertically-align-text': true,
          })}>
          Workspace: {this.props.workspaceName || 'Default'}
        </div>
      );
    } if (this.props.toolType === ToolType.WORKSPACE_MANAGER) {
      toolDiv = (
        <div
          className={classnames({
            'fill-parent-height': true,
            'vertically-align-text': true,
          })}>
          Workspace Selection 
        </div>
      );
    } if (this.props.toolType === ToolType.SOURCE_LIBRARY) {
      toolDiv = (
        <div
          className={classnames({
            'fill-parent-height': true,
            'vertically-align-text': true,
          })}>
          Personal Library 
        </div>
      );
    }
  	return (
  		<div className="account-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
          <div
            className={classnames({
              'fill-parent-height': true,
              'vertically-align-text': true,
            })}
            style={{color: 'mediumpurple', fontSize: '22px'}}>
            <Icon iconType="tapestry-logo"/>
            Tapestry
          </div>
          <Spacer type="horizontal" width="10px"/>
          <Spacer type="horizontal" width="2px" background="red" />
          <Spacer type="horizontal" width="10px"/>
          { toolDiv ||  
            <div
              className={classnames({
                'fill-parent-height': true,
                'vertically-align-text': true,
              })}>
              Select A Tool
            </div>
          }
          { toolDiv &&
            <>
            <Spacer type="horizontal" width="10px"/>
            <button
              onClick={this.props.removeToolTypeHandler}>
              Select New Tool 
            </button>
            </>
          }
      	</div>
        <div 
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{display: 'inline-block', float: 'right'}}>
          <button
            onClick={this.props.logoutHandler}>
            Logout 
          </button>
        </div>
  		</div>
	 	);
  }
};
export default AccountHeader;
