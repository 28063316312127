import React from 'react';
import PropTypes from 'prop-types';

import SlidingPane from 'react-sliding-pane';

import 'react-sliding-pane/dist/react-sliding-pane.css';

import './SlidingPane.css';


class SidingUpPane extends React.Component {
  static propTypes = {
    isPaneOpen:       PropTypes.bool.isRequired,
    closePaneHandler: PropTypes.func.isRequired,
    title:            PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
  	return (
      <SlidingPane
        className="sidepanel-container"
        from="bottom"
        width="100%"
        overlayClassName="some-custom-overlay-class"
        isOpen={this.props.isPaneOpen}
        title={this.props.title}
        onRequestClose={this.props.closePaneHandler}
      >
    		{this.props.children}
      </SlidingPane>
	 	);
  }
};
export default SidingUpPane;
