import React from 'react'
import PropTypes from 'prop-types'

import SmallRectangularImage from  './../Images/SmallRectangularImage';
import Spacer from './../Utilities/Spacer';

import './OpenGraphPreview.css';

const DefaultService = {
  sourceImage: 'http://saveabandonedbabies.org/wp-content/uploads/2015/08/default.png',
};

OpenGraphPreview.propTypes = {
  sourceId:        PropTypes.string,
  sourceUri:       PropTypes.string,
  openGraphInfo:   PropTypes.object,
  savedDate:       PropTypes.instanceOf(Date),
  addNoteToSource: PropTypes.func,
}

function OpenGraphPreview(props) {
  let {
    openGraphInfo,
    savedDate,
    sourceUri,
    addNoteToSource,
  } = props;
  let sourceImageUrl;
  if (openGraphInfo.ogImage) {
    sourceImageUrl = openGraphInfo.ogImage.url;
  }
  const publisherName = openGraphInfo.ogSiteName ? openGraphInfo.ogSiteName : 'UNKNOWN';
  const sourceTitle = openGraphInfo.ogTitle;
  const wrapperClasses = ['source-item'];
  return (
    <div
    	className={wrapperClasses.join(' ')}>
      <div style={{display: 'inline-block', maxWidth: '50%', height: '100px'}}>
        <a
          className="inline-source-title"
          rel="noopener noreferrer"
          href={sourceUri} target="_blank">
          {sourceTitle}
        </a>
        <div className="inline-publisher-name">{publisherName}</div>
        {savedDate &&
          <div className="inline-publisher-name">
            Saved Date: {savedDate.toString()}
          </div>
        }
        {addNoteToSource &&
          <>
            <Spacer type="vertical" width="10px"/>
            <button onClick={addNoteToSource}>
              Add Note
            </button>
          </>
        }
      </div>
      <div style={{float: 'right'}}>
        <SmallRectangularImage
          height={100}
          width={100}
          imageSrc={sourceImageUrl || DefaultService.sourceImage} />
      </div>
    </div>
  );
}

export default OpenGraphPreview;

