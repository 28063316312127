import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

import MapServiceClient from './../../../Shared/Network/MapService/MapServiceClient';
import MapServiceApi from './../../../Shared/Network/MapService/MapServiceApi';

class MapManager {
  constructor() {
  	this.loadedMaps = {};
  	this.loadedSilhouetteSeries = {};
    this.loadedFeatureGroups = {};
    this.loadedEvents = {};
    this.mapServiceClient = new MapServiceClient();
  }

  async destroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    await this.mapServiceClient.destroy();
  }

  createMap() {
    const chart = am4core.create('chartdiv', am4maps.MapChart);
    chart.projection = new am4maps.projections.Miller();
    this.chart = chart;
    return chart;
  }

  async removeFeatureGroupWithHash(featureGroupHash) {
    const fgSeries = this.loadedFeatureGroups[featureGroupHash];
    if (!fgSeries) {
      return;
    }
    this.chart.series.removeIndex(
      this.chart.series.indexOf(fgSeries)
    ).dispose();
    delete this.loadedFeatureGroups[featureGroupHash];
  }

  async updateVisibleSilhouettes(oldSilhouettes, newSilhouettes,
                                 silhouetteFeatureClickHandler) {
    const {
      firstOnly: silhouettesToRemove,
      secondOnly: silhouettesToAdd,
    } = this.setComparison(oldSilhouettes, newSilhouettes);
    console.log(oldSilhouettes);
    console.log(newSilhouettes);
    console.log('Updating Silhouettes');
    console.log(silhouettesToRemove);
    console.log(silhouettesToAdd);
    await this.removeSilhouettesFromMap(silhouettesToRemove,
                                        silhouetteFeatureClickHandler);
    await this.addSilhouettesToMap(silhouettesToAdd,
                                   silhouetteFeatureClickHandler);
  }
  async updateVisibleFeatureGroups(oldFeatureGroups, newFeatureGroups,
                                   silhouetteFeatureClickHandler) {
    const oldHashes = oldFeatureGroups.map(fgroup => fgroup.hash);
    const newHashes = newFeatureGroups.map(fgroup => fgroup.hash);
    const {
      firstOnly: hashesToRemove,
      secondOnly: hashesToAdd,
    } = this.setComparison(oldHashes, newHashes);
    const fGroupsToRemove = oldFeatureGroups.filter(fgroup => {
      return hashesToRemove.includes(fgroup.hash)
    });
    const fGroupsToAdd = newFeatureGroups.filter(fgroup => {
      return hashesToAdd.includes(fgroup.hash)
    });
    await this.removeFeatureGroupsFromMap(fGroupsToRemove);
    await this.addFeatureGroupsToMap(fGroupsToAdd);
  }

  async updateVisibleEvents(oldEvents, newEvents,
                            silhouetteFeatureClickHandler) {
    const oldHashes = oldEvents.map(event => event.hash);
    const newHashes = newEvents.map(event => event.hash);
    const {
      firstOnly: hashesToRemove,
      secondOnly: hashesToAdd,
    } = this.setComparison(oldHashes, newHashes);
    const eventsToRemove = oldEvents.filter(event => {
      return hashesToRemove.includes(event.hash)
    });
    const eventsToAdd = newEvents.filter(event => {
      return hashesToAdd.includes(event.hash)
    });
    await this.removeEventsFromMap(eventsToRemove);
    await this.addEventsToMap(eventsToAdd);
  }

  async addEventsToMap(events) {
    let promise = Promise.resolve();
    const featureGroups = this.getFeatureGroupSetFromEvents(events);
    const seriesToAdd = [];
    featureGroups.forEach(featureGroup => {
      promise = promise.then(async () => {
        // console.log(featureGroup.color);
        const series = await this.addFeatureGroupToMap(featureGroup.mapPath,
                                                       featureGroup.features,
                                                       featureGroup.hash,
                                                       featureGroup.color); 
        if (!series) {
          return;
        }
        seriesToAdd.push({
          series,
          hash: featureGroup.hash,
        });
      })
    });
    await promise;
    this.addSeriesToChart(seriesToAdd);
    return promise;
  }

  getFeatureGroupSetFromEvents(events) {
    const featureGroupMap = {};
    const featureGroups = events.reduce((fgs, event) => {
      if (!event.locations) {
        return fgs;
      }
      event.locations.forEach(featureGroup => {
        if (featureGroupMap[featureGroup.hash]) {
          return;
        }  
        featureGroupMap[featureGroup.hash] = true;
        return fgs.push(featureGroup);
      });
      return fgs; 
    }, []);
    return featureGroups;
  }

  getDataForMapOfType = async (mapType) => {
    // const body = {payload: {mapType}};
    const response = await (new MapServiceApi()).getDataForMapOfType(mapType);
    // const updatedState = {};
    console.log(response);
    return response;
    /*
    if (response.success) {
      return response.mapData;
    } else {
      return [];
    }
    */
  }


  async removeEventsFromMap(events) {
    let promise = Promise.resolve();
    const featureGroups = this.getFeatureGroupSetFromEvents(events);
    featureGroups.forEach(featureGroup => {
      promise = promise.then(() => {
        return this.removeFeatureGroupWithHash(featureGroup.hash); 
      })
    });
    return promise;
  }


  setComparison = (array1, array2) => {
    const intersection = array1.filter(stringValue => {
      return array2.includes(stringValue); 
    });
    const firstOnly = array1.filter(stringValue => {
      return !array2.includes(stringValue); 
    });
    const secondOnly = array2.filter(stringValue => {
      return !array1.includes(stringValue); 
    });
    return {
      firstOnly, secondOnly, intersection,
    };
  }

  addFeatureGroupsToMap(featureGroups) {
    let promise = Promise.resolve();
    featureGroups.forEach(featureGroup => {
      promise = promise.then(() => {
        // console.log(featureGroup.color);
        return this.addFeatureGroupToMap(featureGroup.mapPath,
                                         featureGroup.features,
                                         featureGroup.hash,
                                         featureGroup.color); 
      })
    });
    return promise;
  }

  async removeFeatureGroupsFromMap(featureGroups) {
    let promise = Promise.resolve();
    featureGroups.forEach(featureGroup => {
      promise = promise.then(() => {
        return this.removeFeatureGroupWithHash(featureGroup.hash); 
      })
    });
    return promise;
  }

  async addFeatureGroupToMap(mapType, featureIds, featureGroupHash, color,
                             seriesOnly = false) {
    if (this.loadedFeatureGroups[featureGroupHash]) {
      return;
    }
    const fgSeries = await this.createPolygonSeriesForFeatureIds(mapType,
                                                                 featureIds,
                                                                 color); 
    if (seriesOnly) {
      return fgSeries;
    }
    this.chart.series.push(fgSeries);
    const DEFAULT_Z_INDEX = 50;
    fgSeries.zIndex = DEFAULT_Z_INDEX;
    this.loadedFeatureGroups[featureGroupHash] = fgSeries;
  } 

  addSeriesToChart(seriesArray, zIndex = 50) {
    seriesArray.forEach(({series, hash}) => {
      this.chart.series.push(series);
      const DEFAULT_Z_INDEX = 50;
      series.zIndex = DEFAULT_Z_INDEX;
      this.loadedFeatureGroups[hash] = series;
    });

  }

	/*addSilhouetteSeriesToState*/
  async addSilhouettesToMap(mapTypes, silhouetteFeatureClickHandler) {
    const mapTypesSet = await this.loadMapTypesIfNecessary(mapTypes);

    const unloadedSilhouetteTypes = mapTypesSet.filter(mapType => {
      return !this.loadedSilhouetteSeries[mapType];
    });

    unloadedSilhouetteTypes.forEach(mapType => {
      const polygonSeries = this.createPolygonSeries({mapType});
      if (silhouetteFeatureClickHandler) {
      	const polygonTemplate = polygonSeries.mapPolygons.template;
				polygonTemplate.events.on('hit', function(ev) {
					const featureGroupId = ev.target.dataItem.dataContext.id;
					silhouetteFeatureClickHandler(mapType, featureGroupId);
				})
      }

      this.chart.series.unshift(polygonSeries);
      this.loadedSilhouetteSeries[mapType] = polygonSeries;
    });
  }

  /*removeSeriesForSilhouettesFromState*/
  // TODO: Also Remove All Feature Group Series that depend on Silhouette 
  async removeSilhouettesFromMap(mapTypes) {
    mapTypes.forEach(mapType => {
      const silhouetteSeries = this.loadedSilhouetteSeries[mapType];
      this.chart.series.removeIndex(
        this.chart.series.indexOf(silhouetteSeries)
      ).dispose();
      delete this.loadedSilhouetteSeries[mapType];
    });
  }

  // ///////////////////////////////////////////////////////////////////////////
  // Private Methods
  createPolygonSeriesForFeatureIds = async (mapType, featureIds, color) => {
    await this.loadMapTypesIfNecessary([mapType]);
    const seriesOptions = {
      mapType:      mapType, 
      includeCodes: featureIds,
      fillColor:    color,
    };
    const featureGroupSeries = this.createPolygonSeries(seriesOptions);
    return featureGroupSeries;
  }

  async loadMapTypesIfNecessary(mapTypes) {
    const mapTypesSet = [...(new Set(mapTypes))];
    const missingMapTypes = mapTypesSet.filter(mapType => {
      return !this.loadedMaps[mapType];
    })
    if (missingMapTypes.length > 0) {
      await this.loadMapTypes(missingMapTypes);
    }
    return mapTypesSet; 
  }

  async loadMapTypes(mapTypes) {
    const results = await Promise.all(mapTypes.map(async mapType => {
      const alteredMapType = mapType.replace('High', 'Low');
      console.log('Calling To Get Map: ' + alteredMapType);
      const mapData = await this.getDataForMapOfType(alteredMapType);
      return {
        mapType,
        mapData,
      };
    }));
    results.forEach(result => {
      this.loadedMaps[result.mapType] = result.mapData;
    });
  }

  createPolygonSeries({includeCodes, mapType, fillColor}) {
    const polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.geodata = this.loadedMaps[mapType];
    if (includeCodes) {
      polygonSeries.include = includeCodes;
    }
    polygonSeries.useGeodata = true;

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}';
    if (fillColor) {
      polygonSeries.fill = am4core.color(fillColor);
      polygonTemplate.fill = am4core.color(fillColor);
    } else {
      polygonSeries.fill = am4core.color('black')
      polygonTemplate.fill = am4core.color('black');
    }
    return polygonSeries;
  }
}

export default MapManager;
