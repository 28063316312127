import NetworkComponent from './../NetworkComponent';
import RestApiBase from './../../Utilities/RestApiBase';

const urlServiceBaseUrl = 'http://localhost:3003';

class UrlServiceApi extends RestApiBase {
	constructor() {
		super(urlServiceBaseUrl);
	}

	async getOpenGraphInfoForUri(uri) {
		return this.post('test', {uri});
	}
}

const componentName = 'url_service_server';
class UrlServiceServer extends NetworkComponent {

	constructor() {
		super(componentName);
		const handlers = {};
		handlers.requestOpenGraphInfoForUri = this.requestOpenGraphInfoForUri.bind(this);
		super.connectToNetworkWithHandlers(handlers);
	}

	/*
	 * TODO: Associate name to party and identity provider services
	 * body = {
	 *   signature: String, //Signed By Service
	 *   payload:   {
	 *     partyId:            String,
	 *     identityProviderId: String,
	 *   },
	 * },
	*/
	async requestOpenGraphInfoForUri(body) {
		const {
			payload,
		} = body;

		let response;
    try {
    	const api = new UrlServiceApi();
    	response = await api.getOpenGraphInfoForUri(payload.uri);
    } catch (e) {
    	console.log(e);
	    return {msg: 'error'};
    }

	  return {	
	  	msg:     'success',
	  	payload: response,
		};
	}	

	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default UrlServiceServer;
