import NetworkComponent from './../NetworkComponent';

class UrlServiceClient extends NetworkComponent {

	constructor() {
		super('url_service_client');
		this.server = 'url_service_server';
		const handlers = {};
		super.connectToNetworkWithHandlers(handlers);
	}

	async requestOpenGraphInfoForUri(body) {
		const recipient = this.server;
		const requestType = 'requestOpenGraphInfoForUri';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			console.log(response);
			return;
			// throw new Error('Registration Failed');
		}
		return response.payload;
	}

	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default UrlServiceClient;
