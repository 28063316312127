import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import OpenGraphPreview from './../../../Shared/OpenGraphPreview/OpenGraphPreview';
import wrapperWithDimensions from './../../../Shared/HOC/wrapperWithDimensions';

import Api from './../../../MockApi/Api';

import './SourceLibrary.css';

class SourceLibrary extends React.Component {
  static propTypes = {
    goToNoteFormWithSourceId:  PropTypes.func,
    multiRow:                  PropTypes.bool,
    loadAll:                   PropTypes.bool,
    searchString:              PropTypes.string,
    startDateTime:             PropTypes.instanceOf(Date), 
    endDateTime:               PropTypes.instanceOf(Date), 
  }

  static defaultProps = {
    loadAll: true,
  }

  constructor(props) {
    super(props);
    this.state = {
    	sources: [],
    };
  }

	async componentDidMount() {
    if (this.props.loadAll) {
      const sources = await Api.getAllUserSources();
      this.setState({sources});
    }
	}

  async componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString ||
        prevProps.startDateTime !== this.props.startDateTime ||
        prevProps.endDateTime !== this.props.endDateTime) {
      const { searchString, startDateTime, endDateTime } = this.props;
      const searchParameters = {
        searchString, startDateTime, endDateTime,
      };
      console.log(searchParameters);
      if (!this.props.searchString && !this.props.startDateTime
          && !this.props.endDateTime) {
        const sources = await Api.getAllUserSources();
        this.setState({sources});
      } else {
        const newSources = await Api.getUserSourcesWithSearchParameters(searchParameters);
        this.setState({sources: newSources});
      }
    }
  }

  render() {
  	if (!this.state.sources || this.state.sources.length === 0) {
  		return (
  			<div
	        className={classnames({
	          'fill-parent-height': true,
	          'fill-parent-width':  true,
	        })}
  			>
	  			No Sources Yet
  			</div>
  		);
  	}
  	const sourceDivs = this.state.sources.map(source => {
  		if (source._openGraphInfo) {
	  		return (
	        <OpenGraphPreview 
            key={source.id}
	          sourceId={source.id}
            sourceUri={source._link}
	          openGraphInfo={source._openGraphInfo}
            savedDate={source.createdAt}
            addNoteToSource={() => this.props.goToNoteFormWithSourceId(source.id)}
	        />
	  		);
  		} else {
  			return (
  				<div>
  					No Open Graph Information
  				</div>
  			)
  		}
  	});
  	return (
			<div
        className={classnames({
          'fill-parent-height':  true,
          'fill-parent-width':   true,
          'multi-source-parent': this.props.multiRow,
          'scrollable':          true,
        })}
			>
  			{sourceDivs}	
			</div>
	 	);
  }
};

export default wrapperWithDimensions(SourceLibrary);
