import RecordWithHistory from './../RecordTypes/RecordWithHistory';
import NoteService from './../AtomicContent/NoteService';

import AuthService from './../../AuthService';

const UserNoteDef = {
  userId:    { type: 'id', collection: 'User', hashable: true },
  noteId:    { type: 'id', collection: 'Note', hashable: true },
  dateAdded: { type: 'date' },
};

class UserNoteService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'user_note', UserNoteDef, true);
  }

  async getAllUserNotes() {
    const userId = AuthService.getUserId();
    const notes = await super.findWithField('userId', userId);
    return notes.sort((a,b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      } else if (a.createdAt > b.createdAt) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  async getUserNotesWithSearchParameters({searchString, startDateTime, endDateTime}) {
    // Match Search String To Description
    // Match Search String To Title
    // Think about user dictionary and dictionary settings
    const notes = await this.getAllUserNotes();
    const filtered = notes.filter(note => {
      return this.filterNoteWithSearchParameters(note, {searchString, startDateTime, endDateTime});
    }); 
    return filtered;
  }

  filterNoteWithSearchParameters(note, {searchString, startDateTime, endDateTime}) {
    if (startDateTime && note.createdAt < startDateTime) {
      return false;
    }
    if (endDateTime && note.createdAt > endDateTime) {
      return false;
    }
    if (!searchString) {
      return true;
    }
    if (note._text.includes(searchString)) {
      return true;
    }
    return false;
  }

  async findOrCreateNoteWithText(text) {
  	const userId = AuthService.getUserId();
		const note = await NoteService.findOrCreateNoteWithText(text);
		const queryObj = {
			userId,
			noteId: note.id,
		};
    const existingUserNote = await super.findOneWithFields(queryObj)
    if (existingUserNote) {
    	return {
    		status: 'found',
    		existingUserNote,
    	};
    }
    // Notes: Add Source Hash to track version
		const userNoteObj = {
			userId:         userId,
			noteId:         note.id,
			// Duplicate Fields For Queries
			_text:          note.text,
		};
		const newUserNote = await super.createNewRecord(userNoteObj);
  	return {
  		status: 'created',
  		newUserNote,
  	};
  }
}

export default (new UserNoteService());
