import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Icon from './../Icons/Icon';

function IconButtonWithTooltip(props) {
	const {
		iconType,
		tooltipText,
		onClick,
	} = props;
	const tooltipKey = tooltipText + 'tooltip';
  return (
  	<button
  		onClick={onClick}
  		data-tip={tooltipText}
  		data-for={tooltipKey}>
  		<Icon iconType={iconType}/>
      <ReactTooltip id={tooltipKey} />
  	</button>
  );
}

IconButtonWithTooltip.propTypes = {
	iconType:    PropTypes.string.isRequired,
	tooltipText: PropTypes.string.isRequired,
	onClick:     PropTypes.func.isRequired,
};

export default IconButtonWithTooltip;
