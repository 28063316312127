import IndexedDBCollection from './../IndexedDb/IndexedDbCollection'; 

class ComponentDB {
	constructor(dbPrefix, collections) {
		this.dbPrefix = dbPrefix;
		Object.keys(collections).forEach(collectionName => {
			const primaryField = collections[collectionName].primaryField;
			this[collectionName] = new IndexedDBCollection(dbPrefix, collectionName,
																							       primaryField);	
		});
	}
}
export default ComponentDB;
