import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Icon from './../Icons/Icon';

function AddWithTooltip(props) {
	const {
		tooltipText,
		onClick,
	} = props;
	const tooltipKey = tooltipText + 'tooltip';
  return (
  	<button
  		onClick={onClick}
  		data-tip={tooltipText}
  		data-for={tooltipKey}>
  		<Icon iconType="add"/>
      <ReactTooltip id={tooltipKey} />
  	</button>
  );
}

AddWithTooltip.propTypes = {
	tooltipText: PropTypes.string.isRequired,
	onClick:     PropTypes.func.isRequired,
};

export default AddWithTooltip;
