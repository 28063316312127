import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spacer from './../../../Shared/Utilities/Spacer';
import MultiSwitch from './../../../Shared/Buttons/MultiSwitch';

// import './AccountHeader.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

class NoteHeader extends React.Component {

  static propTypes = {
    selectedNoteType: PropTypes.string.isRequired,
    noteTypeSelected: PropTypes.func.isRequired,
    noteTypes:        PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidUpdate(prevProps) {

  }

  render() {
    const {
      noteTypes,
      selectedNoteType,
      noteTypeSelected,
    } = this.props;
  	return (
  		<div className="account-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
          <div
            className={classnames({
              'fill-parent-height': true,
              'vertically-align-text': true,
            })}
            style={{color: 'mediumpurple', fontSize: '22px'}}>
            Note Type
          </div>
          <Spacer type="horizontal" width="10px"/>
          <MultiSwitch
            wrapperClasses={{'inline-display': true}}
            options={noteTypes}
            selectValue={noteTypeSelected}
            selectedValue={selectedNoteType}
          />
      	</div>
        <div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'right'}}>
        </div>
  		</div>
	 	);
  }
};
export default NoteHeader;
