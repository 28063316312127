import NetworkComponent from './../NetworkComponent';

class LedgerClient extends NetworkComponent {

	constructor() {
		super('ledger_client');
		this.server = 'ledger_server';
		const handlers = {};
		super.connectToNetworkWithHandlers(handlers);
	}

	async registerPartyWithPublicIdForService(body) {
		const recipient = this.server;
		const requestType = 'registerPartyWithPublicIdForService';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Registration Failed');
		}
		return;
	}

	async loginWithUsername(body) {
		const recipient = this.server;
		const requestType = 'loginWithUsername';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Login Failed');
		}
		return;
	}

	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default LedgerClient;
