import NetworkComponent from './../NetworkComponent';
import MapServiceApi from './MapServiceApi';

const componentName = 'map_service_server';
class MapServiceServer extends NetworkComponent {

	constructor() {
		super(componentName);
		const handlers = {};
		handlers.requestMapOptionsThatMatchSearchString = this.requestMapOptionsThatMatchSearchString.bind(this);
		handlers.requestFeatureOptionsForMapPath = this.requestFeatureOptionsForMapPath.bind(this);
		handlers.requestDataForMapOfType = this.requestDataForMapOfType.bind(this);
		super.connectToNetworkWithHandlers(handlers);
	}

	async requestFeatureOptionsForMapPath(body) {
		const {
			payload,
		} = body;

		let response;
    try {
    	const api = new MapServiceApi();
    	response = await api.getFeaturesFromMapPath(payload.searchString);
    } catch (e) {
    	console.log(e);
	    return {msg: 'error'};
    }

	  return {	
	  	msg:     'success',
	  	payload: response,
		};
	}	


	async requestMapOptionsThatMatchSearchString(body) {
		const {
			payload,
		} = body;

		let response;
    try {
    	const api = new MapServiceApi();
    	response = await api.getMapOptionsThatMatchSearchString(payload.searchString);
    } catch (e) {
    	console.log(e);
	    return {msg: 'error'};
    }

	  return {	
	  	msg:     'success',
	  	payload: response,
		};
	}	

	async requestDataForMapOfType(body) {
		const {
			payload,
		} = body;

		let response;
    try {
    	const api = new MapServiceApi();
		console.log('Sending Request To Server');
    	response = await api.getDataForMapOfType(payload.mapType);
    } catch (e) {
    	console.log(e);
	    return {msg: 'error'};
    }

	  return {	
	  	msg:     'success',
	  	payload: response,
		};
	}	

	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default MapServiceServer;
