import { v4 as uuid } from 'uuid'; 
import objectHash from 'object-hash';

import IndexedDbCollection from './../../../Shared/IndexedDb/IndexedDbCollection'; 
import BasicQueue from './../../../Shared/Utilities/BasicQueue'; 
import AuditTrailService from './AuditTrailService'; 


class RecordWithHistory {
  constructor(serverName, storeName, recordDefinition,
              saveHistoryByDefault = false) {
    this.collectionName = `${serverName}_${storeName}`; 
    this.storeName = storeName;
    this.saveHistoryByDefault = saveHistoryByDefault;
    this.stateCollection = new IndexedDbCollection(serverName,
                                                   `${storeName}State`, 'id');  
    this.collectionEditQueue = new BasicQueue();
    this.fieldsToHash = Object.keys(recordDefinition).filter(fieldName => {
      return recordDefinition[fieldName].hashable;
    });
  }

  // 1) Assign An Id
  // 2) Hash record then add to tapestry, then create object.
  // 3) This will create a failsafe to prevent the creation of objects before 
  //    the hashing 
  createNewRecord(newDoc) {
    newDoc.id = uuid();
    newDoc.createdAt = new Date();
    // TODO: Add function to determine if we are saving changes for record type 
    // for user here, for now they will be determined by type.
    if (this.saveHistoryForDoc(newDoc)) {
      // TODO: Consider Changing this to an array in the event we allow 
      // multiple start stop dates
      newDoc.saveHistory = true;
      newDoc.historyStarted = new Date();
      newDoc.changeLog = [];
    }
    const promiseGenerator = () => {
      newDoc.hash = this.hashRecord(newDoc);
      return this.stateCollection.add(newDoc);
    };
    return this.collectionEditQueue.addToQueue(promiseGenerator);
  }

  saveHistoryForDoc(newDoc) {
    return this.saveHistoryByDefault;
  }

  async updateRecord(editorId, recordId, recordHash,
                     {addToArray, fieldsToUpdate = {}, fieldsToRemove}) {
    const promiseGenerator = async () => {
      const currentVersion = await this.stateCollection.findOneWithPrimaryField(recordId);
      if (recordHash !== currentVersion.hash) {
        // Edits Rejected because hashes didnt match;
        // i.e: attempted to update outdated record
        return {status: 'rejected', record: currentVersion};
      }

      // TODO: Should Probably be a copy maybe?
      let updatedVersion = currentVersion;
      const allowedToEdit = await this.allowedToEditRecord(editorId,
                                                           currentVersion);
      if (!allowedToEdit) {
        return {status: 'rejected', record: currentVersion};
      }
      const timestamp = new Date();
      const recordType = this.collectionName;
      const validatedChanges = {};
      // ///////////////////////////////////////////////////////////////////////
      // Verify All Changes Are Valid
      if (addToArray) {
        const changeInvalid = Object.keys(addToArray).find(fieldName => {
          const values = currentVersion[fieldName];
          const newValues = addToArray[fieldName];
          const duplicateValue = newValues.find(newValue => {
            return values.includes(newValue);
          });
          if (duplicateValue) {
            return true;
          }
          validatedChanges[fieldName] = this.copy(values).concat(this.copy(newValues));
          return false;
        });
        if (changeInvalid) {
          return {status: 'rejected', record: currentVersion};
        }
      }

      if (Object.keys(fieldsToUpdate).length) {
        const changeInvalid = Object.keys(fieldsToUpdate).find(fieldName => {
          const oldValue = currentVersion[fieldName];
          const newValue = fieldsToUpdate[fieldName];
          if (oldValue === newValue) {
            return true;
          }
          validatedChanges[fieldName] = newValue;
          return false;
        });
        if (changeInvalid) {
          return {status: 'rejected', record: currentVersion};
        }
      }
      if (fieldsToRemove) {
        const changeInvalid = Object.keys(fieldsToRemove).find(fieldName => {
          const oldValue = currentVersion[fieldName];
          if (!oldValue) {
            return true;
          }
          validatedChanges[fieldName] = undefined;
          return false;
        });
        if (changeInvalid) {
          return {status: 'rejected', record: currentVersion};
        }
      }
      // ///////////////////////////////////////////////////////////////////////
      // Apply Changes
      if (Object.keys(validatedChanges).length) {
        await Object.keys(validatedChanges).reduce((promise, fieldName) => {
          return promise.then(async() => {
            const oldValue = currentVersion[fieldName];
            const newValue = validatedChanges[fieldName];
            // TODO: Maybe Do Some Field Validation to ensure types
            if (newValue) {
              updatedVersion[fieldName] = validatedChanges[fieldName];
            } else {
              delete updatedVersion[fieldName];
            }
            // Add To Audit Trail

            const trailEntry = await AuditTrailService.addTrailEntry(editorId,
                                                                     timestamp,
                                                                     recordType,
                                                                     recordId,
                                                                     fieldName,
                                                                     oldValue,
                                                                     newValue)
            updatedVersion.changeLog.push({
              timestamp:    timestamp,
              trailEntryId: trailEntry.id,
            });
          });
        }, Promise.resolve());
      }
      // Open Question: should we store the hash in the audit trail?
      // Should be able to reproduce, but might make searching history easier
      // If we can use hashes
      const newHash = this.hashRecord(updatedVersion); 
      updatedVersion.updatedAt = new Date();
      updatedVersion.hash = newHash;
      const updateFunc = () => {
        return updatedVersion;
      };
      const finalVersion = await this.stateCollection.findOneWithPrimaryFieldAndUpdate(recordId,
                                                                                       updateFunc);
      return {status: 'applied', record: finalVersion};
    };
    return this.collectionEditQueue.addToQueue(promiseGenerator);
  }

  async allowedToEditRecord(editorId, record) {
    // TODO: Implement
    return true;
  }
  // ///////////////////////////////////////////////////////////////////////////
  // Pass Throughs
  async findOneWithFields(fieldMap) {
    return this.stateCollection.findOneWithFields(fieldMap);
  }
  async findOneWithPrimaryField(itemId) {
    return this.stateCollection.findOneWithPrimaryField(itemId);
  }

  async findWithField(fieldName, fieldValue) {
    return this.stateCollection.findWithField(fieldName, fieldValue);
  }

  getLatestItemStatesWithIds(itemIds) {
    return this.stateCollection.findWithPrimaryField(itemIds);
  }

  hashRecord(record) {
    const hashableObj = this.fieldsToHash.reduce((subObj, fieldName) => {
      subObj[fieldName] = record[fieldName];
      return subObj;
    }, {}); 
    hashableObj.updatedAt = record.updatedAt;
    return objectHash(hashableObj);
  } 

  copy(object) {
    return JSON.parse(JSON.stringify(object))
  }
}

export default RecordWithHistory;
