import React from 'react';

function Spacer({type, height, width, background}) {
  if (type === 'vertical') {
    return (
      <div style={{height: height}}>
      </div>
    );
  } else if (type === 'horizontal') {
  	const styles = {
			display: 'inline-block',
			width:   width,
			height:  '100%',
  	};
  	if (background) {
  		styles.background = background;
  	}
    return (
    	<div style={styles}></div>
    );
  }
}
export default Spacer;
