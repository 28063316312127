import objectHash from 'object-hash';
import { v4 as uuid } from 'uuid'; 

import NetworkComponent from './../NetworkComponent';
import WebCrypto from './../../Utilities/WebCrypto';
import LedgerClient from './../Ledger/LedgerClient';
import TapestryClient from './../Tapestry/TapestryClient';

import ComponentDB  from './../ComponentDB';

const componentName = 'identity_provider_server';
class IdentityProviderServer extends NetworkComponent {

	static getCollections() {
		return {
			Users: {
				primaryField: 'id',
				fieldNames: [
					'id',
					'username', 
					'publicSigningKeyHash', 
				  // 'storablePublicSigningKey',
				  'partyId', 
				  'services',
				],
			},
		};
	}
	
	static getComponentName() {
		return componentName;	
	}

	constructor() {
		super(componentName);
		const handlers = {};
		handlers.registerPublicKeyWithUsername = this.registerPublicKeyWithUsername.bind(this);
		handlers.loginWithUsername = this.loginWithUsername.bind(this);
		handlers.requestUserIdForService = this.requestUserIdForService.bind(this);
		// DANGER: Remove Before Production
		handlers.registerWithTapestryService = this.registerWithTapestryService.bind(this);

		super.connectToNetworkWithHandlers(handlers);
	  const collections = IdentityProviderServer.getCollections();
		this.DB = new ComponentDB(componentName, collections);
	}
	/*
	 * body = {
	 *   signature: String,
	 *   payload:   {
	 *     nonce:            String,
	 *     username:         String,
	 *     publicSigningKey: String, 
	 *   },
	 * },
	 * Open Questions:
	 * Should we use public keys as an identifier?
	*/
	async loginWithUsername(body) {
		const {
			signature,
			payload,
		} = body;
		const storablePublicSigningKey = payload.publicSigningKey;
		const publicSigningKeyHash = this.getIdentifierForKey(storablePublicSigningKey);
		const userFields = 'id storablePublicSigningKey username';
		const user = await this.getUserForKeyHash(publicSigningKeyHash, userFields);
		if (!user) {
			console.log('User Not Found');
	    return {msg: 'error'};
		}
    try {
	    const publicSigningKey = await WebCrypto.importPublicKey(user.storablePublicSigningKey);
	    await WebCrypto.verifySignature(publicSigningKey, signature, payload);
	    // Should we be checking this?
			if (user.username !== payload.username) {
				console.log('User Name did not find');
		    return {msg: 'error'};
			}
    } catch (e) {
				console.log(e);
	    return {msg: 'error'};
    }
    const userServices = await this.getServicesForUserId(user.id);
    return {
    	msg:  'success',
    	data: {
    		encrypted: false,
    		data:      {
    			services: userServices,
    		},
    	},
    };
	}	

	/*
	 * body = {
	 *   signature: String,
	 *   payload:   {
	 *     nonce:            String,
	 *     username:         String,
	 *     publicSigningKey: String, 
	 *     serviceName:      String,
	 *   },
	 * },
	 * Open Questions:
	 * Should we use public keys as an identifier?
	*/
	async requestUserIdForService(body) {
		let user;
		try {
			user = await this.validateSignatureFromRequest(body);
		} catch (e) {
	    return {msg: 'error'};
		}

    const userServices = await this.getServicesForUserId(user.id);
    if (!userServices.includes(body.payload.serviceName)) {
	    return {msg: 'error'};
    }
    const payload = {
 			partyId: user.partyId,
    };
    const payloadSignature = await this.signPayload(payload);
    return {
    	msg:  'success',
    	data: {
    		signature: payloadSignature,
    		encrypted: false,
    		payload,
    	},
    };
	}	

	async signPayload(payload) {
		// TODO: Implement
		return 'From Identity Provider';
	}

	async validateSignatureFromRequest(requestBody) {
		const {
			signature,
			payload,
		} = requestBody;
		const storablePublicSigningKey = payload.publicSigningKey;
		const publicSigningKeyHash = this.getIdentifierForKey(storablePublicSigningKey);
		const userFields = 'id storablePublicSigningKey username partyId';
		// Consider requesting username and public key together to prevent fishing
		const user = await this.getUserForKeyHash(publicSigningKeyHash, userFields);
		if (!user) {
			throw new Error('No User For Key Hash');
		}
    try {
	    const publicSigningKey = await WebCrypto.importPublicKey(user.storablePublicSigningKey);
	    await WebCrypto.verifySignature(publicSigningKey, signature, payload);
    } catch (e) {
			throw new Error('Signature Failed');
    }
    return user;
	}

	/*
	 * body = {
	 *   signature: String,
	 *   payload:   {
	 *     nonce:            String,
	 *     username:         String,
	 *     publicSigningKey: String, 
	 *   },
	 * },
	 * Open Questions:
	 * Should we make usernames unique?
	*/
	async registerPublicKeyWithUsername(body) {
		const {
			signature,
			payload,
		} = body;
		const storablePublicSigningKey = payload.publicSigningKey;
		const publicSigningKeyHash = this.getIdentifierForKey(storablePublicSigningKey);

		const keyRegistered = await this.publicSigningKeyHasBeenRegistered(publicSigningKeyHash);
		if (keyRegistered) {
			console.log(publicSigningKeyHash);
			console.log('Key Registered');
	    return {msg: 'error'};
		}

    try {
	    const publicSigningKey = await WebCrypto.importPublicKey(storablePublicSigningKey);
	    await WebCrypto.verifySignature(publicSigningKey, signature, payload);
    } catch (e) {
    	console.log('Signature failed');
	    return {msg: 'error'};
    }

		const partyId = uuid();
    try {
    	const body = {
    		payload: {
    			partyId,
    			serviceName: componentName
    		}
    	};
	    await (new LedgerClient()).registerPartyWithPublicIdForService(body);
    } catch (e) {
    	console.log();
    	console.log('Failed to Register With Ledger');
	    return {msg: 'error'};
    }

    try {
	    const username = payload.username;
	    await this.registerUsernameWithPublicKey(username, storablePublicSigningKey,
																               publicSigningKeyHash, partyId)
	    return {msg: 'success'};
    } catch (e) {
    	console.log('Registration Failed');
	    return {msg: 'error'};
    }
	}	

	destroy() {
	  super.disconnectFromNetwork();	
	}
	// TODO: When enough private methods have been written for a collection
	// create them into their own class
	// ///////////////////////////////////////////////////////////////////////////
	// Private Methods
	async addServiceName(userId, serviceName) {
		const updateFunc = (oldDoc) => {
			if (!oldDoc.services) {
				oldDoc.services = [];
			}
			if (oldDoc.services.includes(serviceName)) {
				return oldDoc;
			}
			oldDoc.services.push(serviceName);
			return oldDoc;
		}
	  const doc = await this.DB.Users.findOneAndUpdate('id', userId, updateFunc);
	  return doc;
	}

	getIdentifierForKey(key) {
    return objectHash(key); 
	}

	async getServicesForUserId(userId) {
		const fields = 'services';
	  const doc = await this.DB.Users.findOneWithField('id',
	  																								 userId,
	  																								 fields);
	  if (!doc.services) {
	  	return [];
	  }
	  return doc.services;
	}


	async getUserForKeyHash(publicSigningKeyHash, fields) {
	  const doc = await this.DB.Users.findOneWithField('publicSigningKeyHash',
	  																								 publicSigningKeyHash,
	  																								 fields);
	  return doc;
	}

	async publicSigningKeyHasBeenRegistered(publicSigningKeyHash) {
	  const doc = await this.DB.Users.findOneWithField('publicSigningKeyHash',
	  																								 publicSigningKeyHash);
	  return !!doc;
	}

	async registerUsernameWithPublicKey(username, storablePublicSigningKey,
																      publicSigningKeyHash, partyId) {
		const doc = {
			id: uuid(),
			username, 
			publicSigningKeyHash, 
		  storablePublicSigningKey,
		  partyId, 
		};
	  return this.DB.Users.add(doc);
	}
	// ///////////////////////////////////////////////////////////////////////////
	// Dev Only 
	/*
	 * body = {
	 *   payload:   {
	 *     username: String,
	 *   },
	 * },
	 * Open Questions:
	 * Should we use public keys as an identifier?
	*/
	async registerWithTapestryService(body) {
		const {
			payload,
		} = body;
		const user = await this.getUserForUsername(payload.username);
		if (!user) {
	    return {msg: 'error'};
		}
    try {
    	const identityProviderId = user.id;
    	const partyId = user.partyId;
    	const tbody = {
    		payload: {
	    		identityProviderId,
	    		partyId,
	    	},
    	};
	    await (new TapestryClient()).registerPartyIdWithPublicIdForService(tbody);
    } catch (e) {
	    return {msg: 'error'};
    }
    try {
	    await this.addServiceName(user.id, 'Tapestry');
    } catch (e) {
	    return {msg: 'error'};
    }
    return {msg: 'success'};
	}	
	// Likely only gonna wake for early server testing before duplicates
	async getUserForUsername(username) {
	  const doc = await this.DB.Users.findOneWithField('username', username);
	  return doc;
	}


}

export default IdentityProviderServer;
