import React from 'react';
import classnames from 'classnames';


import NoteLibrary from './../Global/NoteCollectionDisplay/NoteLibrary';
import SourceLibrary from './../Global/SourceCollectionDisplay/SourceLibrary';
import LinkBasedStoryForm from './../Workspace/LinkBasedStoryForm/LinkBasedStoryForm';
import AddNoteForm from './../Global/FormViews/AddNoteForm';
import SearchHeader from './SearchHeader';
import PersonalLibraryHeader from './PersonalLibraryHeader';

import wrapperWithDimensions from './../../Shared/HOC/wrapperWithDimensions';
import CommonClassMethods from './../../Shared/Utilities/CommonClassMethods';

const ViewType = {
  SOURCES:              'SOURCES',
  ADD_SOURCE:           'ADD_SOURCE',
  NOTES:                'NOTES',
  ADD_NOTE:             'ADD_NOTE',
  ADD_NOTE_TO_SOURCE:   'ADD_NOTE_TO_SOURCE',
};

class PersonalLibrary extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      viewType:         ViewType.SOURCES,
      previousViewType: ViewType.SOURCES,
      startDateTime:    now,
      endDateTime:      now,
      noteSourceId:     null,
    };
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
  }

	async componentDidMount() {
	}

  async componentWillUnmount() {
  }

  searchStringUpdated = updatedString => {
    this.setState({searchString: updatedString});
  }

  dateTimeRangeUpdated = (startDateTime, endDateTime) => {
    this.setState({
      startDateTime: startDateTime,
      endDateTime:   endDateTime,
    });
  }

  goToNoteFormWithSourceId = (sourceId) => {
    this.updateViewType(ViewType.ADD_NOTE_TO_SOURCE, {noteSourceId: sourceId});
  }

  updateViewType = (newViewType, otherUpdates = {}) => {
    const updatedState = Object.assign(otherUpdates, {
      previousViewType: this.state.viewType,
      viewType:         newViewType,
    });
    this.setState(updatedState);
  } 

  exitNoteForm = () => {
    this.updateViewType(this.state.previousViewType, {noteSourceId: null});
  }

  render() {
    const headerPxHeight = 53;
    const searchHeaderPxHeight = 53;
  	let mapPxHeight = this.props.parentHeight - headerPxHeight;
    if ([ViewType.SOURCES, ViewType.NOTES].includes(this.state.viewType)) {
      mapPxHeight = mapPxHeight - searchHeaderPxHeight;
    }
    const viewOptions = [
      {
        value: ViewType.SOURCES,
        name: 'All Sources',
      },
      {
        value: ViewType.ADD_SOURCE,
        name: 'Add Source',
      },
      {
        value: ViewType.NOTES,
        name: 'All Notes',
      },
      {
        value:   ViewType.ADD_NOTE,
        aliases: [ViewType.ADD_NOTE_TO_SOURCE],
        name:    'Add Note',
      },
    ];
    const switchAllowed = this.state.viewType !== ViewType.ADD_NOTE_TO_SOURCE;

    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}>
        <div className='header' style={{height: `${headerPxHeight}px`}}>
          <PersonalLibraryHeader 
            selectedViewOption={this.state.viewType}
            viewOptionSelected={option => this.updateViewType(option)}
            viewOptions={viewOptions}
            switchAllowed={switchAllowed}
          />
        </div>
        {  [ViewType.SOURCES, ViewType.NOTES].includes(this.state.viewType) &&
          <div className='header' style={{height: `${searchHeaderPxHeight}px`}}>
            <SearchHeader 
              searchStringUpdated={this.searchStringUpdated}
              dateTimeRangeUpdated={this.dateTimeRangeUpdated}
              startDateTime={this.state.startDateTime}
              endDateTime={this.state.endDateTime}
              />
          </div>
        }
        <div 
          className={classnames({
            'fill-parent-width':  true,
          })}
          style={{height: `${mapPxHeight}px`}}>
          { this.state.viewType === ViewType.ADD_SOURCE &&
            <LinkBasedStoryForm
              exitForm={() => this.updateViewType(ViewType.SOURCES)}
            />
          }
          { this.state.viewType === ViewType.SOURCES &&
            <SourceLibrary
              multiRow={true}
              searchString={this.state.searchString}
              startDateTime={this.state.startDateTime}
              endDateTime={this.state.endDateTime}
              goToNoteFormWithSourceId={this.goToNoteFormWithSourceId}
            />
          }
          { [ViewType.ADD_NOTE, ViewType.ADD_NOTE_TO_SOURCE].includes(this.state.viewType) &&
            <AddNoteForm
              exitForm={() => this.exitNoteForm()}
              sourceId={this.state.noteSourceId}
            />
          }
          { this.state.viewType === ViewType.NOTES &&
            <NoteLibrary
              multiRow={true}
              searchString={this.state.searchString}
              startDateTime={this.state.startDateTime}
              endDateTime={this.state.endDateTime}
            />
          }
		    </div>
    	</div>
    );
  }
}

export default wrapperWithDimensions(PersonalLibrary);
