import React from 'react';
import PropTypes from 'prop-types';

import HeaderWithButton from './../Header/HeaderWithButton';

import './ScrollableWithHeader.css';


class ScrollableWithHeader extends React.Component {

  static propTypes = {
  	headerTitle:         PropTypes.string.isRequired,
    headerButtonHandler: PropTypes.func,
    headerButtonName:    PropTypes.string,
    noScrolling:         PropTypes.bool,
    headerMarginTop:     PropTypes.number,
    updateHeight:        PropTypes.func,
    parentHeight:        PropTypes.number,
  }

  static defaultProps = {
    noScrolling:      false,
  }

  constructor(props) {
    super(props);
    this.state = {
      height: this.props.parentHeight,
    };
  }

  componentDidMount() {
    if (this.props.parentHeight) {
      return;
    }
    const height = this.divElement.clientHeight;
    this.setState({ height });
    if (this.props.updateHeight) {
      this.props.updateHeight(height);
    }
  }

  render() {
    const headerPxHeight = 45;
    let bodyHeight = '100%';
    if (this.state.height && this.props.headerMarginTop) {
      bodyHeight = `${this.state.height - headerPxHeight - this.props.headerMarginTop}px`;
    } else if (this.state.height) {
      bodyHeight = `${this.state.height - headerPxHeight}px`;
    }
    const bodyStyles = {
      height: bodyHeight,
    };
    bodyStyles.overflowX = 'hidden';
    if (!this.props.noScrolling) {
      bodyStyles.overflowY = 'scroll';
    } else {
      bodyStyles.overflowY = 'hidden';
    }
  	return (
      <div
        style={{height: '100%'}}
        ref={ (divElement) => { this.divElement = divElement } }>
        <HeaderWithButton
          title={this.props.headerTitle}
          buttonHandler={this.props.headerButtonHandler}
          buttonName={this.props.headerButtonName}
          height={headerPxHeight}
          marginTop={this.props.headerMarginTop}
        />
      	<div style={bodyStyles}>
      		{this.props.children}
        </div>
	    </div>
	 	);
  }
}
export default ScrollableWithHeader;
