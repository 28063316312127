const CryptoUtil = {
	getMessageEncoding,
	getSignatureFromString,
};

function getSignatureFromString(stringSignature) {
  return Uint8Array.from(atob(stringSignature), c => c.charCodeAt(0))
}
function getMessageEncoding(message) {
  const enc = new TextEncoder();
  return enc.encode(message);
}

export default CryptoUtil;
