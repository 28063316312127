import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ScrollableWithHeader from './../ScrollableWithHeader/ScrollableWithHeader'

import './MultiSelectableList.css';

class MultiSelectableList extends React.Component {

  static propTypes = {
    selectOption:    PropTypes.func.isRequired,
    unselectOption:  PropTypes.func.isRequired,
    options:         PropTypes.array.isRequired,
    parentHeight:    PropTypes.number.isRequired,
    searchFunction:  PropTypes.func,
    selectedOptions: PropTypes.array,
    optionsTitle:    PropTypes.string,
  }

  static defaultProps = {
    selectedOptions: [],
    optionsTitle:    'Options',
  }

  constructor(props) {
    super(props);

    this.state = {
      visibleOptions: this.props.options,
    };
    this.textInput = React.createRef();
  }
  onSelectMap = value => {
    if (this.props.selectedOptions.includes(value)) {
      this.props.unselectOption(value);
    } else {
      this.props.selectOption(value);
    }
    if (this.state.searchString) {
      this.textInput.current.focus();
    }
  }

  searchStringUpdated = async event => {
    const newSearchString = event.target.value;
    const visibleOptions = await this.props.searchFunction(this.props.options,
                                                           newSearchString);
    this.setState({ visibleOptions, searchString: newSearchString })
  };
  render() {
		// const selectedMap = this.state.maps.find(map => map.name === this.state.selectedMapType);
		// const mapNames =  selectedMap ? selectedMap.mapNames : [];
    const sortedOptions = [...this.state.visibleOptions].sort((a, b) => {
      let aSelected, bSelected;
      if (typeof a === 'string') {
        aSelected = this.props.selectedOptions.includes(a);
        bSelected = this.props.selectedOptions.includes(b);
      } else {
        aSelected = this.props.selectedOptions.includes(a.value);
        bSelected = this.props.selectedOptions.includes(b.value);
      }
      if (aSelected && bSelected) {
        return 0;
      } else if (!aSelected && !bSelected) {
        return 0;
      } else if (aSelected) {
        return -1;
      } else if (bSelected) {
        return 1;
      }
      return 0;
    });
    const optionDivs = sortedOptions.map(option => {
      if (typeof option === 'string') {
        const classNamesConfig = {
          'selectable-div': true,
          selected:         this.props.selectedOptions.includes(option),
        };
        return (
          <div
            key={option}
            onClick={() => {this.onSelectMap(option)}}
            className={classnames(classNamesConfig)}>
            {option}
          </div>
        );
      } else {
        const classNamesConfig = {
          'selectable-div': true,
          selected:         this.props.selectedOptions.includes(option.value),
        };
        return (
          <div
            key={option.value}
            onClick={() => {this.onSelectMap(option.value)}}
            className={classnames(classNamesConfig)}>
            {option.label}
          </div>
        );
      }
    });
    const wrapperDivs = {
      overflow: 'hidden',
      // height: '100%',
      boxSizing: 'border-box',
      width: '100%',
      padding: '10px',
    };
    const searchStyles = {
      marginBottom: '10px',
      boxSizing: 'border-box',
      width:'100%',
      float: 'left',
      height: '30px',
      fontSize: '16px',
    };
    const headerMarginTop = 40 ;
  	return (
      <div style={wrapperDivs}>
        <input 
          ref={this.textInput}
          style={searchStyles}
          type="text" 
          value={this.state.searchString}
          onChange={this.searchStringUpdated}
          placeholder="Search Maps" 
        />
        <ScrollableWithHeader 
          headerTitle={this.props.optionsTitle}
          headerMarginTop={headerMarginTop}
          parentHeight={this.props.parentHeight}
        >
          {optionDivs}
        </ScrollableWithHeader>
      </div>
	 	);
  }
};
export default MultiSelectableList;
