import objectHash from 'object-hash';
import { v4 as uuid } from 'uuid'; 

import NetworkComponent from './../NetworkComponent';

import ComponentDB  from './../ComponentDB';

const componentName = 'ledger_server';
class LedgerServer extends NetworkComponent {

	static getCollections() {
		return {
			Parties: {
				primaryField: 'id',
				fieldNames:   ['id', 'partyId', 'serviceName']
			},
		};
	}
	static getComponentName() {
		return componentName;	
	}

	constructor() {
		super(componentName);
		const handlers = {};
		handlers.registerPartyWithPublicIdForService = this.registerPartyWithPublicIdForService.bind(this);
		// handlers.loginWithUsername = this.loginWithUsername.bind(this);

		super.connectToNetworkWithHandlers(handlers);
		const collections = LedgerServer.getCollections();
		this.DB = new ComponentDB(componentName, collections);
	}

	/*
	 * body = {
	 *   signature: String, //Signed By Service
	 *   payload:   {
	 *     nonce:       String,
	 *     partyId:     String,
	 *     serviceName: String, 
	 *   },
	 * },
	*/
	async registerPartyWithPublicIdForService(body) {
		const {
			payload,
		} = body;
		const {
			partyId,
			serviceName,	
		} = payload; 
		const partyRegistered = await this.partyIdHasBeenRegistered(partyId);
		if (partyRegistered) {
			console.log('Party Registered');
	    return {msg: 'error'};
		}
		// TODO: Verify signature from server

    try {
	    await this.registerPartyIdForService(serviceName, partyId);
	    return {msg: 'success'};
    } catch (e) {
    	console.log('Registration Failed');
	    return {msg: 'error'};
    }
	}	

	destroy() {
	  super.disconnectFromNetwork();	
	}
	// TODO: When enough private methods have been written for a collection
	// create them into their own class
	// ///////////////////////////////////////////////////////////////////////////
	// Private Methods
	getIdentifierForKey(key) {
    return objectHash(key); 
	}

	async partyIdHasBeenRegistered(partyId) {
	  const doc = await this.DB.Parties.findOneWithField('partyId', partyId);
	  return !!doc;
	}

	async registerPartyIdForService(serviceName, partyId) {
		const doc = {
			id:          uuid(),
			partyId:     partyId,
			serviceName: serviceName, 
		};
	  return this.DB.Parties.add(doc);
	}
}

export default LedgerServer;
