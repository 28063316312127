import { v4 as uuid } from 'uuid'; 

import NetworkComponent from './../NetworkComponent';

import ComponentDB  from './../ComponentDB';

const componentName = 'tapestry_server';
class TapestryServer extends NetworkComponent {

	static getCollections() {
		return {
			Users: {
				primaryField: 'id',
				fieldNames: ['id', 'partyId', 'identityProviderId'],
			},
		};
	}
	static getComponentName() {
		return componentName;	
	}

	constructor() {
		super(componentName);
		const handlers = {};
		handlers.requestTokenForPartyId = this.requestTokenForPartyId.bind(this);
		handlers.registerPartyIdWithPublicIdForService = this.registerPartyIdWithPublicIdForService.bind(this);
		super.connectToNetworkWithHandlers(handlers);
		const collections = TapestryServer.getCollections();
		this.DB = new ComponentDB(componentName, collections);
	}

	/*
	 * TODO: Associate name to party and identity provider services
	 * body = {
	 *   signature: String, //Signed By Service
	 *   payload:   {
	 *     partyId:            String,
	 *     identityProviderId: String,
	 *   },
	 * },
	*/
	async requestTokenForPartyId(body) {
		const {
			signature,
			payload,
		} = body;

    try {
	    await this.verifyIdentityProviderSignature(signature, payload);
    } catch (e) {
    	console.log('Invalid Signature');
	    return {msg: 'error'};
    }

		const {
			// identityProviderId,
			partyId,
		} = payload; 
		const partyRegistered = await this.partyIdHasBeenRegistered(partyId);
		if (!partyRegistered) {
			console.log('Party Not Registered');
	    return {msg: 'error'};
		}

	  return {	
	  	msg: 'success',
	  	payload: {
	  		partyId,
		  	jwt: 'token',	
		  },
		};
	}	

	/*
	 * TODO: Associate name to party and identity provider services
	 * body = {
	 *   signature: String, //Signed By Service
	 *   payload:   {
	 *     partyId:            String,
	 *     identityProviderId: String,
	 *   },
	 * },
	*/
	async registerPartyIdWithPublicIdForService(body) {
		const {
			payload,
		} = body;
		const {
			identityProviderId,
			partyId,
		} = payload; 
		const partyRegistered = await this.partyIdHasBeenRegistered(partyId);
		if (partyRegistered) {
			console.log('Party Registered');
	    return {msg: 'error'};
		}
		// TODO: Verify signature from server

    try {
	    await this.registerPartyIdForService(partyId, identityProviderId);
	    return {msg: 'success'};
    } catch (e) {
    	console.log('Registration Failed');
	    return {msg: 'error'};
    }
	}	

	destroy() {
	  super.disconnectFromNetwork();	
	}
	// ///////////////////////////////////////////////////////////////////////////
	// Private Methods

	async verifyIdentityProviderSignature(signature, payload) {
		// TODO: Implement
		return 'From Identity Provider' === signature;
	}
	async partyIdHasBeenRegistered(partyId) {
	  const doc = await this.DB.Users.findOneWithField('partyId', partyId);
	  return !!doc;
	}

	async registerPartyIdForService(partyId, identityProviderId) {
		const doc = {
			id:                 uuid(),
			partyId:            partyId,
			identityProviderId: identityProviderId,
		};
	  return this.DB.Users.add(doc);
	}
}

export default TapestryServer;
