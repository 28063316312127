import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Timeline from '../Timeline/Timeline';

import Api from '../../../MockApi/Api.js';

import './Timelines.css';


class Timelines extends React.Component {

  static propTypes = {
    storyViewConfigUpdated:  PropTypes.func.isRequired,
  	updateTimelinesHeight:   PropTypes.func.isRequired,
    selectedStory:           PropTypes.object.isRequired,
    eventDraft:              PropTypes.object,
    storyViewConfig:         PropTypes.object, 
  }

  static defaultProps = {
    storyViewConfig: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    	eventTimelineCollapsed:        false,
    	perspectiveTimelineCollapsed:  true,
    	observerTimelineCollapsed:     true,
    	height:                        0,
    };
  }

  toggleTimelineVar = varName => {
  	const updatedState = {};
  	updatedState[varName] = !this.state[varName];
    const timelinesPxHeight = this.getHeightFromState(varName); 
    if (this.state.height !== timelinesPxHeight) {
      this.props.updateTimelinesHeight(timelinesPxHeight);
      updatedState.height = timelinesPxHeight;
    }
  	this.setState(updatedState);	
  }

  updateTimeFrameDateOfType = async (timeFrameType, dateVar, date) => {
    const configId = this.props.storyViewConfig.id;
    await Api.updateTimeFrameFieldOfType(configId, timeFrameType, dateVar, date);
    this.props.storyViewConfigUpdated('timeframe_updated');
  }

  getHeightFromState = (toggledVar) => {
  	const booleans = [
  		'eventTimelineCollapsed',
  		'perspectiveTimelineCollapsed',
  		'observerTimelineCollapsed',
  	];
  	const collapsedTimelines = booleans.filter(varName => {
      if (toggledVar === varName) {
        return !this.state[varName];
      }
  		return this.state[varName];
  	}).length;
  	const openTimelines = booleans.filter(varName => {
      if (toggledVar === varName) {
        return this.state[varName];
      }
  		return !this.state[varName];
  	}).length;
  	return collapsedTimelines * 50 +  openTimelines * 100;

  }

  render() {
    const defaultTimeFrame = {
      start: '01-01-1900',
      end:   '01-01-2025',
    };
    let eventTimeFrame;
    console.log(this.props.storyViewConfig);
    if (this.props.storyViewConfig.timeFrames.event) {
      eventTimeFrame = Object.assign(defaultTimeFrame,
                                     this.props.storyViewConfig.timeFrames.event);
    } else {
      eventTimeFrame = defaultTimeFrame;
    }
  	return (
    	<div className={classnames({'timelines-container': true})}
        style={{height: `${this.state.height}px`}}>
	    	<Timeline
	    		key="Event"
	    		opacity={1}
	    		name="Event Timeline"
	    		barColor="gray"
          updateTimeFrameDate={(dateVar, date) => this.updateTimeFrameDateOfType('event', dateVar, date)}
	    		collapsed={this.state.eventTimelineCollapsed}
	    		toggleTimeline={() => this.toggleTimelineVar('eventTimelineCollapsed')}
	    	  timeFrame={eventTimeFrame}
	    	  events={this.props.selectedStory ? this.props.selectedStory.events : []}
          eventDraft={this.props.eventDraft}
	    	/>
	    	<Timeline
	    		key="perspective"
	    		opacity={.1}
	    		name="Perspecitive Timeline"
	    		barColor="red"
          updateTimeFrameDate={(dateVar, date) => this.updateTimeFrameDateOfType('perspective', dateVar, date)}
	    		collapsed={this.state.perspectiveTimelineCollapsed}
	    		toggleTimeline={() => this.toggleTimelineVar('perspectiveTimelineCollapsed')}
          timeFrame={defaultTimeFrame}
	    	  events={[]}
	    	/>
	    	<Timeline
	    		key="observer"
	    		opacity={.1}
	    		name="Observer Timeline"
	    		barColor="purple"
          updateTimeFrameDate={(dateVar, date) => this.updateTimeFrameDateOfType('observer', dateVar, date)}
	    		collapsed={this.state.observerTimelineCollapsed}
	    		toggleTimeline={() => this.toggleTimelineVar('observerTimelineCollapsed')}
          timeFrame={defaultTimeFrame}
	    	  events={[]}
	    	/>
      </div>
	 	);
  }
};
export default Timelines;
