import PubNub from 'pubnub';

class NetworkManager {
  constructor(userId) {
  	this.userId = userId;
    this.subscribedChannels = {};
  }

  async connectToNetworkWithName(handler) {
    this.pubnub = new PubNub({
      publishKey: "pub-c-04468f16-b235-481e-8856-ee3a03d02bef",
      subscribeKey: "sub-c-d48b0fae-faf0-11ea-8db0-569464a6854f",
      uuid: this.userId, 
    });
    const channelName = this.userId + '_request';
    this.subscribedChannels[channelName] = {
      channelName: channelName,
      channel: new Channel(channelName),
    }
    this.pubnub.subscribe({
      channels: [channelName],
    })
    this.pubnub.addListener({
        message: function(msg) {
          handler(msg.message.content);
        },
    });
  }
	async connectToNetwork(subscribedChannels = [], activeUsersUpdated) {
		this.pubnub = new PubNub({
		  publishKey: "pub-c-7b9ee0c4-ea0f-408a-9c79-8a33ca9bf65c",
		  subscribeKey: "sub-c-febeb574-faef-11ea-a11c-fa009153ffbc",
		  uuid: this.userId, 
		});
    const defaultChannels = ['active_users'];
    this.subscribedChannels = defaultChannels.concat(subscribedChannels).reduce((map, channelName) => {
      map[channelName] = {
        channelName: channelName,
        // type: ''
        channel: new Channel(channelName),
      };
      return map;
    }, {});
    this.subscribedChannels['active_users'].channel.addHandlerOfType('users_updated',
                                                                      activeUsersUpdated);
    this.pubnub.subscribe({
      channels: Object.keys(this.subscribedChannels),
      withPresence: true,
    });
    this.pubnub.addListener({
      presence: function(event) {
        this.subscribedChannels[event.channel].newEvent(event);
      }
    });
    await Promise.all(
      Object.keys(this.subscribedChannels).map(async channelName => {
        const userIds = await this.getUserIdsInChannel(channelName);
        this.subscribedChannels[channelName].channel.addUserIds(userIds);
      })
    );
    return;
	}

  async disconnectFromNetwork() {
    this.pubnub.unsubscribe({
      channels: Object.keys(this.subscribedChannels), 
    }); 
  }

  async getUserIdsInChannel(channel) {
    return new Promise((resolve, reject) => {
      this.pubnub.hereNow(
        {
            channels: [channel], 
            includeUUIDs: true,
            // includeState: true 
        },
        function (status, response) {
          resolve(response.channels[channel].occupants.map(occ => occ.uuid));
            // handle status, response
        }
      );
    });
  }
  messageIdentityProvider(message) {
    return this.sendMessageToComponent('daml', message);
  }

  sendMessageToComponent(component, message) {
    return new Promise((resolve, reject) => {
      this.pubnub.publish({
        channel: component + '_request',
        message: {
          sender: this.userId,
          content: message,
        },
      }, function(status, response) {
        console.log(response);
        resolve(response);
      });
    });
  }

}

class Channel {
  constructor(name) {
    this.name = name;
    this.userIds = [];
    this.eventHandlers = {
      'users_updated': [],
    };
  }

  addHandlerOfType(type, handler) {
    this.eventHandlers[type].push(handler);
  }

  addUserIds(userIds) {
    this.userIds = userIds.concat(this.userIds);
    this.eventHandlers['users_updated'].forEach(handler => {
      handler(this.userIds.length); 
    });
  }

  newEvent(event) {
    if (event.action === 'joined') {
      this.userIds.push(event.uuid);
      this.eventHandlers['users_updated'].forEach(handler => {
        handler(this.userIds.length); 
      });
    }
  }
}

export default NetworkManager;
/*
button.addEventListener('click', () => {
  pubnub.publish({
    channel : "pubnub_onboarding_channel",
    message : {"sender": uuid, "content": "Hello From JavaScript SDK"}
  }, function(status, response) {
    //Handle error here
  });
});

pubnub.subscribe({
  channels: ['pubnub_onboarding_channel'],
  withPresence: true
});

pubnub.addListener({
  message: function(event) {
    let pElement = document.createElement('p');
    pElement.appendChild(document.createTextNode(event.message.content));
    document.body.appendChild(pElement);
  },
  presence: function(event) {
    let pElement = document.createElement('p');
    pElement.appendChild(document.createTextNode(event.uuid + " has joined. That's you!"));
    document.body.appendChild(pElement);
  }
});

pubnub.history(
  {
    channel: 'pubnub_onboarding_channel',
    count: 10,
    stringifiedTimeToken: true,
  },
  function (status, response) {
    let pElement = document.createElement('h3');
    pElement.appendChild(document.createTextNode('historical messages'));
    document.body.appendChild(pElement);

    pElement = document.createElement('ul');
    let msgs = response.messages;
    for (let i in msgs) {
      msg = msgs[i];
      let pElement = document.createElement('li');
      pElement.appendChild(document.createTextNode('sender: ' + msg.entry.sender + ', content: ' + msg.entry.content));
      document.body.appendChild(pElement);
    }
  }
);
*/