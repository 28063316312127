import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CommonClassMethods from './../../Shared/Utilities/CommonClassMethods';
import Spacer from './../../Shared/Utilities/Spacer';
import MultiSwitch from './../../Shared/Buttons/MultiSwitch';


class SelectAndHide extends React.Component {

  static propTypes = {
		selectedValue: PropTypes.string.isRequired,
		selectValue:   PropTypes.func.isRequired,
		options:       PropTypes.array.isRequired,
    switchAllowed: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
    	selecting: false,
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  async componentDidUpdate(prevProps) {

  }
  selectValue = (value) => {
    this.toggleVar('selecting', false);
    this.props.selectValue(value);
  }
  getDisplayStringForValue = (value) => {
    return this.props.options.find(option => {
      return option.value === value || (option.aliases && option.aliases.includes(value)); 
    }).name;
  }

  render() {
    const {
			selectedValue,
			options,
      switchAllowed,
    } = this.props;
    if (this.state.selecting) { 
	  	return (
				<div
	        className={classnames({
	          'fill-parent-height': true,
	          'align-children-top': true,
	        })}
	        style={{float: 'left'}}>
	        <MultiSwitch
            wrapperClasses={{'inline-display': true}}
	          options={options}
	          selectValue={this.selectValue}
	          selectedValue={selectedValue}
	        />
          <Spacer type="horizontal" width="10px"/>
          <button onClick={()=>this.toggleVar('selecting', false)}>Close</button>
	    	</div>
		 	);
    }

    return (
			<div
        className={classnames({
          'fill-parent-height': true,
          'align-children-top': true,
        })}>
        {this.getDisplayStringForValue(selectedValue)}
        <Spacer type="horizontal" width="10px"/>
        { switchAllowed &&
          <button onClick={()=>this.toggleVar('selecting', true)}>Switch</button>
        }
      </div>
    ); 
  }
};
export default SelectAndHide;
