import React from 'react';
import classnames from 'classnames';

import SmartCardService from './../../../../Services/SmartCardService';
import CommonClassMethods from './../../../../Shared/Utilities/CommonClassMethods';

import AddWithTooltip from './../../../../Shared/Buttons/AddWithTooltip';
import DropdownCaretWithTooltip from '../../../../Shared/Buttons/DropdownCaretWithTooltip';
import Icon from './../../../../Shared/Icons/Icon';

import SmartCardPreview from './SmartCardPreview';
import KeySetPreview from './KeySetPreview';


class SmartCardWallet extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
  	super(props);
  	this.state = {
  		existingCards: [],
      selectedKeys:  {
        encrypt: {cardId: '', keyId: ''},
        sign:    {cardId: '', keyId: ''},
      },
  	};
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  componentDidMount() {
  	this.lookForCardsInLocalStorage();
    this.setListenerForKeySelection();
  }

  setListenerForKeySelection() {
    SmartCardService.setHandlerForKeyChanges('scw_selectedKeys', () => {
      const selectedKeys = SmartCardService.getSelectedKeys();
      this.setState({selectedKeys});
    });  

    const selectedKeys = SmartCardService.getSelectedKeys();
    this.setState({selectedKeys});
  }

  componentWillUnmount(){
    SmartCardService.removeHandlerForKeyChanges('scw_selectedKeys');
  }


  async lookForCardsInLocalStorage() {
  	const existingCards = await SmartCardService.getExistingCards();
  	this.setState({existingCards: existingCards});
  }

  createEncryptingKeyPair = async () => {
    await SmartCardService.createEncryptingKeyPair(this.state.cardId);
    this.selectCard(this.state.cardId, this.state.cardName);
  }

  createNewBrowserCard = async () => {
  	await SmartCardService.createNewBrowserCard();
  	this.lookForCardsInLocalStorage();
  }

  createSigningKeyPair = async () => {
    await SmartCardService.createSigningKeyPair(this.state.cardId);
    this.selectCard(this.state.cardId, this.state.cardName);
  }

  deleteKeyWithId = async keyId => {
    Object.keys(this.state.selectedKeys).forEach(keyType => {
       if (this.state.selectedKeys[keyType].keyId === keyId) {
        SmartCardService.unselectKeyForType(keyType);
       }  
    });
    await SmartCardService.deleteCardKeyWithId(this.state.cardId, keyId);
    this.selectCard(this.state.cardId, this.state.cardName);
  }

  deleteCard = async cardId => {
  	await SmartCardService.deleteCardWithId(cardId);
  	this.lookForCardsInLocalStorage();
  }

  selectCard = async (cardId, cardName) => {
    const cardKeys = await SmartCardService.getKeysForSmartCard(cardId);
    this.setState({cardId, cardName, cardKeys});
  }

  selectKeyForType = (keyType, keyId) => {
    SmartCardService.selectKeyForType(this.state.cardId, keyType, keyId);
  }

  unselectCard = async () => {
    this.setState({cardId: '', cardName: '', cardKeys: []});
  }
  
  unselectKeyForType = keyType => {
    SmartCardService.unselectKeyForType(keyType);
  }

  render() {
    const wrapperClasses = {
      'top-margin':         true,
      'base-padding':       true,
      'rounded-box-border': true,
      'purple-border':      true,
    };
    const wrapperHeader = (
      <div>
        <Icon iconType="wallet" color="lightblue" />Smart Card Wallet
        <div className={classnames({
            'inline-display': true,
            'hidden':         this.state.cardId,
          })}>
          &nbsp;&nbsp;
          <AddWithTooltip
            onClick={this.createNewBrowserCard}
            tooltipText="Create New Card"
          />
        </div>
      </div>
    );
    if (!this.state.cardId) {
      const browserCardDivs = this.state.existingCards.map(smartCard => {
        return (
          <SmartCardPreview 
            id={smartCard.id}
            name={smartCard.name}
            deleteHandler={this.deleteCard}
            selectHandler={() => this.selectCard(smartCard.id, smartCard.name)}/>
        );
      }); 
      return (
        <div className={classnames(wrapperClasses)}>
          {wrapperHeader}
          {browserCardDivs}
        </div>
      )
    }
    const cardKeyDivs = this.state.cardKeys.map(cardKey => {
      return (
        <KeySetPreview
          id={cardKey.id}
          name={cardKey.name}
          deleteHandler={this.deleteKeyWithId}
          selectHandler={this.selectKeyForType}
          unselectHandler={this.unselectKeyForType}
          type={cardKey.type}
          selected={this.state.selectedKeys[cardKey.type].keyId === cardKey.id}
        />
      );
    }); 
		return (
			<div className={classnames(wrapperClasses)}>
        {wrapperHeader}
        <div className={classnames({
            'top-margin':         true,
            'base-padding':       true,
            'rounded-box-border': true,
            'turquoise-border':   true,
          })}>
          <div>
            <Icon iconType="card" color="green" />
            Card {this.state.cardName &&
              <span>Name: {this.state.cardName}</span>
            }
            { !this.state.cardName &&
              <span>Id: {SmartCardService.getDisplayId(this.state.cardId)}</span>
            }
            &nbsp;&nbsp;
            <DropdownCaretWithTooltip
              expanded={this.state.showCardOptions}
              toggleExpand={() => this.toggleVar('showCardOptions')}
              expandTooltip="Show Card Options"
              collapseTooltip="Hide Card Options"
            />
          </div>
          { this.state.showCardOptions &&
            <>
              <button
                onClick={this.unselectCard}>
                Unselect Card 
              </button>
              <button
                onClick={this.createEncryptingKeyPair}>
                Create Encrypting Key Pair 
              </button>
              <button
                onClick={this.createSigningKeyPair}>
                Create Signing Key Pair 
              </button>
            </>
          }
          {cardKeyDivs}
        </div>
	    </div>
		);
  }
}

export default SmartCardWallet;
