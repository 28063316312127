import React from 'react';
import PropTypes from 'prop-types';


import './ExpandableTextarea.css';


class ExpandableTextarea extends React.Component {

  static propTypes = {
  	updateValue: PropTypes.func.isRequired,
  	charsPerRow: PropTypes.number.isRequired,
  	value:       PropTypes.string,
  }

  static defaultProps = {
  	value: '',
  }
  
  componentDidMount(){
    this.nameInput.focus();
  }

  render() {
  	const {
  		value,
  		charsPerRow,
  	} = this.props; 
    let rows = 1;
    if (value) {
      rows = Math.floor(value.length / charsPerRow) + 1;
    }
  	return (
      <textarea
        ref={(input) => { this.nameInput = input; }} 
      	style={{width: '100%', boxSizing: 'border-box'}}
        rows={rows}
        onChange={event => this.props.updateValue(event.target.value)}
        value={value || ''}
      />
	 	);
  }
};
export default ExpandableTextarea;
