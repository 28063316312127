import React from 'react';
import PropTypes from 'prop-types';

import MapManager from './MapManager';

// import './SingleMap.css';

class SingleStoryMap extends React.Component {
  static propTypes = {
    mapSilhouettes:   PropTypes.array,
    events:           PropTypes.array,
    selectMapFeature: PropTypes.func
  }

  static defaultProps = {
  	mapSilhouettes: [],
    events:  [],
  }

  constructor(props) {
    super(props);
    this.state = {
      loadedMaps: {},
      width:      '100%',
    };
    this.MapManager = new MapManager();
  }


  async componentDidMount() {
  	this.chart = this.MapManager.createMap();
  	if (this.props.mapSilhouettes.length > 0) {
	    const silhouetteSeries = this.props.mapSilhouettes;
	    await this.MapManager.addSilhouettesToMap(silhouetteSeries,
                                                this.props.selectMapFeature);
      if (this.props.events) {
        const events = this.props.events;
        await this.MapManager.addEventsToMap(events);
      }
  	}
  }

  async componentDidUpdate(prevProps) {
  	if (prevProps.mapSilhouettes !== this.props.mapSilhouettes) {
  		const newSilhouettes = this.props.mapSilhouettes;
  		const oldSilhouettes = prevProps.mapSilhouettes;
	    await this.MapManager.updateVisibleSilhouettes(oldSilhouettes,
	    																							 newSilhouettes,
                                                		 this.props.selectMapFeature);
  	}
    if (prevProps.events !== this.props.events) {
      const oldEvents = prevProps.events;
      const newEvents = this.props.events;
    	await this.MapManager.updateVisibleEvents(oldEvents, newEvents);
    }
  }

  componentWillUnmount() {
    this.MapManager.destroy();
    console.log('Component Unmounting');
  }

  render() {
    const chartStyle = {
      maxWidth:  '100%',
      width:     this.state.width,
      maxHeight: '100%',
      height:    "100%"
    };
    return (
      <div id="chartdiv" style={chartStyle}></div>
    );
  }
};

export default SingleStoryMap;
