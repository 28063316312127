import RecordWithHistory from './../RecordTypes/RecordWithHistory';

const StoryDef = {
  name:       { type: 'string', hashable: true, },
  events:     { type: ['id'], collection: 'Event', hashable: true, },
  narratives: { type: ['id'], collection: 'Narrative', hashable: true, },
  mapPaths:   { type: ['string'], hashable: true, },
  tags:       { type: ['string'], hashable: true, },
};
class StoryService extends RecordWithHistory {
  constructor() {
  	super('tapestry', 'story', StoryDef);
  }

  async createStory(storyName, color, options = {}) {
    const storyObj = {
      name:       storyName,
      tags:       options.tags || [],
      narratives: [],
      events:     [],
      color:      color,
      mapPaths:   options.mapPaths || ['world_worldHigh'],
    };
    
    return super.createNewRecord(storyObj);
  }
  
  async getLatestStoriesWithIds(storyIds) {
    return super.getLatestItemStatesWithIds(storyIds);
  }

  // ///////////////////////////////////////////////////////////////////////////
  // Unverified
  getAllStories() {
  	return super.getAllItemsFromStore();
  }

  async deleteStoryWithId(storyId) {
    return super.deleteItemWithIdFromStore(storyId);
  }
  async addEventIdToStory(storyId, eventId) {
    return super.addValueToItemSetField(storyId, 'events', eventId);
  }

  async addMapPathToStory(storyId, mapPath) {
    return super.addValueToItemSetField(storyId, 'mapPaths', mapPath);
  }

  async removeMapPathFromStory(storyId, mapPath) {
    return super.removeIdFromItemArrayField(storyId, 'mapPaths', mapPath);
  }

  async removeEventIdFromStory(storyId, eventId) {
    return super.removeIdFromItemArrayField(storyId, 'events', eventId);
  }
}

export default (new StoryService());
