const BrowserStorage = {
	local: {
		getItemOrDefault:     getLocalStorageItemOrDefault,	
		setItem:              setLocalStorageItem,
    getStoragePercentage: getLocalStoragePercentage,
	},
	session: {
		getItemOrDefault: getSessionStorageItemOrDefault,	
		setItem:          setSessionStorageItem,
	},
};

function getLocalStoragePercentage() {
  var _lsTotal = 0,
      _xLen, _x;
  for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
          continue;
      }
      _xLen = ((localStorage[_x].length + _x.length) * 2);
      _lsTotal += _xLen;
  };
  const CHROME_LOCAL_STORAGE_LIMIT = 10000000000;
  return Math.ceil((_lsTotal / CHROME_LOCAL_STORAGE_LIMIT) * 100);
}

function setLocalStorageItem(key, value, stringified = true) {
  if (stringified) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
}

function getLocalStorageItemOrDefault(key, defaultValue, stringified = true) {
  let string = localStorage.getItem(key);
  if (!string || string === 'undefined' || string === undefined) {
    return defaultValue; 
  }
  if (stringified) {
    return JSON.parse(string);
  }
  return string;
}

function setSessionStorageItem(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

function getSessionStorageItemOrDefault(key, defaultValue) {
  let string = sessionStorage.getItem(key);
  if (!string || string === 'undefined') {
    return defaultValue; 
  }
  return JSON.parse(string);

}

export default BrowserStorage;
