import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './MultiSwitch.css';

function MultiSwitch(props) {
	const {
		selectValue,
		selectedValue,
		options,
		wrapperClasses,
	} = props;
	const optionDivs = options.map(option => {
		return (
	  	<button
	  	  key={option.value}
		  	className={classnames({
		  		'ms-selectable-div': true,
		  		selected: option.value === selectedValue,
		  	})}
	  		onClick={() => selectValue(option.value)}>
	  		{option.name}
	  	</button>
		);
	});
  return (
  	<div
		  	className={classnames(wrapperClasses)}>
  		{optionDivs}
  	</div>
  );
}

MultiSwitch.propTypes = {
	selectedValue: PropTypes.string.isRequired,
	selectValue:   PropTypes.func.isRequired,
	options:       PropTypes.array.isRequired,
};

export default MultiSwitch;
