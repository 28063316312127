import RecordWithHistory from './../RecordTypes/RecordWithHistory';
import objectHash from 'object-hash';

const EventDef = {
  description:   { type: 'string', hashable: true },
  timeFrame:     { type: 'TimeFrame', hashable: true },
  locations:     { type: ['Location'], hashable: true },
  source:        { type: 'id', collection: 'Source', hashable: true },
  humanEntities: { type: ['id'], collection: 'HumanEntity', hashable: true },
  institutions:  { type: ['id'], collection: 'Institution', hashable: true },
};
class EventService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'event', EventDef, true);
  }

  async createEvent(description, options = {}) {
		const eventObj = {
			description:   description,
			timeFrame:     options.timeframe || null,
			locations:     options.location || [],
			source:        options.sourceId || null,
			humanEntities: [],
			institutions:  [],
		};
		
		return super.addItemToStore(eventObj);
  }

  createEventFromDraft(eventDraft) {
    // TODO: Some Sort Of verification and maybe adding a hash
    delete eventDraft.hash;
    const newHash = objectHash(eventDraft); 
    eventDraft.hash = newHash;
    return super.addItemToStore(eventDraft);
  }

  rehashObject(object) {
    delete object.hash;
    const newHash = objectHash(object); 
    object.hash = newHash;
    return object;
  }

  updateEventWithIdFromDraft(eventId, eventDraft) {
    const updateFunction = item => {
      return this.rehashObject(eventDraft);
    };
    return super.updateItemWithKey(eventId, updateFunction);
  }

  updateEventWithId(eventId, updatedProperties, removedProperties) {
    const updateFunction = item => {
      Object.keys(updatedProperties).forEach(objectKey => {
        item[objectKey] = updatedProperties[objectKey]; 
      });
      removedProperties.forEach(removedProperty => {
        delete item[removedProperty]; 
      });
      return item;
    };
    return super.updateItemWithKey(eventId, updateFunction);
  }

  getAllEvents() {
  	return super.getAllItemsFromStore();
  }

  async getEventsWithIds(eventIds) {
  	// TODO: Try to find a better way to do this
    return super.getItemsWithIds(eventIds);
  }
}

export default (new EventService());
