import NetworkComponent from './../NetworkComponent';

class TapestryClient extends NetworkComponent {

	constructor() {
		super('tapestry_client');
		this.server = 'tapestry_server';
		this.ip_ext = 'identity_provider_client';
		const handlers = {};
		super.connectToNetworkWithHandlers(handlers);
	}

	async registerPartyIdWithPublicIdForService(body) {
		const recipient = this.server;
		const requestType = 'registerPartyIdWithPublicIdForService';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Registration Failed');
		}
		return;
	}

	async getUserIdFromIdentityProvider(body) {
		const recipient = this.ip_ext;
		const requestType = 'getUserIdFromIdentityProvider';
		const response = await this.sendRequest(recipient, requestType, body);
		
		if (response.msg !== 'success') {
			throw new Error('Request Failed');
		}
		return response;
	}

	async requestTokenForPartyId(body) {
		const recipient = this.server;
		const requestType = 'requestTokenForPartyId';
		const response = await this.sendRequest(recipient, requestType, body);
		
		if (response.msg !== 'success') {
			throw new Error('Request Failed');
		}
		return response;
	}


	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default TapestryClient;
