import objectHash from 'object-hash';

import RecordWithHistory from './../RecordTypes/RecordWithHistory';

import {DraftType, TimeFrameType} from '../../../Shared/TapestryConstants/Enums';

const DraftDef = {
  typeId:            { type: 'id', hashable: true },
  type:              { type: 'string', enum: DraftType, hashable: true },
  initialState:      { type: 'object', hashable: true },
  editedState:       { type: 'object', hashable: true },
  intermediateState: { type: 'object', hashable: true },
};

class DraftService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'draft', DraftDef, true);
  }

  async createEventDraft(event, options = {}) {
  	// Nedessary For this mock api to avoid sharing a reference
  	const eventCopy = Object.assign({}, event);
		const draftObj = {
			type:              'event',
			typeId:            event.id,
			initialState:      eventCopy,
			editedState:       eventCopy,
		  intermediateState: {},	
		};
		
		return super.addItemToStore(draftObj);
  }
  // Abstract Method
  updateOrAddObjectInArrayWithField(array, newObject, fieldName, altKeyVal) {
    const objectIndex = array.findIndex(arrayEntry => {
      if (altKeyVal) {
        return arrayEntry[fieldName] === altKeyVal; 
      } else {
        return arrayEntry[fieldName] === newObject[fieldName]; 
      }
    });
    if (objectIndex < 0) {
      array.push(newObject);
    } else {
      array[objectIndex] = newObject;
    }
    return array;
  }

  removeEventDraftLocationGroupFromInter(draftId, save = false) {
    const updateFunction = item => {
      if (save) {
        if (!item.editedState.locations) {
          // TODO: Remove This Check and Ensure The Default Is Set To [];
          item.editedState.locations = [];
        }
        const newFeatureGroup = item.intermediateState.featureGroup;
        const oldLocations = item.editedState.locations;

        const uneditedHash = item.intermediateState.featureGroupHash;
        const locations = this.updateOrAddObjectInArrayWithField(oldLocations,
                                                                 newFeatureGroup,
                                                                 'hash',
                                                                 uneditedHash);
        item.editedState.locations = locations;
      }
      delete item.intermediateState.featureGroup;
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  async deleteDraftWithId(draftId) {
    return super.deleteItemWithIdFromStore(draftId);
  }

  // Also Need To Create Has For 
  updateItemAndReHash(item, updateFunction) {
    let updatedItem = Object.assign({}, item);
    delete updatedItem.hash;
    updatedItem = updateFunction(item);
    const newHash = objectHash(updatedItem); 
    updatedItem.hash = newHash;
    return updatedItem;
  }

  addMapFeatureIdToEventDraftLocation(draftId, mapFeatureId) {
    const updateFunction = item => {
      const featureGroup = item.intermediateState.featureGroup;
      const updateHandler = object => {
        object.features.push(mapFeatureId);
        return object;
      };
      item.intermediateState.featureGroup = this.updateItemAndReHash(featureGroup,
                                                                     updateHandler);
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  removeMapFeatureIdFromEventDraftLocation(draftId, mapFeatureId) {
    const updateFunction = item => {
      const featureGroup = item.intermediateState.featureGroup;
      const updateHandler = object => {
        const itemIndex = object.features.indexOf(mapFeatureId);
        object.features.splice(itemIndex, 1);
        return object;
      };
      item.intermediateState.featureGroup = this.updateItemAndReHash(featureGroup,
                                                                     updateHandler);
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  updateLocationFeatureGroupMap(draftId, mapPath) {
    const updateFunction = item => {
      const featureGroup = item.intermediateState.featureGroup;
      const updateHandler = object => {
        object.mapPath = mapPath;
        return object;
      };
      item.intermediateState.featureGroup = this.updateItemAndReHash(featureGroup,
                                                                     updateHandler);
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  updateLocationFeatureGroupColor(draftId, color) {
    const updateFunction = item => {
      const featureGroup = item.intermediateState.featureGroup;
      const updateHandler = object => {
        object.color = color;
        return object;
      };
      item.intermediateState.featureGroup = this.updateItemAndReHash(featureGroup,
                                                                     updateHandler);
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  updateDraftStringField(draftId, fieldName, fieldValue) {
    const updateFunction = item => {
      // TODO: Default Service To Get These Default Objects
      if (item.type === 'event') {
        const allowedStringFields = ['description', 'sourceLink'];
        if (!allowedStringFields.includes(fieldName)) {
          return item;
        }
      }
      item.editedState[fieldName] = fieldValue;
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  updateDraftBooleanField(draftId, fieldName, fieldValue) {
    const updateFunction = item => {
      // TODO: Default Service To Get These Default Objects
      if (item.type === 'event') {
        const allowedBooleanFields = ['addTimeFrame'];
        if (!allowedBooleanFields.includes(fieldName)) {
          return item;
        }
      }
      item.editedState[fieldName] = fieldValue;
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  updateDraftTimeFrameField(draftId, fieldName, fieldValue) {
    const updateFunction = item => {
      let updatedTimeFrame = {}; 
      if (item.type === 'event') {
        if (item.editedState.timeFrame) {
          updatedTimeFrame = item.editedState.timeFrame;
        } 
      }
      if (fieldName === 'type') {
        if (fieldValue === TimeFrameType.ONGOING_EVENT) {
          delete updatedTimeFrame.endDate;
        } else if (fieldValue === TimeFrameType.IS_INSTANT) {
          updatedTimeFrame.endDate = updatedTimeFrame.startDate;
        }
        updatedTimeFrame.type = fieldValue;
      } else {
        // TODO: Probably Disallow End Date For Some Types
        const allowedStringFields = ['startDate', 'endDate', 'color'];
        if (allowedStringFields.includes(fieldName)) {
          updatedTimeFrame[fieldName] = fieldValue;
        }
      }
      if (item.type === 'event') {
        item.editedState.timeFrame = updatedTimeFrame;
      }
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  removeFeatureGroupFromEventDraftLocation(draftId, featureGroupHash) {
    const updateFunction = item => {
      if (item.editedState.locations) {
        const index = item.editedState.locations.findIndex(location => {
          return location.hash === featureGroupHash;
        });
        item.editedState.locations = [...item.editedState.locations].splice(index, 1);
      }
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  setEventDraftLocationGroupWithHash(draftId, options) {
    const {
      existingFeatureGroupHash,
      defaultMapPath,
    } = options;
    const updateFunction = item => {
      // TODO: Default Service To Get These Default Objects
    	let featureGroup = {
        features: [],
        color:    'red',
      };
    	if (existingFeatureGroupHash) {
        if (item.editedState.locations) {
      		const existingFeatureGroup = item.editedState.locations.find(location => {
      			return location.hash === existingFeatureGroupHash;
      		});
      		if (existingFeatureGroup) {
      			featureGroup = existingFeatureGroup;	
            item.intermediateState.featureGroupHash = existingFeatureGroupHash;
      		}
        }
    	} else if (defaultMapPath) {
        featureGroup.mapPath = defaultMapPath;
      }
    	item.intermediateState.featureGroup = featureGroup;
      return item;
    };
    return super.updateItemWithKey(draftId, updateFunction);
  }

  getDraftWithId(draftId) {
  	return super.getItemWithId(draftId);
  }
}

export default (new DraftService());
