import React from 'react';
import classnames from 'classnames';

function wrapperWithDimensions(WrappedComponent) {
  class WrapperWithDimensions extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        height: null,
        width:  null,
      };
    }

    async componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    async componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;
      this.setState({ width: width, height: height });
    }

    render() {
      return (
        <div
          className={classnames({
            'fill-parent-height': true,
            'fill-parent-width':  true,
          })}
          ref={ (divElement) => { this.divElement = divElement } }>
          <WrappedComponent
            parentHeight={this.state.height}
            parentWidth={this.state.width}
            {...this.props}
          />
        </div>
      );
    }
  }
  WrapperWithDimensions.displayName = `WrapperWithDimensions(${getDisplayName(WrappedComponent)})`;
  return WrapperWithDimensions;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default wrapperWithDimensions;