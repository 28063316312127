
class BasicQueue {
	constructor() {
		this.queue = {
			running:   false,
			nextBatch: [],
		};
	}
	// Add To Queue
	// If Queue Is Empty
	// Return When Complete
	// If Queue Is Running, add To Next
	addToQueue( promiseGenerator) {
		return new Promise(resolve => {
			this.queue.nextBatch.push(() => {
				return promiseGenerator().then(response => {
					return resolve(response);
				});
			});
			if (this.queue.running) {
				return;
			} else {
				this.runNextBatch();
			}
		});
	}

	async runNextBatch() {
		this.queue.running = true;
		this.queue.batch = this.queue.nextBatch;
		this.queue.nextBatch = [];
		const lastPromise = this.queue.batch.reduce((promise, promiseGenerator) => {
			return promise.then(() => {
				return promiseGenerator();
			});
		}, Promise.resolve());
		await lastPromise;
		if (this.queue.nextBatch.length > 0) {
			this.runNextBatch();
			return;
		}
		this.queue.running = false;
	}
}

export default BasicQueue;
