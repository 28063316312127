import RecordWithHistory from './../RecordTypes/RecordWithHistory';

const NoteDef = {
  text: { type: 'string', hashable: true },
  // TODO: Use api to get content and hash that content
  // contentHash:      { type: 'string', hashable: true },
  // constentHashDate: { type: 'date', hashable: true },
};
class NoteService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'note', NoteDef, true);
  }

  async findOrCreateNoteWithText(text, options = {}) {
    // ///////////////////////////
    // Find
    const queryObj = {
      text, 
    };
    const existingNote = await super.findOneWithFields(queryObj)
    if (existingNote) {
      return existingNote;
    }
    // ///////////////////////////
    // Create 
    const noteObj = {
      text,
    };
    return super.createNewRecord(noteObj);
  }

  getAllNotes() {
  	return super.getAllItemsFromStore();
  }
  /*
  async getSourceWithId(sourceId) {
    // TODO: Try to find a better way to do this
    return super.getItemWithId(sourceId);
  }

  async getSourcesWithIds(sourceIds) {
  	// TODO: Try to find a better way to do this
  	return super.getItemsWithIds(sourceIds);
  }
  */
}

export default (new NoteService());
