import {CommonColloquialDateRanges, TimeFrameType} from './Enums';

const DisplayTimeFrameType = {};
DisplayTimeFrameType[TimeFrameType.HAS_END_DATE] = 'Has End Date';
DisplayTimeFrameType[TimeFrameType.ONGOING_EVENT] = 'Ongoing Event (No End Date)';
DisplayTimeFrameType[TimeFrameType.IS_INSTANT] = 'Is Instant (Start Date Equals End Date)';
const DisplayColoquialDR = {};
DisplayColoquialDR[CommonColloquialDateRanges.TODAY_IN_TZ] = 'Today';
DisplayColoquialDR[CommonColloquialDateRanges.YESTERDAY_IN_TZ]  = 'Yesterday';
DisplayColoquialDR[CommonColloquialDateRanges.CALENDAR_WEEK_IN_TZ] = 'This Calendar Week';
DisplayColoquialDR[CommonColloquialDateRanges.LAST_7_DAYS] = 'Last 7 Days';

export {
	DisplayColoquialDR,
	DisplayTimeFrameType,
};

