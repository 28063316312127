import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartCardService from './../Services/SmartCardService';
import BrowserStorage from './../Shared/Utilities/BrowserStorage';

import Icon from './../Shared/Icons/Icon';
import IdentityProviderClient from './../Shared/Network/IdentityProvider/IdentityProviderClient';
import IdentityProviderServer from './../Shared/Network/IdentityProvider/IdentityProviderServer';
import LedgerServer from './../Shared/Network/Ledger/LedgerServer';
import TapestryServer from './../Shared/Network/Tapestry/TapestryServer';
import CommonClassMethods from './../Shared/Utilities/CommonClassMethods';

import LimitedTextArea from './../Shared/FormComponents/TextInputs/LimitedTextArea/LimitedTextArea';

const VisibleFormType = {
  LOGIN:    'login',
  REGISTER: 'register',
};

const MessageType = {
  AWAITING_REG_SIGNATURE:    'AWAITING_REG_SIGNATURE',
  AWAITING_LOGIN_SIGNATURE:  'AWAITING_LOGIN_SIGNATURE',
  COMPLETING_AUTHENTICATION: 'COMPLETING_AUTHENTICATION',
  VERIFYING_SIGNATURE:       'VERIFYING_SIGNATURE',
};

class IdentityProviderBrowserExt extends React.Component {
  static propTypes = {
  	smartKeysSet:                PropTypes.bool.isRequired,
    showDevTools:                PropTypes.bool.isRequired,
    updateIdentityProviderState: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      loggedIn:        false,
      visibleFormType: '',
      username:        '',
      messageType:     '',
      serviceNames:    [],
      rememberMe:      BrowserStorage.local.getItemOrDefault('rememberMe', false),
    };
    
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
    this.updateStateFieldWithValue = CommonClassMethods.updateStateFieldWithValue.bind(this);
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

	async componentDidMount() {
    const uiHandlers = {};
    uiHandlers.receiveAuthenticationRequestForService = this.receiveAuthenticationRequestForService;
    this.identityProviderClient = new IdentityProviderClient(uiHandlers); 
    this.identityProviderServer = new IdentityProviderServer(); 
    this.ledgerServer = new LedgerServer(); 
    this.tapestryServer = new TapestryServer(); 
    if (this.state.rememberMe) {
      const userInfo = BrowserStorage.local.getItemOrDefault('userInfo', null);
      if (userInfo) {
        this.updateLogin(userInfo, true);
      }
    }
	}

  async componentWillUnmount() {
    await this.identityProviderClient.destroy();
    await this.identityProviderServer.destroy();
    await this.ledgerServer.destroy();
    await this.tapestryServer.destroy();
  }

  updateRememberMe = async () => {
    await this.toggleVar('rememberMe');
    BrowserStorage.local.setItem('rememberMe', this.state.rememberMe);
    if (!this.state.rememberMe) {
      BrowserStorage.local.setItem('userInfo', null);
    }
  }

  register = async () => {
    // TODO: Request Nonce From Identity Provider Server
    await this.updateStringField('messageType', MessageType.AWAITING_REG_SIGNATURE);

    const requestName = 'Register With Identity Provider';
    let body;
    try {
      const payload = {
        nonce:     1,
        username:  this.state.username,
      };
      body = await SmartCardService.addPublicKeyAndRequestSignature(payload,
                                                                    requestName);
    } catch (e) {
      console.log(e);
      // Signature request denied
      await this.updateStringField('messageType', '');
      return;
    }

    await this.updateStringField('messageType', MessageType.VERIFYING_SIGNATURE);

    try {
      await this.identityProviderClient.registerPublicKeyWithUsername(body);
    } catch (e) {
      console.log(e);
      await this.updateStringField('messageType', '');
      return;
    }
    await this.updateStringField('messageType', '');
    this.updateLogin({username: this.state.username, services: []});
  }

  updateLogin = async (userInfo, localStorageLoad = false) => {
    const updatedState = await this.toggleVar('loggedIn');
    if (!updatedState.loggedIn) {
      // Clear local profile information
      this.setState({serviceNames: []});
      BrowserStorage.local.setItem('userInfo', {});
    } else {
      this.setState({
        loggedInUsername: userInfo.username,
        serviceNames:     userInfo.services,
      });
      if (this.state.rememberMe && !localStorageLoad) {
        BrowserStorage.local.setItem('userInfo', userInfo);
      }
    }
    this.props.updateIdentityProviderState(this.state.loggedIn);
  }


  login = async () => {
    // TODO: Request Nonce From Identity Provider Server
    await this.updateStringField('messageType', MessageType.AWAITING_LOGIN_SIGNATURE);

    const requestName = 'Login With Identity Provider';
    let body;
    const payload = {
      nonce:     1,
      username:  this.state.username,
    };
    try {
      body = await SmartCardService.addPublicKeyAndRequestSignature(payload,
                                                                    requestName);
    } catch (e) {
      console.log(e);
      // Signature request denied
      await this.updateStringField('messageType', '');
      return;
    }

    await this.updateStringField('messageType', MessageType.VERIFYING_SIGNATURE);
    let response;
    try {
      response = await this.identityProviderClient.loginWithUsername(body);
    } catch (e) {
      console.log(e);
      await this.updateStringField('messageType', '');
      return;
    }
    await this.updateStringField('messageType', '');
    const userInfo = {
      username: this.state.username,
      services: response.data.services,
    };
    this.updateLogin(userInfo);
  }

  logout = async () => {
    await this.toggleVar('loggedIn');
    BrowserStorage.local.setItem('userInfo', null);
    this.props.updateIdentityProviderState(this.state.loggedIn);
  }

  registerWithTapestryService = async () => {
    // TODO: Request Nonce From Identity Provider Server
    try {
      const body = {
        payload: {
          username: this.state.username,
        }
      };
      await this.identityProviderClient.registerWithTapestryService(body);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  receiveAuthenticationRequestForService = async (serviceName, nonce) => {
    await this.updateStringField('messageType', MessageType.AWAITING_LOGIN_SIGNATURE);

    const requestName = 'Authenticate with ' + serviceName;
    let body;
    const payload = {
      nonce:       1,
      username:    this.state.username,
      serviceName: serviceName 
    };
    try {
      body = await SmartCardService.addPublicKeyAndRequestSignature(payload,
                                                                    requestName);
    } catch (e) {
      console.log(e);
      // Signature request denied
      await this.updateStringField('messageType', '');
      return;
    }

    await this.updateStringField('messageType', MessageType.VERIFYING_SIGNATURE);
    let idenProvResponse;
    try {
      idenProvResponse = await this.identityProviderClient.requestUserIdForService(body);
    } catch (e) {
      console.log(e);
      await this.updateStringField('messageType', '');
      return;
    }
    await this.updateStringField('messageType', '');
    return idenProvResponse;
  }

  render() {
    const wrapperClasses = {
      'base-padding': true,
      'white-border': true,
    };
    const wrapperStyles = {
    };
    const wrapperHeader = (
      <div>
        <div
          className={classnames({'green-text': this.state.loggedIn})}
          style={{fontSize: '27px'}}>
          <Icon iconType="identity"/>
          Identity Manager 
        </div>
        <div style={{fontSize: '14px'}}>
          (Browser Extension) 
        </div>
      </div>
    );
  	const {
      showDevTools,
      smartKeysSet
  	} = this.props;
    if (!smartKeysSet) {
      return (
        <div
          className={classnames(wrapperClasses)}
          style={wrapperStyles}>

          {wrapperHeader}
          <div>Set Smart Keys To Begin</div>
        </div>
      );
    } else if (this.state.messageType) {
      let message = ''; 
      if (this.state.messageType === MessageType.AWAITING_REG_SIGNATURE) {
        message = 'Sign Request To Complete Registration';
      } else if (this.state.messageType === MessageType.AWAITING_LOGIN_SIGNATURE) {
        message = 'Sign Request To Complete Login';
      } else if (this.state.messageType === MessageType.COMPLETING_AUTHENTICATION) {
        message = 'Completing Authentication With Service';
      } else if (this.state.messageType === MessageType.VERIFYING_SIGNATURE) {
        message = 'Verifying Signature';
      }
      return (
        <div
          className={classnames(wrapperClasses)}
          style={wrapperStyles}>

          {wrapperHeader}
          <Spacer type="vertical" height="10px"/>
          <div>{message}</div>
        </div>
      );
    } else if (this.state.loggedIn) {
      return (
        <div
          className={classnames(wrapperClasses)}
          style={wrapperStyles}>

          {wrapperHeader}
          <Spacer type="vertical" height="10px"/>
          <div style={{fontSize: '14px'}}>
            Logged In As: {this.state.loggedInUsername} 
          </div>
          <Spacer type="vertical" height="10px"/>
          <button
            onClick={() => this.logout()}>
            Log out
          </button>
          {showDevTools &&
            <button
              onClick={() => this.registerWithTapestryService()}>
              Tapestry Register 
            </button>
          }
          <Spacer type="vertical" height="10px"/>
          { (!this.state.serviceNames || this.state.serviceNames.length === 0) &&
            <div style={{fontSize: '14px'}}>
              No Services
            </div>
          }
          { (this.state.serviceNames && this.state.serviceNames.length > 0) &&
            <div>
              <div style={{fontSize: '20px'}}>
                Services
              </div>
              {this.state.serviceNames.map(serviceName => {
                 return (
                   <div key={serviceName} className={classnames({'white-border': true})}>
                     {serviceName}
                   </div>
                 ); 
              })}
            </div>

          }
        </div>
      );
    } else if (!this.state.visibleFormType) {
      return (
        <div
          className={classnames(wrapperClasses)}
          style={wrapperStyles}>

          {wrapperHeader}
          <Spacer type="vertical" height="10px"/>
          <button
            onClick={() => this.updateStringField('visibleFormType', VisibleFormType.LOGIN)}>
            Log In
          </button>
          <button
            onClick={() => this.updateStringField('visibleFormType', VisibleFormType.REGISTER)}>
            Register 
          </button>
        </div>
      );
    } else if (this.state.visibleFormType === VisibleFormType.REGISTER || 
               this.state.visibleFormType === VisibleFormType.LOGIN) {
      return (
        <div
          className={classnames(wrapperClasses)}
          style={wrapperStyles}>

          {wrapperHeader}
          <Spacer type="vertical" height="10px"/>
          <label className="block-label">Enter Username</label>
          <LimitedTextArea
            limit={140}
            value={this.state.username} 
            charsPerRow={30}
            focusOnLoad={true}
            updateValue={username => this.updateStringField('username', username)}
          />
          <div> 
           <label>
              Remember Me 
              <input
                name="loginRememberMe"
                type="checkbox"
                checked={this.state.rememberMe}
                onChange={this.updateRememberMe} />
            </label>
          </div>
          <button
            onClick={() => this.updateStringField('visibleFormType', '')}>
            Back 
          </button>
          { this.state.visibleFormType === VisibleFormType.REGISTER &&
            <button
              onClick={() => this.register()}>
              Register 
            </button>
          }
          { this.state.visibleFormType === VisibleFormType.LOGIN &&
            <button
              onClick={() => this.login()}>
              Login 
            </button>
          }
        </div>
      );
    }

  }
};
function Spacer({type, height}) {
  if (type === 'vertical') {
    return (
      <div style={{height: height}}>
      </div>
    );
  }
}

export default IdentityProviderBrowserExt;
