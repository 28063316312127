import React from 'react';
import PropTypes from 'prop-types';

import IconTypeWithTooltip from './IconButtonWithTooltip';

function DropdownCaretWithTooltip(props) {
	const {
		expanded,
		toggleExpand,
		collapseTooltip,
		expandTooltip,
	} = props;
	if (!expanded) {
    return (
    	<IconTypeWithTooltip
    		onClick={toggleExpand}
    		iconType="caret-down"
    		tooltipText={expandTooltip}
    	/>
	  );
	}
	return (
  	<IconTypeWithTooltip
  		onClick={toggleExpand}
  		iconType="caret-up"
  		tooltipText={collapseTooltip}
  	/>
 	);
}

DropdownCaretWithTooltip.propTypes = {
	expanded:        PropTypes.bool.isRequired,
	toggleExpand:    PropTypes.func.isRequired,
	expandTooltip:   PropTypes.string,
	collapseTooltip: PropTypes.string,
};

export default DropdownCaretWithTooltip;
