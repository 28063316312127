import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { format } from 'date-fns'


import Spacer from './../../Shared/Utilities/Spacer';
import CommonClassMethods from './../../Shared/Utilities/CommonClassMethods';
import LimitedTextArea from './../../Shared/FormComponents/TextInputs/LimitedTextArea/LimitedTextArea';
import DateRangePickerDropdown from './../../Shared/FormComponents/Date/DateRangePickerDropdown';

// import './AccountHeader.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

class SearchHeader extends React.Component {

  static propTypes = {
    searchStringUpdated:  PropTypes.func.isRequired,
    dateTimeRangeUpdated: PropTypes.func.isRequired,
    startDateTime:        PropTypes.instanceOf(Date), 
    endDateTime:          PropTypes.instanceOf(Date), 
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      noDateTimeRange:      true,
      editingDateTimeRange: false,
      startDateTime:        props.startDateTime || now,
      endDateTime:          props.endDateTime || now, 
      dateTimeRangeType:    undefined,
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
  }

  async componentDidUpdate(prevProps) {
  }

  updateSearchString = value => {
  	this.updateStringField('searchString', value);
  }

  updateDateTimeRange = (tmpStartDateTime, tmpEndDateTime, dateTimeRangeType) => {
    this.setState({tmpStartDateTime, tmpEndDateTime, dateTimeRangeType});
  }

  saveDateTimeRange = () => {
    if (!this.state.dateTimeRangeType) {
      return;
    }
    this.props.dateTimeRangeUpdated(this.state.tmpStartDateTime,
                                    this.state.tmpEndDateTime);
    this.setState({
      startDateTime: this.state.tmpStartDateTime,
      endDateTime:   this.state.tmpEndDateTime,
    });
    this.toggleVar('editingDateTimeRange');
    if (this.state.noDateTimeRange) {
      this.toggleVar('noDateTimeRange');
    }
  }

  removeDateTimeRangeFilter = () => {
    this.props.dateTimeRangeUpdated(undefined,
                                    undefined);
    this.toggleVar('editingDateTimeRange');
    if (!this.state.noDateTimeRange) {
      this.toggleVar('noDateTimeRange');
    }
  }

  render() {
  	return (
  		<div className="account-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
          <LimitedTextArea 
				  	updateValue={this.updateSearchString}
				  	value={this.state.searchString}
				  	rows={1}
				    focusOnLoad={true}
				    showLimit={false}
				    wrapperClasses={{'inline-display': true}}
          />
          <Spacer type="horizontal" width="10px"/>
          <button
            onClick={() => this.props.searchStringUpdated(this.state.searchString)}>
            Search 
          </button>

      	</div>
        {(this.state.noDateTimeRange && !this.state.editingDateTimeRange) &&
          <div
            className={classnames({
              'fill-parent-height': true,
              'align-children-top': true,
            })}
            style={{float: 'right'}}>
            <button onClick={() => this.toggleVar('editingDateTimeRange')}>
              Add Date Time Range Filter 
            </button>
          </div>
        }
        {this.state.editingDateTimeRange &&
          <div
            className={classnames({
              'fill-parent-height': true,
              'align-children-top': true,
            })}
            style={{float: 'right'}}>
            <DateRangePickerDropdown
              dateTimeRangeType={this.state.dateTimeRangeType}
              wrapperClasses={{'fill-parent-height': true, 'inline-display': true}}
              updateDateTimeRange={this.updateDateTimeRange}
            />
            <button onClick={this.saveDateTimeRange}>
              Save 
            </button>
            <button onClick={this.removeDateTimeRangeFilter}>
              Remove 
            </button>
            <button onClick={() => this.toggleVar('editingDateTimeRange')}>
              Cancel 
            </button>
          </div>
        }
        {(!this.state.noDateTimeRange && !this.state.editingDateTimeRange) &&
          <div
            className={classnames({
              'fill-parent-height': true,
              'align-children-top': true,
            })}
            style={{float: 'right'}}>
            <div className={classnames({'inline-display': true})}>
              {format(this.state.startDateTime, 'Pp')}
            </div>
            <Spacer type="horizontal" width="10px"/>
            -
            <Spacer type="horizontal" width="10px"/>
            <div className={classnames({'inline-display': true})}>
              {format(this.state.endDateTime, 'Pp')}
            </div>
            <Spacer type="horizontal" width="10px"/>
            <button onClick={() => this.toggleVar('editingDateTimeRange')}>
              Edit 
            </button>
          </div>
        }
  		</div>
	 	);
  }
};
export default SearchHeader;
