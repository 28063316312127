import React from 'react';
import classnames from 'classnames';

import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';
import UrlServiceClient from './../../../Shared/Network/UrlService/UrlServiceClient';
import ExpandableTextArea from './../../../Shared/FormComponents/TextInputs/ExpandableTextArea/ExpandableTextArea';
import OpenGraphPreview from './../../../Shared/OpenGraphPreview/OpenGraphPreview';

import Spacer from './../../../Shared/Utilities/Spacer';

import Api from './../../../MockApi/Api';

import './LinkBasedStoryForm.css';
import './../../../Shared/FormComponents/Labels/Labels.css';
import './../../../Shared/CSS/ParentChild.css';

class LinkBasedStoryForm extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      openGraphFailed: false,
      urlSubmitted:    false,
      checkingUrl:     false,
    };
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

	componentDidMount() {
    this.urlServiceClient = new UrlServiceClient();
	}

  async componentWillUnmount() {
    await this.urlServiceClient.destroy();
  }

  getGraphData = async () => {
    console.log('Something');
    const url = this.state.sourceLink;
    if (!url) {
      console.log('Nothing');
      return;
    }
    const stateReset = {
      openGraphFailed: false,
      urlSubmitted:    true,
    };
    await this.toggleVar('checkingUrl', stateReset);
    const body = {payload: {uri: url}};
    console.log(body);
    const response = await this.urlServiceClient.requestOpenGraphInfoForUri(body);
    const updatedState = {};
    if (response.success) {
      updatedState.openGraphInfo = response.openGraphInfo;
    } else {
      updatedState.openGraphFailed = true;
    }
    await this.toggleVar('checkingUrl', updatedState);

    console.log(response);
  }

  prepareForNewUrl = () => {
    const stateReset = {
      checkingUrl:     false,
      openGraphFailed: false,
      urlSubmitted:    false,
      sourceLink:      '',
      openGraphInfo:   undefined,
      sourceState:     undefined,
    };
    this.setState(stateReset);
  }

  findOrCreateLink = async () => {
    let link = this.state.sourceLink;
    if (this.state.openGraphInfo) {
      link = this.state.openGraphInfo.ogUrl;
    }
    const response = await Api.findOrCreateSourceWithLink(link);
    console.log(response);
    const updatedState = {};
    if (response.status === 'created') {
      updatedState.sourceState = 'Added Link To Your Library';
    } else if (response.status === 'found') {
      updatedState.sourceState = 'This Link Is Already In Your Library';
    }
    this.setState(updatedState);
  }

  render() {
    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}>
        <div
	        className={classnames({
	        	'turquoise-border':   true,
	          'half-parent-height': true,
	          'fill-parent-width':  true,
            'base-padding':       true,
	        })}
	        > 
          <div className={classnames({
            'fill-parent-width':     true,
          })}>
            <label className="block-label">
              { (this.state.urlSubmitted && 'Submitted Link') || 'Enter Link'} 
              { this.state.urlSubmitted &&
                <button
                  style={{float: 'right'}}
                  onClick={this.prepareForNewUrl}>
                  Clear 
                </button>
              }
            </label>
            { !this.state.urlSubmitted &&
              <ExpandableTextArea
                className={classnames({'word-break-all': true})}
                value={this.state.sourceLink} 
                updateValue={sourceLink => this.updateStringField('sourceLink', sourceLink.trim())}
              />
            }
            { this.state.urlSubmitted &&
              <div className={classnames({
                'fill-parent-width':     true,
              })}>
                <div className={classnames({
                  'word-break-all': true,
                })}
                >{this.state.sourceLink}</div>
              </div>
            }
          </div>
          { (!this.state.checkingUrl && !this.state.urlSubmitted) &&
            <button
              onClick={this.getGraphData}>
              Get Graph Data 
            </button>
          }
          { this.state.checkingUrl &&
            <div
              className={classnames({
                'fill-parent-width':  true,
              })}>
              Looking For Open Graph Information
              </div>
          }
          { this.state.openGraphFailed &&
            <div className={classnames({
              'fill-parent-width':     true,
            })}>
              <label className="block-label">Couldnt Find Open Graph Information For Link</label>
            </div>
          }
          { (this.state.openGraphInfo && this.state.openGraphInfo.ogUrl &&
             this.state.openGraphInfo.orUrl !== this.state.sourceLink) &&
            <>
            <Spacer type="vertical" height="30px"/>
            <div className={classnames({
              'fill-parent-width':     true,
            })}>
              <label className="block-label">Clean Link</label>
              <div>{this.state.openGraphInfo.ogUrl}</div>
            </div>
            </>
          }
        </div>
        <div
	        className={classnames({
	        	'green-border':       true,
            'half-parent-height': true,
            'fill-parent-width':  true,
            'align-children-top': true,
            'parent-with-bordered-horizontal-children': true,
	        })}
	        > 
          <div
            className={classnames({
              'fill-parent-height': true,
              'half-parent-width':  true,
              'base-padding':       true,
              'inline-display':     true, 
            })}
            > 
            { this.state.openGraphInfo &&
              <>
                <label className="block-label">Open Graph Preview</label>
                <OpenGraphPreview 
                  sourceId={'test'}
                  openGraphInfo={this.state.openGraphInfo}
                />
              </>
            }
          </div>
          <div
            className={classnames({
              'fill-parent-height': true,
              'half-parent-width':  true,
              'base-padding':       true,
              'inline-display':     true, 
            })}
            > 
            { this.state.urlSubmitted &&
              <label className="block-label">Next Steps</label>
            }
            { (this.state.urlSubmitted && !this.state.sourceState) &&
              <button
                style={{float: 'left'}}
                onClick={this.findOrCreateLink}>
                Add Link To Library 
              </button>
            }
            { (this.state.urlSubmitted && this.state.sourceState) &&
              <label className="block-label">{this.state.sourceState}</label>
            }
          </div>
        </div>
      </div>
    );
  }
};

export default LinkBasedStoryForm;
