// Stores The State That Is Necessary to Render the UI
import RecordWithHistory from './../RecordTypes/RecordWithHistory';
import AuthService from './../../AuthService';

const UserTabStateDef = {
  user:      { type: 'id', collection: 'User', hashable: true },
  tab:       { type: 'id', hashable: true },
  workspace: { type: 'id', collection: 'Workspace', hashable: true },
  toolType:  { type: 'string', hashable: true },
};

class UserTabStateService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'userTabState', UserTabStateDef, true);
  }

  async createUserTabState(userId, tabId, workspaceId, options = {}) {
		const userTabStateObj = {
			user:      userId,
			tab:       tabId,
			workspace: workspaceId,
      toolType:  null,
			// Id Fields Initially Null
			// selectedStory
			// selectedStoryViewConfig
			// storyViewConfig
			// eventDraft: DraftId
			
		};
    return super.createNewRecord(userTabStateObj);
  }

	async getUserTabState(userId, tabId) {
    const queryObj = {
      user: userId,
      tab:  tabId,
    };
		const existingTabState = await super.findOneWithFields(queryObj)
    if (existingTabState) {
      return existingTabState;
    }
    return null;
	}

  async editToolTypeForUserTabState(userId, tabId, toolType) {
    const editorId = AuthService.getUserId();
    const uniqueQuery = {
      user: userId,
      tab:  tabId,
    };
    const userTabState = await super.findOneWithFields(uniqueQuery, 'id hash');
    const fieldsToUpdate = {
      toolType,
    };
    const response = await super.updateRecord(editorId, userTabState.id,
                                              userTabState.hash,
                                              {fieldsToUpdate});
    return response;
  }

  // /////////////////////////////////////////////////////////////////////////// 
  // Not Checked
  setNetworkState(userTabStateId, networkEnabled) {
    const updateFunction = (userTabState) => {
      userTabState.networkEnabled = networkEnabled;
      return userTabState;
    }
    return super.updateItemWithKey(userTabStateId, updateFunction);
  }

  async setEventDraftForTabState(userTabStateId, eventDraftId) {
  	const updateFunction = (userTabState) => {
  		userTabState.eventDraft = eventDraftId;
  		return userTabState;
  	}
		return super.updateItemWithKey(userTabStateId, updateFunction);
  }

  async setStoryViewConfigForTabState(userTabStateId, configId) {
  	const updateFunction = (userTabState) => {
  		userTabState.storyViewConfig = configId;
  		return userTabState;
  	}
		return super.updateItemWithKey(userTabStateId, updateFunction);
  }

  async setSelectedStoryForTabState(userTabStateId, storyId) {
  	const updateFunction = (userTabState) => {
  		userTabState.selectedStory = storyId;
  		if (!storyId) {
  			userTabState.storyViewConfig = undefined;
  		}
  		return userTabState;
  	}
		return super.updateItemWithKey(userTabStateId, updateFunction);
  }
}

export default (new UserTabStateService());
