import React from 'react';
import classnames from 'classnames';

import './SmartCardManager.css';

import SmartCardService from './../../../Services/SmartCardService';
import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';

import Icon from './../../../Shared/Icons/Icon';
import DropdownCaretWithTooltip from '../../../Shared/Buttons/DropdownCaretWithTooltip';

import SmartCardWallet from './SmartCardWallet/SmartCardWallet';

import KeySetWithCard from './KeySetWithCard';


class SmartCardManager extends React.Component {
  constructor(props) {
  	super(props);
  	this.state = {
  		selectedKeys:             {
  			encrypt: {cardId: '', keyId: ''},
  			sign:    {cardId: '', keyId: ''},
  		},
  		signatureRequests:  {},
  		existingCards:      [],
  		walletOpen:         false,
  		encryptKeyExpanded: false,
  		signKeyExpanded:    false,
  	};
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  componentDidMount() {
  	this.setListenerForKeySelection();
  }

  setListenerForKeySelection() {
  	SmartCardService.setHandlerForKeyChanges('scm', () => {
  		const selectedKeys = SmartCardService.getSelectedKeys();
  		this.setState({selectedKeys});
  	});	

  	SmartCardService.setHandlerForSignatureRequests('scm', () => {
  		const signatureRequests = SmartCardService.getSignatureRequests();
  		this.setState({signatureRequests});
  	});	

		const selectedKeys = SmartCardService.getSelectedKeys();
		this.setState({selectedKeys});
  }

  componentWillUnmount(){
		SmartCardService.removeHandlerForKeyChanges('scm');
		SmartCardService.removeHandlerForSignatureRequests('scm');
  }

  // Scan For Cards Button
  // List Cards
  // Select Cards
  // Generate Card
  // Message: Prototype only supports browser cards and they are not secure
  // but instead meant to teach the behaviors of using smart cards.
  render() {
  	const signatureRequestIds = Object.keys(this.state.signatureRequests);
  	let signatureRequestDivs = [];
  	if (signatureRequestIds.length) {
  		signatureRequestDivs = signatureRequestIds.map(signatureRequestId => {
  			const signatureRequest = this.state.signatureRequests[signatureRequestId];
  		 	return (
					<div 
						key={signatureRequestId}
						className={classnames({
							'fill-parent-width':  true,
							'turquoise-border':   true,
							'base-padding':       true,
							'rounded-box-border': true,
						})}>
						<div>{signatureRequest.name}</div>
	          <button
	            onClick={() => SmartCardService.approveSignatureForRequestWithId(signatureRequestId)}>
	            Approve 
	          </button>
	          <button
	            onClick={() => SmartCardService.rejectSignatureRequestWithId(signatureRequestId)}>
	            Reject 
	          </button>
  		 		</div>
  		 	);	
  		}); 
  	}
		return (
			<div 
				className={classnames({
					'fill-parent-width':  true,
					'white-border':       true,
					'base-padding':       true,
					'rounded-box-border': true,
				})}
			>
				<div className={classnames({})}>
	        <div style={{fontSize: '27px'}}>
	         <Icon iconType="lock"/>  Key Manager 
	        </div>
				  Selected Key Sets&nbsp;&nbsp;
		      <DropdownCaretWithTooltip
		      	expanded={this.state.walletOpen}
		       	toggleExpand={() => this.toggleVar('walletOpen')}
		       	expandTooltip="Open Wallet"
		       	collapseTooltip="Close Wallet"
		      />
				</div>
        <div className={classnames({
        	'top-margin':        true,
        	'fill-parent-width': true,
        })}>
	        <KeySetWithCard
	        	keyType={'encrypt'}
	        	cardId={this.state.selectedKeys.encrypt.cardId}
	        	keyId={this.state.selectedKeys.encrypt.keyId}
	        	expanded={this.state.encryptKeyExpanded}
	        	toggleExpand={() => this.toggleVar('encryptKeyExpanded')}
	        />
	        <KeySetWithCard
	        	keyType={'sign'}
	        	cardId={this.state.selectedKeys.sign.cardId}
	        	keyId={this.state.selectedKeys.sign.keyId}
	        	expanded={this.state.signKeyExpanded}
	        	toggleExpand={() => this.toggleVar('signKeyExpanded')}
	        />
		    </div>
		    {!!signatureRequestDivs.length &&
		    	<>
		        <div style={{fontSize: '20px'}}>
				  		<Icon iconType="signature"/>
		          Signature Requests 
		        </div>
		        {signatureRequestDivs}
	        </>
		    }
		    { this.state.walletOpen &&
        	<SmartCardWallet 
        	/>
		    }
	    </div>
	  );
  }
};

export default SmartCardManager;
