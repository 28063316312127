import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../../../../Shared/Icons/Icon';
import SmartCardService from '../../../../Services/SmartCardService';

function SmartCardPreview(props) {
	const {
		id,
		name,
		selectHandler,
		deleteHandler,
	} = props;
	return (

		<div className={classnames({
				'top-margin':         true,
				'rounded-box-border': true,
				'turquoise-border':   true,
		})}>
			<div>
				<Icon iconType="card" color="green" />
				Card {name &&
					<span>Name: {name}</span>
				}
				{ !name &&
					<span>Id: {SmartCardService.getDisplayId(id)}</span>
				}
			</div>
			<div>
        <button
          onClick={selectHandler}>
          Show Keys 
        </button>
        <button
          onClick={() => deleteHandler(id)}>
          Delete 
        </button>
      </div>
		</div>
 	);
}

SmartCardPreview.propTypes = {
	id:            PropTypes.string.isRequired,
	selectHandler: PropTypes.func.isRequired,
	deleteHandler: PropTypes.func.isRequired,
	name:          PropTypes.string,
};

export default SmartCardPreview;
