import RestApiBase from './../../Utilities/RestApiBase';

const mapServiceBaseUrl = 'http://localhost:3003';

class MapServiceApi extends RestApiBase {
	constructor() {
		super(mapServiceBaseUrl);
	}

	async getMapOptionsThatMatchSearchString(searchString) {
		return this.post('test2', {searchString});
	}

	async getFeaturesFromMapPath(mapPath) {
		return this.post('test3', {mapPath});
	}

	async getDataForMapOfType(mapType) {
		console.log('Sending Request To Server');
		return this.post('test4', {mapType});
	}
}

export default MapServiceApi;
