import { get, set } from 'idb-keyval';

class IndexedDBCollection {
	constructor(dbPrefix, collectionName, primaryField) {
		this.storeName = `${dbPrefix}_${collectionName}`; 
		this.primaryField = primaryField;
		this.defaultValue = {};
	}

	async findWithPrimaryField(fieldValues) {
		const docIdMap = await this.getPrimaryKeyMap();
		return fieldValues.map(docId => docIdMap[docId]).filter(doc => doc);
	}

	async findOneWithPrimaryField(fieldValue) {
		const docIdMap = await this.getPrimaryKeyMap();
		const firstFound = Object.values(docIdMap).find(doc => {
			return doc[this.primaryField] === fieldValue;
		});
		if (!firstFound) {
			return null;
		}
		return firstFound;
	}
	async findOneWithPrimaryFieldAndUpdate(fieldValue, updateFunc) {
		const docIdMap = await this.getPrimaryKeyMap();
		const firstFound = Object.values(docIdMap).find(doc => {
			return doc[this.primaryField] === fieldValue;
		});
		if (!firstFound) {
			return null;
		}
		const updatedRecord = updateFunc(firstFound);
		updatedRecord.updatedAt = new Date();
		docIdMap[firstFound[this.primaryField]] = updatedRecord;
		await this.updatePrimaryKeyMap(docIdMap);
		return updatedRecord;
	}

	async findOneWithField(fieldName, fieldValue) {
		const docIdMap = await this.getPrimaryKeyMap();
		const firstFound = Object.values(docIdMap).find(doc => {
			return doc[fieldName] === fieldValue;
		});
		if (!firstFound) {
			return null;
		}
		return firstFound;
	}

	async findOneWithFields(fieldMap) {
		const docIdMap = await this.getPrimaryKeyMap();
		const firstFound = Object.values(docIdMap).find(doc => {
			const missingFieldIndex = Object.keys(fieldMap).findIndex(fieldName => {
				const fieldValue = fieldMap[fieldName];
				return doc[fieldName] !== fieldValue;
			});
			return missingFieldIndex < 0; 
		});
		if (!firstFound) {
			return null;
		}
		return firstFound;
	}

	async findWithField(fieldName, fieldValue) {
		const docIdMap = await this.getPrimaryKeyMap();
		return Object.values(docIdMap).filter(doc => {
			return doc[fieldName] === fieldValue;
		});
	}


	async findOneAndUpdate(fieldName, fieldValue, updateFunc) {
		const docIdMap = await this.getPrimaryKeyMap();
		const firstFound = Object.values(docIdMap).find(doc => {
			return doc[fieldName] === fieldValue;
		});
		if (!firstFound) {
			return null;
		}
		const updatedRecord = updateFunc(firstFound);
		updatedRecord.updatedAt = new Date();
		docIdMap[firstFound[this.primaryField]] = updatedRecord;
		await this.updatePrimaryKeyMap(docIdMap);
		return updatedRecord;
	}

	async add(doc) {
		const docIdMap = await this.getPrimaryKeyMap();
		const primaryValue = doc[this.primaryField];
		if (docIdMap[primaryValue]) {
			throw new Error('Value With Key Exists');
		}
		doc.createdAt = new Date();
		docIdMap[primaryValue] = doc;
		await this.updatePrimaryKeyMap(docIdMap);
		return doc;
	}

	// ///////////////////////////////////////////////////////////////////////////
	// Private Fields
  async getPrimaryKeyMap() {
		return this.getFromIndexDBStoreOrDefault(this.storeName);
  }		

  updatePrimaryKeyMap(primaryKeyMap) {
  	return this.saveInIndexDBStore(this.storeName, primaryKeyMap);
  }

	async saveInIndexDBStore(store, value) {
	  return set(store, value);
	}

	async getFromIndexDBStoreOrDefault(storeName) {
	  const storedValue = await get(storeName);
	  return storedValue || this.defaultValue;
	}
}

export default IndexedDBCollection;
