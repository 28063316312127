import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import './ToolTypeSelector.css';

class ToolTypeSelector extends React.Component {
  static propTypes = {
    goToChooseAWorkspace: PropTypes.func.isRequired,
    goToSourceLibrary:    PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

	componentDidMount() {
	}

  async componentWillUnmount() {
  }


  render() {
    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        	'base-padding':       true,
        })}>
        <div
	        className={classnames({
	        	'clickable':             true,
	        	'grow-on-hover':         true,
	        	'green-border':          true,
	          'fill-parent-height':    true,
	          'half-parent-width':     true,
	        	'vertically-align-text': true,
	        })}
          onClick={this.props.goToSourceLibrary}
	        > 
	        Go To Personal Library
        </div>
        <div
          className={classnames({
            'clickable':             true,
            'grow-on-hover':         true,
            'turquoise-border':      true,
            'fill-parent-height':    true,
            'half-parent-width':     true,
            'vertically-align-text': true,
          })}
          onClick={this.props.goToChooseAWorkspace}
          > 
          Go To Workspace Manager  
        </div>
      </div>
    );
  }
};

export default ToolTypeSelector;
