import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ExpandableTextArea from './../ExpandableTextArea/ExpandableTextArea';

import './LimitedTextArea.css';


class LimitedTextArea extends React.Component {

  static propTypes = {
  	updateValue:    PropTypes.func.isRequired,
    charsPerRow:    PropTypes.number,
  	value:          PropTypes.string,
  	rows:           PropTypes.number,
    focusOnLoad:    PropTypes.bool,
    showLimit:      PropTypes.bool,
    wrapperClasses: PropTypes.object,
  }

  static defaultProps = {
  	value:          '',
  	rows:           2,
    showLimit:      true,
    wrapperClasses: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    	content: this.props.initialValue,
    };
  }

  setFormattedContent = text => {
  	const limit = this.props.limit;
  	let trimmedValue = text.length > limit ? text.slice(0, limit) : text;
    this.props.updateValue(trimmedValue);
  };

  render() {
  	const {
  		limit,
  		value,
  		charsPerRow,
      focusOnLoad,
      showLimit,
      wrapperClasses,
  	} = this.props; 
  	return (
	    <div className={classnames({...wrapperClasses})}>
	      <ExpandableTextArea
          focusOnLoad={focusOnLoad}
          charsPerRow={charsPerRow}
	        updateValue={this.setFormattedContent}
	        value={value}
	      />
        {
          showLimit &&
          <p style={{margin: '0', textAlign: 'right'}}>
            {value.length}/{limit}
          </p>
        }
	    </div>
	 	);
  }
};
export default LimitedTextArea;
