import { v4 as uuid } from 'uuid'; 

import BrowserStorage from './../Shared/Utilities/BrowserStorage';

const tabIdName = 'TAB_ID';

const TabService = {
	getTabId,
	createNewTabId,
}

function getTabId() {
	const tabId = BrowserStorage.session.getItemOrDefault(tabIdName);
	return tabId;
}

function createNewTabId() {
	const newTabId = uuid();
	BrowserStorage.session.setItem(tabIdName, newTabId);
	return newTabId;
}

export default TabService;
