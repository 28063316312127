import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import SingleStoryMap from '../../Common/Map/SingleStoryMap';
// import MultiSilhouetteMap from '../../Common/Map/MultiSilhouetteMap';


import './MapLoadingManager.css';
const MapType = {
	Default:         'Default',
	Single:          'SingleMap', 
	MultiSilhouette: 'MultiSilhouette',
	SingleStory:     'SingleStory',
};

/*
	TODO: Make this default to all the silhouettes of all the stories if
	      possible
*/ 
class MapLoadingManager extends React.Component {
  static propTypes = {
  	mapPxWidth:       PropTypes.number.isRequired,
  	selectMapFeature: PropTypes.func.isRequired,
  	eventDraftId:     PropTypes.string,
  	eventDraft:       PropTypes.object,
  	selectedStoryId:  PropTypes.string,
  	selectedStory:    PropTypes.object,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    	loading:      true,
    	delayedProps: {},
    };
  }
  async componentDidMount() {
  	// const mapType = this.getMapTypeFromProps(this.props);
		// this.setState({mapType, loading: false});
  	if (!this.timeout) {
			this.timeout = setTimeout(() => {
				this.applyProps({}, this.newProps);
				this.timeout = null;
			}, 1000);	
  	}
		this.newProps = Object.assign({}, this.props);
  }
  getMapTypeFromProps(props) {
  	if (props.eventDraftId) {
  		if (props.eventDraft.intermediateState.featureGroup) {
	      /*this.state.userTabState.eventDraft &&
	        this.state.eventDraft.intermediateState.featureGroup*/
	  		return MapType.Single;
	  	} else {
	        /*this.state.userTabState.eventDraft &&
	        	 !this.state.eventDraft.intermediateState.featureGroup*/
	  		return MapType.MultiSilhouette;
	  	}
  	} else if (props.selectedStoryId) {
        /*!this.state.userTabState.eventDraft &&
        	 this.state.userTabState.selectedStory*/
  		return MapType.SingleStory;
  	} else {
  		return MapType.Default;	
  	}
  }

  applyProps(oldProps, newProps) {
  	const mapType = this.getMapTypeFromProps(newProps);
  	// const oldMapType = this.getMapTypeFromProps(oldProps);
  	console.log('applying props');
 		this.setState({loading: false, mapType, delayedProps: newProps});
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
  	if (!this.timeout) {
  		this.setState({loading: true});
			this.timeout = setTimeout(() => {
				this.oldProps = Object.assign({}, this.props);
				this.applyProps(this.oldProps, this.newProps);
				this.timeout = null;
			}, 200);	
  	}
		this.newProps = Object.assign({}, nextProps);
  }

  render() {
  	let MapComponent = (<div>Loading</div>);
  	const delayedProps = this.state.delayedProps;
 		console.log('MapType: ' + this.state.mapType);
  	if (this.state.mapType === MapType.Default) {
  		MapComponent = (
        <SingleStoryMap 
          selectMapFeature={delayedProps.selectMapFeature}
          mapSilhouettes={['world_worldHigh']}
          events={[]}
        />
  		);
  	} else if (this.state.mapType === MapType.SingleStory) {
  		MapComponent = (
        <SingleStoryMap 
          selectMapFeature={delayedProps.selectMapFeature}
          mapSilhouettes={[...(delayedProps.selectedStory.mapPaths || [])]}
          events={delayedProps.selectedStory.events}
        />
      );
  	} else if (this.state.mapType === MapType.MultiSilhouette) {
  		MapComponent = (
        <SingleStoryMap 
          selectMapFeature={delayedProps.selectMapFeature}
          mapSilhouettes={[...(delayedProps.selectedStory.mapPaths || [])]}
          events={[delayedProps.eventDraft.editedState]}
        />
      );
  		/*
  		MapComponent = (
          <MultiSilhouetteMap 
            selectMapFeature={delayedProps.selectMapFeature}
            mapSilhouettes={delayedProps.selectedStory.mapPaths}
            featureGroups={delayedProps.eventDraft.editedState.locations}
          />
      );
      */
  	} else if (this.state.mapType === MapType.Single) {
  		const eventDraftIS = delayedProps.eventDraft.intermediateState;
      const sils = [];
      if (eventDraftIS.featureGroup.mapPath) {
        sils.push(eventDraftIS.featureGroup.mapPath);
      }
      const event = {
        hash:      'tmp', 
        locations: [{
            mapPath:  eventDraftIS.featureGroup.mapPath,
            features: eventDraftIS.featureGroup.features,
            color:    eventDraftIS.featureGroup.color,
            hash:     eventDraftIS.featureGroup.hash,
        }],
      };
  		MapComponent = (
        <SingleStoryMap 
          selectMapFeature={delayedProps.selectMapFeature}
          mapSilhouettes={sils}
          events={[event]}
        />
  		);
  	}
  	const loadingStyles = {
  			background: 'grey',
  			width: '100%',
  			height: '100%',
  			position: 'absolute',
  			zIndex: 2,
  		};
  	return (
      <div
      	className="map-container"
      	style={{width: `${this.props.mapPxWidth}px`}}>
   	      <CSSTransition
   	      	in={this.state.loading}
   	      	timeout={2000}
   	        unmountOnExit
   	      	classNames="my-node">
	      		<div style={loadingStyles}>
            Loading
	      		</div>
      		</CSSTransition>
          {MapComponent}
      </div>
    );
  }
}

export default MapLoadingManager;
