import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {TimeFrameType} from '../../../Shared/TapestryConstants/Enums';

import './DisplayTimeFrame.css';


class DisplayTimeFrame extends React.Component {

  static propTypes = {
  	timeFrame: PropTypes.exact({
      type:        PropTypes.string.isRequired,
	  	startDate:   PropTypes.number.isRequired,
	  	endDate:     PropTypes.number,
      // Depricated
      tfType:      PropTypes.string,
  	}).isRequired,
  }

  static defaultProps = {
  }

  render() {
    const {
      timeFrame,
    } = this.props;
    console.log(timeFrame);

    if (timeFrame.type === TimeFrameType.ONGOING_EVENT) {
      return (
        <div style={{fontSize: '12px', verticalAlign:'bottom'}}>
          <div style={{display: 'inline-block', width: '49%'}}>  
            Start: {moment(timeFrame.startDate).format('MM-DD-YYYY')}
          </div>
          <div style={{display: 'inline-block', width: '49%'}}>
            End: Present
          </div>
        </div>
      );
    } else if (timeFrame.type === TimeFrameType.IS_INSTANT) {
      return (
        <div style={{fontSize: '12px', verticalAlign:'bottom'}}>
          <div style={{display: 'inline-block', width: '49%'}}>  
            Date: {moment(timeFrame.startDate).format('MM-DD-YYYY')}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{fontSize: '12px', verticalAlign:'bottom'}}>
          <div style={{display: 'inline-block', width: '49%'}}>  
            Start: {moment(timeFrame.startDate).format('MM-DD-YYYY')}
          </div>
          <div style={{display: 'inline-block', width: '49%'}}>
            End: {moment(timeFrame.endDate).format('MM-DD-YYYY')}
          </div>
        </div>
      );
    }
  }
};
export default DisplayTimeFrame;
