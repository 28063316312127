import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import NewStoryForm from '../NewStoryForm/NewStoryForm';
// import StorySidepanel from '../StorySidepanel/StorySidepanel';
// import SourceSidepanel from '../SourceSidepanel/SourceSidepanel';

import Spacer from '../../../Shared/Utilities/Spacer';
import CommonClassMethods from '../../../Shared/Utilities/CommonClassMethods';

import Api from '../../../MockApi/Api.js';

import './WorkspaceHeader.css';

class WorkspaceHeader extends React.Component {

  static propTypes = {
  	selectStoryWithIdHandler: PropTypes.func.isRequired,
  	addNewStoryHandler:       PropTypes.func.isRequired,
    showSourceLibrary:        PropTypes.func.isRequired,
  	stories:                  PropTypes.array.isRequired,
    workspaceId:              PropTypes.string.isRequired, 
    workspaceHash:            PropTypes.string.isRequired, 
  	numStories:               PropTypes.number,
  }

  static defaultProps = {
  	numStories: 0,
  	stories:    [],
  }

  constructor(props) {
    super(props);
    this.state = {
    	storyPaneIsOpen:         false,
      sourcePaneIsOpen:        false,
    	newStoryModalIsOpen:     false,
      countryOfDayStoryExists: true, 
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  async componentDidUpdate(prevProps) {
  }

  addNewStory = async storyId => {
    // Api Add Story Id To Workspace
    await Api.addStoryIdToWorkspace(this.props.workspaceId, this.props.workspaceHash,
                                    storyId);
    this.props.addNewStoryHandler(storyId);
    this.toggleVar('newStoryModalIsOpen');
  }

  selectStoryAndClosePanel = storyId => {
    this.toggleVar('storyPaneIsOpen');
    // Added So the modal would close before to attempt loading
    setTimeout(() => {
      this.props.selectStoryWithIdHandler(storyId);
    }, 500);
  }

  storyWithNameExists = (stories, story) => {
    return stories.some(existingStory => {
      return existingStory.name === story.name;
    });
  }

  render() {
  	return (
  		<div className="workspace-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
    			<div
            className={classnames({
              'fill-parent-height': true,
              'vertically-align-text': true,
            })}
            style={{marginRight: '10px'}}>
            Num Stories: {this.props.numStories}
          </div>
          { this.props.stories.length > 0 &&
            <button
              className="workspace-button"
              onClick={() => this.toggleVar('storyPaneIsOpen')}>
              Select Story 
            </button>
          }
          {/*
	      	<SmallButton
            title="Create Story"
            variant="primary"
	      		onClick={() => this.toggleVar('newStoryModalIsOpen')}>
	      	</SmallButton>
          */}
      	</div>
        <div 
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{display: 'inline-block', float: 'right'}}>
          <button
            onClick={this.props.showSourceLibrary}>
            Show Source Library 
          </button>
          <Spacer type="horizontal" width="10px"/>
          <Spacer type="horizontal" width="2px" background="red" />
          <Spacer type="horizontal" width="10px"/>
        </div>
        {/*
        <button
          style={{float: 'right'}}
          className="workspace-button"
          onClick={() => {alert('Coming Soon')}}>
          Misc Notes 
        </button>
        <button
          style={{float: 'right'}}
          className="workspace-button"
          onClick={() => this.toggleVar('sourcePaneIsOpen')}>
          Misc Links 
        </button>
        <SourceSidepanel
          isPaneOpen={this.state.sourcePaneIsOpen}
          closePaneHandler={() => this.toggleVar('sourcePaneIsOpen')}
        />
      	<StorySidepanel
      		selectStoryHandler={this.selectStoryAndClosePanel}
      		stories={this.props.stories}
      		isPaneOpen={this.state.storyPaneIsOpen}
      		closePaneHandler={() => this.toggleVar('storyPaneIsOpen')}
      	/>

        <NewStoryForm 
          open={this.state.newStoryModalIsOpen}
          submitFormHandler={this.addNewStory}
          cancelForm={() => this.toggleVar('newStoryModalIsOpen')}
        />
        */}
  		</div>
	 	);
  }
};
export default WorkspaceHeader;
