import RecordWithHistory from './../RecordTypes/RecordWithHistory';

import {SourceType} from './../../../Shared/TapestryConstants/Enums';
import UrlServiceClient from './../../../Shared/Network/UrlService/UrlServiceClient';

const SourceDef = {
  type:          { type: 'string', enum: SourceType, hashable: true },
  uri:           { type: 'string', hashable: true },
  openGraphInfo: { type: 'OpenGraphInfo' },
  openGraphDate: { type: 'date' },
  // TODO: Use api to get content and hash that content
  // contentHash:      { type: 'string', hashable: true },
  // constentHashDate: { type: 'date', hashable: true },
};
class SourceService extends RecordWithHistory {
  constructor() {
    super('tapestry', 'source', SourceDef, true);
  	this.SourceType = {SourceType};
  }

  async findOrCreateSourceWithLink(link) {
		return this.findOrCreateSourceOfType(this.SourceType.LINK, {uri: link});
  }

  async findOrCreateSourceOfType(type, options = {}) {
    let openGraphResponse;
    // ///////////////////////////
    // Find
    let existingSource;    
    const queryObj = {
      type, 
    };
    if (type === this.SourceType.LINK) {
      queryObj.uri = options.uri; 
      // Check Open Graph
      openGraphResponse = await this.getOpenGraphDataForUri(options.uri); 
      if (openGraphResponse && openGraphResponse.openGraphInfo.ogUrl) {
        queryObj.uri = openGraphResponse.openGraphInfo.ogUrl;
      }
      existingSource = await super.findOneWithFields(queryObj)
    }
    if (existingSource) {
      return existingSource;
    }
    // ///////////////////////////
    // Create 
    const sourceObj = {
      type,
    };
		if (type === this.SourceType.LINK) {
			const linkFields = ['uri'];
			linkFields.forEach(linkField => {
				sourceObj[linkField] = options[linkField];	
			});
      if (openGraphResponse && openGraphResponse.openGraphInfo) {
        const openGraphInfo = openGraphResponse.openGraphInfo;
        sourceObj.openGraphInfo = openGraphResponse.openGraphInfo;
        sourceObj.openGraphDate = new Date();
        if (openGraphInfo.ogUrl) {
          sourceObj.uri = openGraphInfo.ogUrl;
        }
      }
		} else {
			throw new Error('Unknown Source Type: ' + type);
		}
    return super.createNewRecord(sourceObj);
  }
  async getOpenGraphDataForUri(uri) {
    // Get Open Graph Info
    const urlServiceClient = new UrlServiceClient();
    try {
      const body = {payload: {uri}};
      const response = await urlServiceClient.requestOpenGraphInfoForUri(body);
      if (response.success) {
        return response;
      }
    } catch (e) {
      console.log(e); 
    }
    await urlServiceClient.destroy();
  }

  getAllSources() {
  	return super.getAllItemsFromStore();
  }

  async getSourceWithId(sourceId) {
    // TODO: Try to find a better way to do this
    return super.getItemWithId(sourceId);
  }

  async getSourcesWithIds(sourceIds) {
  	// TODO: Try to find a better way to do this
  	return super.getItemsWithIds(sourceIds);
  }
}

export default (new SourceService());
