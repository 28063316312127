import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import wrapperWithDimensions from './../../../Shared/HOC/wrapperWithDimensions';

import Api from './../../../MockApi/Api';

// import './SourceLibrary.css';

class NoteLibrary extends React.Component {
  static propTypes = {
    multiRow:      PropTypes.bool,
    loadAll:       PropTypes.bool,
    searchString:  PropTypes.string,
    startDateTime: PropTypes.instanceOf(Date), 
    endDateTime:   PropTypes.instanceOf(Date), 
  }

  static defaultProps = {
    loadAll: true,
  }

  constructor(props) {
    super(props);
    this.state = {
    	notes: [],
    };
  }

	async componentDidMount() {
    if (this.props.loadAll) {
      const notes = await Api.getAllUserNotes();
      this.setState({notes});
    }
	}

  async componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString ||
        prevProps.startDateTime !== this.props.startDateTime ||
        prevProps.endDateTime !== this.props.endDateTime) {
      const { searchString, startDateTime, endDateTime } = this.props;
      const searchParameters = {
        searchString, startDateTime, endDateTime,
      };
      console.log(searchParameters);
      if (!this.props.searchString && !this.props.startDateTime
          && !this.props.endDateTime) {
        const notes = await Api.getAllUserNotes();
        this.setState({notes});
      } else {
        const newNotes = await Api.getUserNotesWithSearchParameters(searchParameters);
        this.setState({notes: newNotes});
      }
    }
  }

  render() {
  	if (!this.state.notes || this.state.notes.length === 0) {
  		return (
  			<div
	        className={classnames({
	          'fill-parent-height': true,
	          'fill-parent-width':  true,
	        })}
  			>
	  			No Notes Yet
  			</div>
  		);
  	}
  	const noteDivs = this.state.notes.map(note => {
			return (
				<div 
          key={note.id}
          style={{height: '30px'}}
          className={classnames({
            'fill-parent-width': true,
            'turquoise-border':      true,
          })}>
				  {note._text}
				</div>
			)
  	});
  	return (
			<div
        className={classnames({
          'fill-parent-height':  true,
          'fill-parent-width':   true,
          'multi-source-parent': this.props.multiRow,
          'scrollable':          true,
          'base-padding':        true,
          'green-border':        true,
        })}
			>
  			{noteDivs}	
			</div>
	 	);
  }
};

export default wrapperWithDimensions(NoteLibrary);
