import React from 'react';
import PropTypes from 'prop-types';

import ScrollableWithHeader from '../../Common/ScrollableWithHeader/ScrollableWithHeader';
import DisplayTimeFrame from '../../Common/TimeFrame/DisplayTimeFrame';

import './StoryDetailsSidepanel.css';


class StoryDetailsSidepanel extends React.Component {

  static propTypes = {
    openEventForm:        PropTypes.func.isRequired,
    removeEventFromStory: PropTypes.func.isRequired,
    events:               PropTypes.array,
  }

  static defaultProps = {
    events: [],
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
  	const eventDivs = this.props.events.map(event => {
  		return (
  			<div
  				key={event.id}
  				className='event-cell'>
          <div style={{height: '40%'}}>
    				{event.description}
          </div>
          { event.source &&
            <div style={{color: 'lightblue', height: '20px'}}>
              <a
                rel="noopener noreferrer"
                href={event.source.link} target="_blank">
                Source
              </a>
            </div>
          }
  				{ event.timeFrame &&
            <DisplayTimeFrame
              timeFrame={event.timeFrame}
            />
  				}
          { event.location && 
            <div style={{color: 'lightblue', height: '20px'}}>
             {event.location.name}
            </div>

          }
          <div>
            <button
              onClick={() => this.props.openEventForm('editingEvent', event.id)}>
              Edit 
            </button>
            <button
              style={{color: 'white', background: 'red'}}
              onClick={() => this.props.removeEventFromStory(event.id)}>
              Remove from Story 
            </button>
          </div>
  			</div>
  		);
  	});
  	return (
      <ScrollableWithHeader 
        headerTitle={`Events (${this.props.events.length})`}
        headerButtonHandler={() => this.props.openEventForm('creatingEvent')}
        headerButtonName="Add Event"
      >
        {eventDivs}
      </ScrollableWithHeader>
	 	);
  }
};
export default StoryDetailsSidepanel;
