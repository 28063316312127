import EventService from '../MockApi/LocalDB/AtomicContent/EventService';
import SourceService from '../MockApi/LocalDB/AtomicContent/SourceService';
import StoryService from '../MockApi/LocalDB/UserPerspective/StoryService';
import WorkspaceService from '../MockApi/LocalDB/UserExperience/WorkspaceService';

const DisplayService = { 
	getStoryIdMapForWorkspaceId,
};


async function getStoryIdMapForWorkspaceId(workspaceId) {
	// Get All Story Ids For Workspace
	// For Each Story Replace the internal ids with the events
	// * events 
	const workspace = await WorkspaceService.getWorkspaceWithId(workspaceId);	
	const storyIds = workspace.stories;
	// Here
	const stories = await StoryService.getLatestStoriesWithIds(storyIds);
	console.log(stories);
	const displayStories = await Promise.all(stories.map(async story => {
		const eventIds = story.events;
		const displayEvents = await getDisplayEventsFromIds(eventIds); 
		return Object.assign(story, {events: displayEvents});
	})); 
	return displayStories.reduce((storyIdMap, displayStory) => {
		storyIdMap[displayStory.id] = displayStory;
		return storyIdMap;
	}, {});
}

async function getDisplayEventsFromIds(eventIds) {
	const events = await EventService.getEventsWithIds(eventIds); 
	return Promise.all(events.map(async event => {
		if (!event.source) {
			return event;
		}
		const sourceId = event.source;
		const displaySource = await SourceService.getSourceWithId(sourceId);
		return Object.assign(event, {source: displaySource});
	}));
}

export default DisplayService;
