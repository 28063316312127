import React from 'react';
import {
	FaCaretDown, FaCaretUp, FaFingerprint, FaIdCard, FaPlus, FaSignature,
	FaUserShield, FaWallet,
} from 'react-icons/fa';

function Icon({iconType, color}) {
	const styles = {};
	if (color) {
		styles.color = color;
	}
	if (iconType === 'key') {
		return (
			<span>&#128477;&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'lock') {
		return (
			<span role="img" aria-label={iconType}>&#128274;&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'wallet') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaWallet/>&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'card') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaIdCard/>&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'caret-down') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaCaretDown/></span>
		);
	} else if (iconType === 'caret-up') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaCaretUp/></span>
		);
	} else if (iconType === 'add') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaPlus/></span>
		);
	} else if (iconType === 'signature') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaSignature/>&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'identity') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaUserShield/>&nbsp;&nbsp;</span>
		);
	} else if (iconType === 'tapestry-logo') {
		return (
			<span style={styles}role="img" aria-label={iconType}><FaFingerprint/>&nbsp;&nbsp;</span>
		);
	}
}

export default Icon;
