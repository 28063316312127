import React from 'react';
import classnames from 'classnames';

import IndexedDbCollection from './IndexedDbCollection';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import './IndexedDbViewer.css';


class IndexedDbViewer extends React.Component {
  static propTypes = {
  	/*dbAccessClass: PropTypes.shape({
  		getCollections:   PropTypes.func.isRequired,
  		getComponentName: PropTypes.func.isRequired,
  	}).isRequired,
  	*/
  }

  constructor(props) {
    super(props);
    this.state = {
    	selectCollection: '',
    	collections:      props.dbAccessClass.getCollections(),
    };
  }

  selectCollection = async (option) => {
  	const collectionName = option.value;
  	const collection = this.state.collections[collectionName]; 
  	const collectionFields = collection.fieldNames; 
  	const dbPrefix = this.props.dbAccessClass.getComponentName();
  	const dbCollection = new IndexedDbCollection(dbPrefix, collectionName,
  																							 collection.primaryField);
  	this.dbCollection = dbCollection;

  	const collectionMap = await dbCollection.getPrimaryKeyMap();
  	console.log(collectionMap);
  	this.setState({
  		selectedCollection: collectionName,
  		collectionFields,
  		collectionValues: Object.values(collectionMap || {})
  	});	
  }

  clearCollection = async () => {
		await this.dbCollection.updatePrimaryKeyMap({});
  	this.setState({collectionValues: []});	
  }

  render() {
    const collectionOptions = Object.keys(this.state.collections).map(collectionName => {
      return {
        value: collectionName,
        label: collectionName,
      };
    });
  	return (
  		<div style={{height: '100%', width: '100%'}}>
        <div className={classnames({'base-padding': true})} style={{height: '10%', width: '100%'}}>
          <label>Collections</label>
          <div style={{display: 'inline-block'}}>
	          <Dropdown
	            options={collectionOptions}
	            onChange={this.selectCollection}
	            value={this.state.selectedCollection}
	            placeholder="Select Map" />
	        </div>
	        { this.state.selectedCollection &&
	          <div style={{float: 'right', display: 'inline-block'}}>
							{this.state.selectedCollection}
		          <button
		            onClick={() => this.clearCollection()}>
		            Clear Collection             
		          </button>
	          </div>
	        }

        </div>
        <div className={classnames({'base-padding': true})} style={{height: '89%', width: '100%'}}>
	        <table >
			     <thead>
			       <tr>
			         {this.state.collectionFields && 
			         		this.state.collectionFields.map(field => {
				         		return (
				         			<th key={field} style={{'minWidth': '100px'}}>{field}</th>
				         		)	
				         	})
			          }
			       </tr>
			     </thead>
			     <tbody>
		         {this.state.collectionValues && 
		         		this.state.collectionValues.map(value => {
								  return (
								  	<tr>
				         			{this.state.collectionFields.map(fieldName => {
						         		return (
										      <td key={value[fieldName]} style={{'minWidth': '100px'}}>{value[fieldName]}</td>
						         		)	
				         			})}
										</tr>
									);
			         	})
		          }
			     </tbody>
			   </table>
	  		</div>
  		</div>
	 	);
  }
};
export default IndexedDbViewer;
