import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import TimelineLabel from './TimelineLabel';

import './Timeline.css';


class Timeline extends React.Component {
	static propTypes = {
    updateTimeFrameDate: PropTypes.func.isRequired,
	  name:                PropTypes.string.isRequired,
	  timeFrame:           PropTypes.object.isRequired,
    collapsed:           PropTypes.bool.isRequired,
    toggleTimeline:      PropTypes.func.isRequired,
	  barColor:            PropTypes.string,
	  events:              PropTypes.array,
    eventDraft:          PropTypes.object,
	}

	static defaultProps = {
	  barColor: 'white',
	  events:   [], 
	}

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleDateChange = (dateVar, date) => {
      this.props.updateTimeFrameDate(dateVar, date);
  };

  createDivForEvent = (event, timeFrameStartMs, timeFrameEndMs, color, zIndex) => {
    const eventStartMs = moment(event.timeFrame.startDate).valueOf();
    // End Date Defaults To Now If NOt provided

    const endDate = event.timeFrame.endDate ? event.timeFrame.endDate : new Date();
    const eventEndMs = moment(endDate).valueOf();
    // TODO: Add Dates To this tooltip or make makers visible or something
    const tooltipText = event.description;
    return createAbsoluteDivWithLength(eventStartMs, eventEndMs, timeFrameStartMs,
                                       timeFrameEndMs, event.timeFrame.color || color, zIndex, event.id,
                                       tooltipText)

  }

  render() {
  	const {
  		timeFrame,
      eventDraft,
  	} = this.props;
    const timeFrameStartMoment = moment(timeFrame.start, 'MM-DD-YYYY');
    const timeFrameEndMoment = moment(timeFrame.end, 'MM-DD-YYYY');
  	const timeFrameStartMs = timeFrameStartMoment.valueOf();
  	const timeFrameEndMs = timeFrameEndMoment.valueOf();
  	// const msDiff = timeFrameEndMs - timeFrameStartMs;
  	const eventDivs = this.props.events.filter(event => {
  		return (
        event.timeFrame &&
        (!eventDraft || !eventDraft.editedState.id ||
         event.id !== eventDraft.editedState.id)
      );
  	}).map(event => {
      return this.createDivForEvent(event, timeFrameStartMs, timeFrameEndMs,
                                    'white', 2);
  	});
    if (eventDraft && eventDraft.editedState.addTimeFrame &&
        eventDraft.editedState.timeFrame) {
      eventDivs.push(
        this.createDivForEvent(eventDraft.editedState, timeFrameStartMs,
                               timeFrameEndMs, 'green', 3)
      );
    }
  	// Get Year For Time Frame Start
  	// Find The Start Of The Next Decade
  	// Create Alternating Color Separators For The Decade 
  	const year = timeFrameStartMoment.format('YYYY');
  	const numDecades = Math.floor(Number(year) + 10);
  	const nextDecadeStart = moment(numDecades * 10, 'YYYY').startOf('year');
  	let counterStart = nextDecadeStart.clone();
  	const separatorDivs = [];
  	let index = 0;
  	while (counterStart.isBefore(timeFrameEndMoment)) {
  		const startInMs = counterStart.valueOf();
  		const endOfDecade = counterStart.clone().add(9, 'years').endOf('year');
  		const color = (index % 2 === 1) ? this.props.barColor : 'red'; 
      let endInMs = endOfDecade.valueOf();
      if (endOfDecade.isAfter(timeFrameEndMoment)) {
        endInMs = timeFrameEndMoment.valueOf();
      }
  		separatorDivs.push(createAbsoluteDivWithLength(startInMs, endInMs,
  																									 timeFrameStartMs,
                                                     timeFrameEndMs, color, 1,
                                                     index));
  		counterStart = endOfDecade.clone().add(20, 'days').startOf('year');
  		index += 1;
  	}
		const timelineStyle = {
			height:     '100%',
			width:      '100%',
			background: this.props.barColor,
			position:   'relative',
		};
    const classNamesOptions = {
      'timeline-container': true,
      collapsed:            this.props.collapsed,
    };
    return (
    	<div
        className={classnames(classNamesOptions)}
        style={{opacity: this.props.opacity}}>
      	<div onClick={this.props.toggleTimeline} className="header">
      		{this.props.name}	
      	</div>
        { !this.props.collapsed &&
          <div className="timeline-body">
            <div className="label-container">
            <TimelineLabel
              left={true}
              dateMoment={timeFrameStartMoment}
              handleDateChange={date => this.handleDateChange('start', date)}
            />
            <TimelineLabel
              right={true}
              dateMoment={timeFrameEndMoment}
              handleDateChange={date => this.handleDateChange('end', date)}
            />
            </div>
            <div style={timelineStyle}>
              {separatorDivs}
              {eventDivs}
            </div>  
          </div>
        } 
      </div>
    );
  }
};

function createAbsoluteDivWithLength(eventStartMs, eventEndMs, timeFrameStartMs,
																		 timeFrameEndMs, color, zIndex, key,
                                     eventTooltip) {
	const total = timeFrameEndMs - timeFrameStartMs; 
	let percent;
	if (eventStartMs < eventEndMs) {
		percent = ((eventEndMs - eventStartMs) / total) * 100;
	} else {
		percent = 1;
	}
	const leftPercentage = ((eventStartMs - timeFrameStartMs) / total) * 100;
	const style = {
		height:     '100%', 
		position:   'absolute',
		zIndex:     zIndex,
		left:       `${leftPercentage}%`,
		width:      `${percent}%`,
		background: color,
	};
  if (eventTooltip) {
    return (
      <div key={key} style={style} data-tip={eventTooltip} data-for={key + 'tooltip'}>
        <ReactTooltip id={key + 'tooltip'} />
      </div>
    )
  }
	return (
		<div key={key} style={style}>
		</div>
	)
}

export default Timeline;
