import { v4 as uuid } from 'uuid'; 

import SmartCard from './Interface/SmartCard';
import IndexedDbService from './../Services/IndexedDbService';


// Not Secure and stores information in Local Storage
class BrowserCardManager extends SmartCard {
  constructor(cardId) {
    super();
    /*
     * BrowserKey
    */
    this.cardId = cardId;
    this.BrowserKeyStorageEnum = 'BrowserKeys';
  }

  async createSigningKeyPair(name = '') {
    const keyPair = await this.generateKeyWithName(name, 'sign');
    await this.saveKeyWithId(keyPair.id, keyPair);
    try {
      await this.verifySigningAndVerifying(keyPair.id);
    } catch (e) {
      console.log(e);
      await this.deleteKeySetForIdentifier(keyPair.id); 
    }
    return keyPair;
  }


  async createEncryptingKeyPair(name = '') {
    const keyPair = await this.generateKeyWithName(name, 'encrypt');
    await this.saveKeyWithId(keyPair.id, keyPair);
    try {
      await this.verifyEncryptionAndDecryption(keyPair.id);
    } catch (e) {
      await this.deleteKeySetForIdentifier(keyPair.id); 
    }
    return keyPair;
  }

  async verifySigningAndVerifying(keyId) {
    const dataToSign = 'Testing';
    const signature = await this.sign(keyId, dataToSign);
    console.log('Signature');
    console.log(signature);
    const verified = await this.verifySignature(keyId, signature, dataToSign);
    console.log('Verified');
    console.log(verified);
  }


  async verifyEncryptionAndDecryption(keyId) {
    const originalMessage = 'Testing';
    const encryptdString = await this.encrypt(keyId, originalMessage);

    const decryptedMessage = await this.decrypt(keyId, encryptdString);
    console.log('Original Message');
    console.log(originalMessage);
    console.log('Processed Message');
    console.log(decryptedMessage);
    console.log('It Worked: ' + (originalMessage === decryptedMessage));
  }

  async getStoredKeyOfTypeForIdExportable(keyId, keyType) {
    const key = await this.getStoredKeyOfTypeForId(keyId, keyType);
    let exportableKey;
    if (keyType === 'public' && key.algorithm.name === 'ECDSA') {
      exportableKey = await crypto.subtle.exportKey('jwk', key);
    }
    return exportableKey;
  }

  async getStoredKeyOfTypeForId(keyId, keyType) {
    const allBrowserKeys = await this.getAllKeys();
    return allBrowserKeys[keyId][keyType + 'Key'];
  }

  getMessageEncoding(message) {
    const enc = new TextEncoder();
    return enc.encode(message);
  }

  decodeMessage(arrayBuffer) {
    const dec = new TextDecoder();
    return dec.decode(arrayBuffer);
  }

  async getKeyIdsWithNames() {
    const allBrowserKeys = await this.getAllKeys();
    return Object.values(allBrowserKeys).filter(key => {
      return key.cardId === this.cardId;  
    }).map(key => {
      return {name: key.name, id: key.id, type: key.type};  
    });
  }

  // Method Overrides 
	// Returns a list of identifiers for keys
  async getKeyIdentifiers() {
    const allBrowserKeys = await this.getAllKeys();
    return Object.values(allBrowserKeys).filter(key => {
      return key.cardId === this.cardId;  
    }).map(key => {
      return key.id;  
    });
  }
  // Import a private key into NitroHSM and store it under the KeyID path.
  // The public key will be automatically derived.
  // Will Generate Public Key
  savePrivateKeyWithIdentifier(keyId, privateKey) {

  }
  // Delete a pair of public and private key.
  async deleteKeySetForIdentifier(keyId) {
    const allBrowserKeys = await this.getAllKeys();
    delete allBrowserKeys[keyId];
    return this.saveBrowserKeys(allBrowserKeys);
  }

  async decrypt(keyId, encryptedString) {
    const privateKey = await this.getStoredKeyOfTypeForId(keyId, 'private');

    const decryptedString = await window.crypto.subtle.decrypt(privateKey.algorithm,
                                                               privateKey,
                                                               encryptedString);
    return this.decodeMessage(decryptedString);
  }

	// Get Public key and encrypt the string 
  async encrypt(keyId, string) {
    const encodedMessage = this.getMessageEncoding(string);
    const publicKey = await this.getStoredKeyOfTypeForId(keyId, 'public');
    return window.crypto.subtle.encrypt(publicKey.algorithm, publicKey,
                                        encodedMessage);
  }

  async sign(keyId, data) {
    const dataString = data.toString();
    const encodedMessage = this.getMessageEncoding(dataString);
    const privateKey = await this.getStoredKeyOfTypeForId(keyId, 'private');
    const signingAlgorithm = {
      name: 'ECDSA',
      hash: 'SHA-512', 
    };
    return window.crypto.subtle.sign(signingAlgorithm, privateKey,
                                     encodedMessage);
  }

  async verifySignature(keyId, signature, data) {
    const dataString = data.toString();
    const encodedMessage = this.getMessageEncoding(dataString);
    const publicKey = await this.getStoredKeyOfTypeForId(keyId, 'public');
    const signingAlgorithm = {
      name: 'ECDSA',
      hash: 'SHA-512', 
    };
    return window.crypto.subtle.verify(signingAlgorithm, publicKey,
                                       signature, encodedMessage);
  }
  // Private Methods
  async saveKeyWithId(keyId, key) {
    const allBrowserKeys = await this.getAllKeys();
    allBrowserKeys[keyId] = key;
    return this.saveBrowserKeys(allBrowserKeys);
  }

  async getAllKeys() {
    return IndexedDbService.getFromIndexDBStoreOrDefault(this.BrowserKeyStorageEnum,
                                                         {});
  }

  async saveBrowserKeys(browserKeys) {
    return IndexedDbService.saveInIndexDBStore(this.BrowserKeyStorageEnum,
                                               browserKeys);
  }
  // RSA or eliptic curve keys and accept a type
  /* 
    return {publicKey, privateKey}
  */
  async generateKeyWithName(keyName, type) {
    let keyPair;
    if (type === 'encrypt') {
      keyPair = await window.crypto.subtle.generateKey(
        {
          name:           'RSA-OAEP',
          modulusLength:  4096,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash:           'SHA-256'
          /*
          name: "ECDSA",
          namedCurve: "P-384"
          */
        },
        true,
        ['encrypt', 'decrypt']
        // TODO: Look into what these are, may be useful
        // deriveKey, deriveBits, wrapKey, wunwrapKey
      );
    } else if (type === 'sign') {
      keyPair = await window.crypto.subtle.generateKey(
        {
          name:       'ECDSA',
          namedCurve: 'P-384',
        },
        true,
        ['sign', 'verify']
      );
    }
    console.log(keyPair);
    return {
      cardId:     this.cardId,
      type:       type, 
      id:         uuid(),
      publicKey:  keyPair.publicKey,
      privateKey: keyPair.privateKey,
      name:       keyName,
    };
  }
}

export default BrowserCardManager;
