import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CommonClassMethods from './../../../Shared/Utilities/CommonClassMethods';
import UrlServiceClient from './../../../Shared/Network/UrlService/UrlServiceClient';
import ExpandableTextArea from './../../../Shared/FormComponents/TextInputs/ExpandableTextArea/ExpandableTextArea';
import NoteHeader from './NoteHeader';

import wrapperWithDimensions from './../../../Shared/HOC/wrapperWithDimensions';

import Api from './../../../MockApi/Api';

// import './LinkBasedStoryForm.css';
import './../../../Shared/FormComponents/Labels/Labels.css';
import './../../../Shared/CSS/ParentChild.css';

const NoteType = {
  FREE_TEXT: 'FREE_TEXT',
  QUOTE:     'QUOTE',
};


class AddNoteForm extends React.Component {
  static propTypes = {
    exitForm: PropTypes.func,
    sourceId: PropTypes.string,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      noteType: NoteType.FREE_TEXT,
    };
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

	componentDidMount() {
    this.urlServiceClient = new UrlServiceClient();
	}

  async componentWillUnmount() {
    await this.urlServiceClient.destroy();
  }

  prepareForNewNote = () => {
    const stateReset = {
      noteSubmitted: false,
      noteText:      '',
    };
    this.setState(stateReset);
  }

  findOrCreateNote = async () => {
    const noteText = this.state.noteText;
    // const response = 
    await Api.findOrCreateNoteWithText(noteText);
    const updatedState = {
      noteSubmitted: true,
    };
    this.setState(updatedState);
  }

  render() {
    const {
      exitForm,
      sourceId,
    } = this.props;
    const headerPxHeight = 53;
    let mapPxHeight = this.props.parentHeight - headerPxHeight;
    const noteTypes = [
      {
        value: NoteType.FREE_TEXT,
        name: 'Free Text',
      },
      {
        value: NoteType.QUOTE,
        name: 'Quote',
      },
    ];
    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}>
        <div className='header' style={{height: `${headerPxHeight}px`}}>
          <NoteHeader 
            selectedNoteType={this.state.noteType}
            noteTypeSelected={noteType => this.updateStringField('noteType', noteType)}
            noteTypes={noteTypes}
            />
        </div>

          <div className={classnames({
            'fill-parent-width':     true,
            'fill-parent-height':     true,
          })}
          style={{height: `${mapPxHeight}px`}}>
        <div
	        className={classnames({
	        	'turquoise-border':   true,
	          'half-parent-height': true,
	          'fill-parent-width':  true,
            'base-padding':       true,
	        })}
          > 
          <div className={classnames({
            'fill-parent-width':     true,
            'fill-parent-height':     true,
          })}
          > 
            { sourceId &&
              <button
                style={{float: 'left'}}
                onClick={exitForm}>
                Back 
              </button>
            }

            <label className="block-label">
            	Entered Note
              { this.state.noteSubmitted &&
                <button
                  style={{float: 'right'}}
                  onClick={this.prepareForNewNote}>
                  Clear 
                </button>
              }
            </label>
            { !this.state.noteSubmitted &&
              <ExpandableTextArea
                className={classnames({'word-break-all': true})}
                value={this.state.noteText} 
                updateValue={noteText => this.updateStringField('noteText', noteText)}
              />
            }
            { !this.state.noteSubmitted &&
              <button
                onClick={this.findOrCreateNote}>
                Create Note 
              </button>
            }
            { this.state.noteSubmitted &&
              <div className={classnames({
                'fill-parent-width':     true,
              })}>
                <div className={classnames({
                  'word-break-all': true,
                })}
                >{this.state.noteText}</div>
              </div>
            }
          </div>
        </div>
        <div
	        className={classnames({
	        	'green-border':       true,
            'half-parent-height': true,
            'fill-parent-width':  true,
            'align-children-top': true,
            'parent-with-bordered-horizontal-children': true,
	        })}
	        > 
          <div
            className={classnames({
              'fill-parent-height': true,
              'half-parent-width':  true,
              'base-padding':       true,
              'inline-display':     true, 
            })}
            > 
            {sourceId &&
              <div>
                {sourceId}
              </div>
            } 
          </div>
          <div
            className={classnames({
              'fill-parent-height': true,
              'half-parent-width':  true,
              'base-padding':       true,
              'inline-display':     true, 
            })}
            > 
            { this.state.noteSubmitted &&
              <label className="block-label">Next Steps</label>
            }
          </div>
        </div>
        </div>
      </div>
    );
  }
};

export default wrapperWithDimensions(AddNoteForm);
