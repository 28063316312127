import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartCardService from './../Services/SmartCardService';
import NetworkManager from './../Services/NetworkManager';

import SlidingUpPane from './../Shared/SlidingPane/SlidingUpPane';
import IndexedDbViewer from './../Shared/IndexedDb/IndexedDbViewer';
import LedgerServer from './../Shared/Network/Ledger/LedgerServer';
import IdentityProviderServer from './../Shared/Network/IdentityProvider/IdentityProviderServer';
import TapestryServer from './../Shared/Network/Tapestry/TapestryServer';

import BrowserStorage from './../Shared/Utilities/BrowserStorage';
import CommonClassMethods from './../Shared/Utilities/CommonClassMethods';

import SmartCardManager from './../Shared/HardwareManager/SmartCardManager/SmartCardManager';

import Api from './../MockApi/Api.js';

import IdentityProviderBrowserExt from './../IPBrowserExtension/IdentityProviderBrowserExt';

import TapestryLogin from './TapestryUIWrapper/TapestryLogin';
import AuthenticatedWrapper from './AuthenticatedWrapper';

import './TapestryUIWrapper.css';

class TapestryUIWrapper extends React.Component {
  static propTypes = {
    tabId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      smartKeysSet:                      false,
      authenticatedWithIdentityProvider: false,
      selectedKeys:                      {},
      authenticationFormType:            '', 
      showDevTools:                      false,
      identityProviderPreviewOpen:       false,
      tapestryPreviewOpen:               false,
      ledgerPreviewOpen:                 false,
      showIdentityProvider:              true,
      partyId:                           false,          
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
    this.updateStateFieldWithValue = CommonClassMethods.updateStateFieldWithValue.bind(this);
    this.updateStringField = CommonClassMethods.updateStringField.bind(this);
  }

  componentDidMount() {
    this.networkManager = new NetworkManager('tapestry_ui');
    this.networkManager.connectToNetworkWithName(() => {});
    this.setListenerForKeySelection();
    this.networkManager2 = new NetworkManager('daml');
    this.networkManager2.connectToNetworkWithName(() => {});
    const rememberMe = BrowserStorage.local.getItemOrDefault('tapestry_rememberMe', false);
    if (rememberMe) {
      const userId = BrowserStorage.local.getItemOrDefault('userId', null);
      if (userId) {
        this.loggedInWithUserId(userId, true);
      }
    }
  }

  setListenerForKeySelection() {
    SmartCardService.setHandlerForKeyChanges('tuic_selectedKeys', () => {
      const selectedKeys = SmartCardService.getSelectedKeys();
      const smartKeyMissing = Object.values(selectedKeys).some(key => {
        return !key.keyId;
      });
      this.setState({selectedKeys, smartKeysSet: !smartKeyMissing });
    }); 
    const selectedKeys = SmartCardService.getSelectedKeys();
    const smartKeyMissing = Object.values(selectedKeys).some(key => {
      return !key.keyId;
    });
    this.setState({selectedKeys, smartKeysSet: !smartKeyMissing });
  }

  componentWillUnmount(){
    SmartCardService.removeHandlerForKeyChanges('tuic_selectedKeys');
  }

  loggedInWithUserId = async (userId, localStorageLogin = false) => {
    await Api.login(userId, this.props.tabId);
    const rememberMe = BrowserStorage.local.getItemOrDefault('tapestry_rememberMe', false);
    if (rememberMe && !localStorageLogin) {
        BrowserStorage.local.setItem('userId', userId);
    }
    // Retrieve Tab State
    // Look To See If Tab Tool Type === WORKSPACE || SOURCE_LIBRARY
    // Or None
    this.setState({userId, showIdentityProvider: false});
  }

  logout = async () => {
    await Api.logout();
    BrowserStorage.local.setItem('userId', null);
    this.setState({userId: '', showIdentityProvider: true});
  }

  attemptToRegisterUsername = async () => {
    // Contact Identity Provider
    // Send Username and Public Key
    const exportableKey = await SmartCardService.getPublicSigningKey();
    const payload = {
      recipient: 'identity_provider',
      action:    'register',
      publicKey: exportableKey,
      username:  this.state.username,
    };
    const signature = await SmartCardService.signPayload(payload);
    const message = {
      payload, 
      signature,
    };
    const {
      userExists,
      registrationSuccessful,
    } = await this.networkManager.messageIdentityProvider(message);
    if (userExists) {
      console.log('User Exists');
    } else if (registrationSuccessful) {
      console.log('');
    }
  }

  usbTest = () => {
    navigator.usb.requestDevice({ filters: [] })
    .then(device => {
      console.log(device);
      return this.setupDevice(device);
    })
    .then(() => {
      return this.write();
    })
    .then(() => {
      return this.read();
    })
    .catch(error => { console.error(error)});
  }

  write = () => {
    const string = 'test';
    var encoder = new TextEncoder();
    var data = encoder.encode(string);
    this.device.transferOut(1, data)
    .catch(error => { console.log(error); })

  }

  read = () => {
    this.device.transferIn(1, 100)
    .then(readResponse => {
      console.log(readResponse);
    })
    .catch(error => { console.log(error); })
  }

  setupDevice(device) {
    return device.open()
    .then(() => device.selectConfiguration(1))
    .then(() => device.claimInterface(0))
    .then(() => {
      this.device = device;
    });
  }


  // TODO: Login and Invitation Redemption are part of the connected use of the 
  //       application. There might be a need to provide a disconnected use of
  //       the platform that saves everything to local storage or allows people
  //       to run their own instance of teh server to connect to.
  // Look For Smart Card Id
  // Generate Smart Card (Prototype Functionality to postpone smart card integration work)
  render() {
    let visibleChild;
    if (!this.state.userId) {
      visibleChild = (
        <TapestryLogin 
          smartKeysSet={this.state.smartKeysSet}
          authenticatedWithIdentityProvider={this.state.authenticatedWithIdentityProvider}
          loggedInWithUserId={this.loggedInWithUserId}
        />
      );
    } else {
      visibleChild = (
        <AuthenticatedWrapper
          userId={this.state.userId}
          tabId={this.props.tabId}
          logoutHandler={this.logout}
        />
      );
    }
    return (
      <div className={classnames({
          'black-background':   true,
          'white-text':         true, 
          'map-timeline-tool':  true,
          'base-padding':       true,
          'fill-parent-height': true,
          'align-children-top': true,
        })}>
        <div className={classnames({
            'tapestryWrapper':    true,
            'devManagerVisible':  this.state.showIdentityProvider,
            'inline-display':     true,
            'fill-parent-height': true,
          })}>
          {visibleChild}
        </div>
        {/*
          <div
            style={{position: 'absolute', 'right': '0px', 'top': '0px'}}
           >
            <button
              style={{float: 'right'}}
              onClick={() => this.toggleVar('showIdentityProvider')}>
              {this.state.showIdentityProvider && 'Hide' || 'Show'} 
            </button>
          </div>

        */}
        <div className={classnames({
            'devManager':         true,
            'devManagerVisible':  this.state.showIdentityProvider,
            'inline-display':     true,
            'fill-parent-height': true,
          })}>
          <SmartCardManager
          />
          <div className={classnames({
            })} style={{height: '50%'}}>
            <IdentityProviderBrowserExt 
              showDevTools={this.state.showDevTools}
              smartKeysSet={this.state.smartKeysSet}
              updateIdentityProviderState={
                value => this.updateStateFieldWithValue('authenticatedWithIdentityProvider', value)
              }
            />
          </div>
        </div>
        <div
          style={{position: 'absolute', 'right': '200px', 'bottom': '0px'}}
         >

         {/*
          <button
            style={{float: 'right'}}
            onClick={() => this.toggleVar('showDevTools')}>
            Toggle Dev Tools 
          </button>
          <button
            style={{float: 'right'}}
            onClick={() => this.usbTest('showDevTools')}>
            Usb Test 
          </button>
         */}
        </div>
        {this.state.showDevTools &&
          <>
            <div
              style={{position: 'absolute', 'bottom': '0px'}}
             >
              <button
                style={{float: 'left'}}
                onClick={() => this.toggleVar('identityProviderPreviewOpen')}>
                Identity Provider Preview 
              </button>
              <button
                style={{float: 'left'}}
                onClick={() => this.toggleVar('ledgerPreviewOpen')}>
                Ledger Preview 
              </button>
              <button
                style={{float: 'left'}}
                onClick={() => this.toggleVar('tapestryPreviewOpen')}>
                Tapestry Preview             
              </button>
            </div>
            <SlidingUpPane
              title="Ledger Preview"
              isPaneOpen={this.state.ledgerPreviewOpen}
              closePaneHandler={() => this.toggleVar('ledgerPreviewOpen')}>
              <IndexedDbViewer
                dbAccessClass={LedgerServer}
              />
            </SlidingUpPane>
            <SlidingUpPane
              title="Tapestry Preview"
              isPaneOpen={this.state.tapestryPreviewOpen}
              closePaneHandler={() => this.toggleVar('tapestryPreviewOpen')}>
              <IndexedDbViewer
                dbAccessClass={TapestryServer}
              />
            </SlidingUpPane>
            <SlidingUpPane
              title="Identity Provider Preview"
              isPaneOpen={this.state.identityProviderPreviewOpen}
              closePaneHandler={() => this.toggleVar('identityProviderPreviewOpen')}>
              <IndexedDbViewer
                dbAccessClass={IdentityProviderServer}
              />
            </SlidingUpPane>
          </>
        }
      </div>
    );
  }
};

export default TapestryUIWrapper;
