import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartCardService from '../../../Services/SmartCardService';
import Icon from './../../../Shared/Icons/Icon';
import DropdownCaretWithTooltip from './../../../Shared/Buttons/DropdownCaretWithTooltip';

function KeySetWithCard(props) {
	const {
  	cardId,
  	keyId,
  	keyType,
  	cardName,
  	keyName,
  	expanded,
  	toggleExpand,
	} = props;
	if (!expanded) {
    return (
    	<div
	    	className={classnames({
	      	'small-label':        true,
	      	'fill-parent-height': true,
	      	'fill-parent-width':  true,
	      	'background-success': keyId, 
	      	'background-error':   !keyId,
	      })}>
	      <Icon iconType="key"/>
	      {(keyType === 'encrypt' && 'Encrypt') || 'Sign'} Key Set &nbsp;&nbsp;
	      <DropdownCaretWithTooltip
	      	expanded={expanded}
	      	toggleExpand={toggleExpand}
	      	expandTooltip="Show Key Details"
	      />
	    </div>
	  );
	}
	return (
		<div className={classnames({
			'top-margin': true,
			'rounded-box-border': true,
			'green-border': !!keyId,
			'red-border': !keyId,
		})}>
			<div>
				{(keyType === 'encrypt' && 'Encryption') || 'Signing'} Key Set &nbsp;&nbsp;
	      <DropdownCaretWithTooltip
	      	expanded={expanded}
	       	toggleExpand={toggleExpand}
	       	collapseTooltip="Hide Key Details"
	      />
			</div>
			{ (!cardName && cardId) &&
				<div>
					<Icon iconType="card" color="green"/>Card Id: {SmartCardService.getDisplayId(cardId)}
				</div>
			}
			{ (!keyName && keyId) &&
				<div>
					<Icon iconType="key"/>Key Set Id : {SmartCardService.getDisplayId(keyId)}
				</div>
			}
		</div>
 	);
}

KeySetWithCard.propTypes = {
	expanded:      PropTypes.bool.isRequired,
	toggleExpand:  PropTypes.func.isRequired,
	keyType:       PropTypes.string.isRequired,
	cardId:        PropTypes.string,
	keyId:         PropTypes.string,
	cardName:      PropTypes.string,
	keyName:       PropTypes.string,
};

export default KeySetWithCard;
