
class SmartCard {
	// Returns a list of identifiers for keys
  getKeyIdentifiers() {

  }
  // Import a private key into NitroHSM and store it under the KeyID path.
  // The public key will be automatically derived.
  // Will Generate Public Key
  savePrivateKeyWithIdentifier(keyId, privateKey) {

  }
  // Delete a pair of public and private key.
  deleteKeySetForIdentifier(keyId) {

  }

  decrypt(keyId, encryptedString) {

  }

	// Get Public key and encrypt the string 
  encrypt(keyId, string) {

  }

  sign(keyId) {

  }
}

export default SmartCard;
