import NetworkComponent from './../NetworkComponent';

class IdentityProviderClient extends NetworkComponent {

	constructor(uiHandlers = {}) {
		super('identity_provider_client');
		this.server = 'identity_provider_server';
		this.tapestry_client = 'tapestry_client';
		const handlers = {};
		handlers.getUserIdFromIdentityProvider = this.getUserIdFromIdentityProvider.bind(this);
		super.connectToNetworkWithHandlers(handlers);
		this.uiHandlers = uiHandlers;
	}

	async registerPublicKeyWithUsername(body) {
		const recipient = this.server;
		const requestType = 'registerPublicKeyWithUsername';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Registration Failed');
		}
		return;
	}

	async loginWithUsername(body) {
		const recipient = this.server;
		const requestType = 'loginWithUsername';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Login Failed');
		}
		return response.data;
	}

	async requestUserIdForService(body) {
		const recipient = this.server;
		const requestType = 'requestUserIdForService';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Request Failed');
		}
		return response.data;
	}

	async registerWithTapestryService(body) {
		const recipient = this.server;
		const requestType = 'registerWithTapestryService';
		const response = await this.sendRequest(recipient, requestType, body);
		if (response.msg !== 'success') {
			throw new Error('Registration Failed');
		}
		return;
	}

	/*
	 * TODO: Associate name to party and identity provider services
	 * body = {
	 *   signature: String, //Signed By Service
	 *   payload:   {
	 *     nonce:       String,
	 *     serviceName: String,
	 *   },
	 * },
	*/
	async getUserIdFromIdentityProvider(body) {
		const {
			payload,
		} = body;
		let response;
    try {
			const {
				nonce,
				serviceName,
			} = payload; 
			response = await this.uiHandlers.receiveAuthenticationRequestForService(serviceName,
																																	            nonce);
	    return {msg: 'success', payload: response};
    } catch (e) {
    	console.log('Registration Failed');
	    return {msg: 'error'};
    }
	}	


	destroy() {
	  super.disconnectFromNetwork();	
	}
}

export default IdentityProviderClient;
