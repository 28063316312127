const TimeFrameType = {
  HAS_END_DATE:  'hasEndDate',
  ONGOING_EVENT: 'ongoingEvent',
  IS_INSTANT:    'isInstant',
};

const CommonColloquialDateRanges = {
	TODAY_IN_TZ:         'TODAY_IN_TZ',
	YESTERDAY_IN_TZ:     'YESTERDAY_IN_TZ',
	CALENDAR_WEEK_IN_TZ: 'CALENDAR_WEEK_IN_TZ',
	LAST_7_DAYS:         'LAST_7_DAYS',
};

const SourceType = {
	LINK: 'LINK',
};
const DraftType = {
	EVENT: 'event',
};
const ToolType = {
	WORKSPACE:           'WORKSPACE',
	WORKSPACE_MANAGER: 'WORKSPACE_MANAGER',
	SOURCE_LIBRARY:      'SOURCE_LIBRARY',
};
export {
	CommonColloquialDateRanges,
	DraftType,
	TimeFrameType,
	ToolType,
	SourceType,
};
