import StoryViewConfigService from './LocalDB/UserExperience/StoryViewConfigService';
import UserTabStateService from './LocalDB/UserExperience/UserTabStateService';
// Unverified
import AuthService from './AuthService';

import EventService from './LocalDB/AtomicContent/EventService';
import DraftService from './LocalDB/UserExperience/DraftService';
import UserNoteService from './LocalDB/UserPerspective/UserNoteService';
import UserSourceService from './LocalDB/UserPerspective/UserSourceService';
import StoryService from './LocalDB/UserPerspective/StoryService';
import WorkspaceService from './LocalDB/UserExperience/WorkspaceService';

const importConfigs = [
	{
		service: DraftService,
		imports: [
			'addMapFeatureIdToEventDraftLocation',
			'createEventDraft',
			'deleteDraftWithId',
			'getDraftWithId',
			'removeEventDraftLocationGroupFromInter',
			'removeMapFeatureIdFromEventDraftLocation',
			'removeFeatureGroupFromEventDraftLocation',
			'setEventDraftLocationGroupWithHash',
			'updateDraftBooleanField',
		  'updateDraftStringField',
		  'updateDraftTimeFrameField',
			'updateLocationFeatureGroupMap',
		  'updateLocationFeatureGroupColor',
		],
	},
	{
		service: EventService,
		imports: [
			'createEvent',
			'updateEventWithId',
			'updateEventWithIdFromDraft',
			'createEventFromDraft',
		],
	},
	{
		service: UserNoteService,
		imports: [
			'findOrCreateNoteWithText',
			'getAllUserNotes',
		  'getUserNotesWithSearchParameters',
		],
	},
	{
		service: UserSourceService,
		imports: [
			'findOrCreateSourceWithLink',
			'getAllUserSources',
		  'getUserSourcesWithSearchParameters',
		],
	},
	{
		service: StoryService,
		imports: [
			'addEventIdToStory',
			'addMapPathToStory',
			'createStory',
			'deleteStoryWithId',
			'removeEventIdFromStory',
			'removeMapPathFromStory',
		],
	},
	{
		service: StoryViewConfigService,
		imports: [
			'createStoryViewConfig',
			'getStoryViewConfig',
			'getViewConfigForStoryId',
			'updateTimeFrameFieldOfType',
		],
	},
	{
		service: UserTabStateService,
		imports: [
			'createUserTabState',
			'getUserTabState',
			'setEventDraftForTabState',
			'setSelectedStoryForTabState',
		  'setStoryViewConfigForTabState',
		  'setNetworkState',
		  'editToolTypeForUserTabState',
		],
	},
	{
		service: WorkspaceService,
		imports: [
			'addStoryIdToWorkspace',
			'removeStoryIdFromWorkspace',
			'getDefaultWorkspaceId',
			'getLatestWorkspaceHashWithId',
		],
	},
];

const exportObj = {
	login,
	logout,
};

importConfigs.forEach(importConfig => {
	addImportsFromService(importConfig.service, importConfig.imports, exportObj);	
});

function addImportsFromService(service, imports, exportObj) {
	imports.forEach(functionName => {
		try {
			exportObj[functionName] = service[functionName].bind(service);	
		} catch (e) {
			throw Error(`${functionName} missing From Service`);
		}
	});
}

// Temporary there is no login purpose and currently just checks to see if the
// computer has ever gone to the site
async function login(userId, tabId) {
	// Create default workspace
	const existingWorkspace = await WorkspaceService.defaultWorkspaceExists(userId);
	if (!existingWorkspace) {
		const workspace = await WorkspaceService.createWorkspace(userId,
																														 'Default Workspace',
																														 {isDefault: true});
		await UserTabStateService.createUserTabState(userId, tabId, workspace.id);
	} else {
		const defaultWS = await WorkspaceService.getDefaultWorkspace(userId);
		if (defaultWS.tab !== tabId) {
			await UserTabStateService.createUserTabState(userId, tabId,
																									 defaultWS.id);
		}
	}
	AuthService.setUserId(userId);
	return {
		userId: userId,
	};
} 

async function logout() {
	AuthService.setUserId('');
} 

export default exportObj;
