import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import './ToolTypeSelector.css';
import WorkspaceManagerHeader from './WorkspaceManagerHeader';


import wrapperWithDimensions from './../../Shared/HOC/wrapperWithDimensions';

class WorkspaceManager extends React.Component {
  static propTypes = {
    goToChooseAWorkspace: PropTypes.func.isRequired,
    goToSourceLibrary:    PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

	componentDidMount() {
	}

  async componentWillUnmount() {
  }

  render() {
    const headerPxHeight = 53;
    // const messageHeaderPxHeight = 53;
  	let mapPxHeight = this.props.parentHeight - headerPxHeight;
    return (
      <div
        className={classnames({
          'fill-parent-height': true,
          'fill-parent-width':  true,
        })}>
        <div className='header' style={{height: `${headerPxHeight}px`}}>
        	<WorkspaceManagerHeader />
        </div>
        <div 
          className={classnames({
            'fill-parent-width':  true,
          })}
          style={{height: `${mapPxHeight}px`}}>
	        <div
		        className={classnames({
		        	'clickable':             true,
		        	'grow-on-hover':         true,
		        	'turquoise-border':      true,
		          'fill-parent-height':    true,
		          'half-parent-width':     true,
		        	'vertically-align-text': true,
		        })}
	          onClick={this.props.goToChooseAWorkspace}
		        > 
	        	Create A Workspace With A Map	
	        </div>
	        <div
		        className={classnames({
		        	'clickable':             true,
		        	'grow-on-hover':         true,
		        	'green-border':          true,
		          'fill-parent-height':    true,
		          'half-parent-width':     true,
		        	'vertically-align-text': true,
		        })}
	          onClick={this.props.goToSourceLibrary}
		        > 
		        Create A Workspace With A Chart 
	        </div>
        </div>
      </div>
    );
  }
};

export default wrapperWithDimensions(WorkspaceManager);
