import React from 'react';
import PropTypes from 'prop-types';

import MultiSelectableList from './../../Common/List/MultiSelectableList';
import ScrollableWithHeader from './../../Common/ScrollableWithHeader/ScrollableWithHeader';
import MapServiceClient from './../../../Shared/Network/MapService/MapServiceClient';

import './MapSelection.css';


class MapSelection extends React.Component {
  static propTypes = {
    addMapPath:        PropTypes.func.isRequired,
    removeMapPath:     PropTypes.func.isRequired,
    closeMapSelection: PropTypes.func.isRequired,
    selectedMapPaths:  PropTypes.array,
  }

  static defaultProps = {
    selectedMapPaths: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      parentHeight: 0,
      options: [],
    };
  }

  componentDidMount() {
    this.mapServiceClient = new MapServiceClient();
  }

  async componentWillUnmount() {
    await this.mapServiceClient.destroy();
  }


  updateParentHeight = parentHeight => {
    this.setState({parentHeight}); 
  }

  searchStringUpdated = (options, newSearchString) => {
    return this.getOptionsFromSearchString(newSearchString); 
    /*
    */
  };

  getOptionsFromSearchString = async (searchString) => {
    if (!searchString) {
      console.log('Nothing');
      return [];
    }
    /*
    const stateReset = {
      openGraphFailed: false,
      urlSubmitted:    true,
    };
    await this.toggleVar('checkingUrl', stateReset);
    */
    const body = {payload: {searchString}};
    const response = await this.mapServiceClient.requestMapOptionsThatMatchSearchString(body);
    // const updatedState = {};
    if (response.success) {
      return response.optionNames;
    } else {
      return [];
    }
  }
  render() {
    console.log(this.props.selectedMapPaths);
  	return (
      <ScrollableWithHeader 
        headerTitle="Selecting Map For Story"
        headerButtonHandler={this.props.closeMapSelection}
        headerButtonName="Close"
        updateHeight={this.updateParentHeight}
        noScrolling={true}
      >
        { this.state.parentHeight &&
          <MultiSelectableList
            selectOption={this.props.addMapPath}
            unselectOption={this.props.removeMapPath}
            options={this.state.options}
            parentHeight={this.state.parentHeight - 55}
            searchFunction={this.searchStringUpdated}
            selectedOptions={this.props.selectedMapPaths}
            optionsTitle="Available Maps"
          />
        }
      </ScrollableWithHeader>
	 	);
  }
};
export default MapSelection;
