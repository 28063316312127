import React from 'react';
import classnames from 'classnames';

import CommonClassMethods from './../../Shared/Utilities/CommonClassMethods';

// import './AccountHeader.css';

class WorkspaceManagerHeader extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
    };
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  async componentDidUpdate(prevProps) {
  }

  render() {
  	return (
  		<div className="account-header">
  			<div
          className={classnames({
            'fill-parent-height': true,
            'align-children-top': true,
          })}
          style={{float: 'left'}}>
          No Workspaces Yet
      	</div>
  		</div>
	 	);
  }
};
export default WorkspaceManagerHeader;
