import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import EditActionButton from '../../Common/Button/EditActionButton';
import ExpandableTextarea from '../../Common/Textarea/ExpandableTextarea';
import LimitedTextarea from '../../Common/Textarea/LimitedTextarea';
import DisplayTimeFrame from '../../Common/TimeFrame/DisplayTimeFrame';

import CommonClassMethods from '../../../Shared/Utilities/CommonClassMethods';

import {TimeFrameType} from '../../../Shared/TapestryConstants/Enums';
import {DisplayTimeFrameType} from '../../../Shared/TapestryConstants/DisplayText';

import Api from '../../../MockApi/Api';

import 'react-datepicker/dist/react-datepicker.css';
import './EditEventForm.css';

// TODO: Consider Draft Model That Exists Locally to Save Progress On These 
// creations
// TODO: Move To Service
class EditEventForm extends React.Component {
  static propTypes = {
    eventDraftId:            PropTypes.string.isRequired,
    showMapFeatureGroupForm: PropTypes.func.isRequired,
    saveEventChangesHandler: PropTypes.func.isRequired,
    createEventHandler:      PropTypes.func.isRequired,
    hideForm:                PropTypes.func.isRequired,
    mapPaths:                PropTypes.arrayOf(PropTypes.string),
  	storyId:                 PropTypes.string,
  	event:                   PropTypes.object,
  }

  static defaultProps = {
    mapPaths: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      addTimeFrame:          false,
      startDate:             new Date(),
      endDate:               null,
      showTimeFrame:         true,
      showLocation:          true,
      selectedTimeFrameType: TimeFrameType.HAS_END_DATE,
      selectedMapPath:       null,
      locations:             [],
    };
    if (props.event) {
      this.state.description = props.event.description;
      if (props.event.source) {
        if (props.event.source.type === 'LINK') {
          this.state.sourceLink = props.event.source.uri;
        } 
      } else if (props.event.sourceLink) {
        this.state.sourceLink = props.event.sourceLink;
      }
      if (props.event.locations) {
        this.state.locations = props.event.locations;
      }
      this.state.addTimeFrame = props.event.addTimeFrame;
      this.state.showTimeFrame = props.event.showTimeFrame;
      if (props.event.timeFrame) {
        this.state.timeFrameColor = props.event.timeFrame.color;
        this.state.selectedTimeFrameType = props.event.timeFrame.type;
        this.state.startDate = this.parseDateIfNecessary(props.event.timeFrame.startDate);
        this.state.endDate = this.parseDateIfNecessary(props.event.timeFrame.endDate);
      }
    }
    this.toggleVar = CommonClassMethods.toggleVar.bind(this);
  }

  parseDateIfNecessary = date => {
    if (!date) {
      return null; 
    } else if (typeof date === 'string') {
      return Date.parse(date); 
    } else {
      return date; 
    } 
  } 
  verifyAndFormatEventDraft = async event => {
    if (!event.description) {
      throw new Error('Description Required');
    }
    if (event.sourceLink) {
      const source = await Api.createSourceWithLink(event.sourceLink);
      event.source = source.id;
      delete event.sourceLink;
    } else if (typeof event.source === 'object') {
      event.source = event.source.id;
    }
    return event;
  }
  submitForm = async () => {
    const eventDraft = await Api.getDraftWithId(this.props.eventDraftId);
    let formattedEvent;
    try {
      formattedEvent = await this.verifyAndFormatEventDraft(eventDraft.editedState); 
    } catch (e) {
      console.log(e);
      return;
    }
  	if (!this.props.event.id) {
	    const newEvent = await Api.createEventFromDraft(formattedEvent);
	    this.props.createEventHandler(newEvent.id);
  	} else {
      console.log(formattedEvent);
      await Api.updateEventWithIdFromDraft(this.props.event.id, formattedEvent);
      this.props.saveEventChangesHandler(this.props.event.id);
  	}
  }

  handleDateChange = (dateVar, date) => {
    const updatedState = {};
    updatedState[dateVar] = date;
    this.setState(updatedState, async () => {
      const eventDraftId = this.props.eventDraftId;
      await Api.updateDraftTimeFrameField(eventDraftId, dateVar, date)
      this.props.eventDraftUpdated();
    });
  };

  toggleTimeFrame = async fieldName => {
    if (!this.state.addTimeFrame && !this.state.timeFrameColor) {
      await this.toggleVar(fieldName, {timeFrameColor: 'green'});
      const eventDraftId = this.props.eventDraftId;
      await Api.updateDraftTimeFrameField(eventDraftId, 'color',
                                          this.state.timeFrameColor);
    } else {
      await this.toggleVar(fieldName);
    }
    const eventDraftId = this.props.eventDraftId;
    await Api.updateDraftBooleanField(eventDraftId, fieldName,
                                      this.state[fieldName])
    this.props.eventDraftUpdated();
  }

  getTimeFrameFromState = () => {
    const timeFrameType = this.state.selectedTimeFrameType;
    const timeFrame = {
      type:      timeFrameType,
      startDate: this.state.startDate,
    };
    if (timeFrameType === TimeFrameType.ONGOING_EVENT) {
      timeFrame.ongoingEvent = true;
    } else if (timeFrameType === TimeFrameType.IS_INSTANT) {
      timeFrame.endDate = this.state.startDate;
    } else {
      timeFrame.endDate = this.state.endDate; 
    }
    return timeFrame;
  }

  selectTimeFrameType = timeFrameTypeOption => {
    const updatedState = {};
    if (timeFrameTypeOption.value === TimeFrameType.ONGOING_EVENT) {
      updatedState.endDate = null;
    } else if (timeFrameTypeOption.value === TimeFrameType.IS_INSTANT) {
      updatedState.endDate = this.state.startDate;
    }
    updatedState.selectedTimeFrameType = timeFrameTypeOption.value;
    this.setState(updatedState, async () => {
      const eventDraftId = this.props.eventDraftId;
      await Api.updateDraftTimeFrameField(eventDraftId, 'type',
                                          this.state.selectedTimeFrameType)
      this.props.eventDraftUpdated();
    });
  }

  selectTimeFrameColor = option => {
    this.setState({timeFrameColor: option.value}, async () => {
      const eventDraftId = this.props.eventDraftId;
      await Api.updateDraftTimeFrameField(eventDraftId, 'color',
                                          this.state.timeFrameColor);
      this.props.eventDraftUpdated();
    });
  }

  reduceMapToOptions = displayTypeMap => {
    return Object.keys(displayTypeMap).reduce((array, optionKey) => {
      array.push({
        value: optionKey,
        label: displayTypeMap[optionKey],
      });
      return array; 
    }, []);
  }

  updateStringField = (fieldName, fieldValue) => {
    const updatedState = {};
    updatedState[fieldName] = fieldValue;
    this.setState(updatedState);
    if (this.editingTimeout) {
      clearTimeout(this.editingTimeout);
      delete this.editingTimeout;
    } 
    this.editingTimeout = setTimeout(() => {
      const eventDraftId = this.props.eventDraftId;
      Api.updateDraftStringField(eventDraftId, fieldName, fieldValue);
    }, 500);
  }

  removeFeatureGroup = (featureGroupHash) => {
    const index = this.state.locations.findIndex(location => {
      return location.hash === featureGroupHash; 
    });
    const updatedState = {
      locations: [...this.state.locations].splice(index, 1),
    };
    this.setState(updatedState, async () => {
      const eventDraftId = this.props.eventDraftId;
      await Api.removeFeatureGroupFromEventDraftLocation(eventDraftId,
                                                         featureGroupHash);
      this.props.eventDraftUpdated();
    });

  }

	render() {
    const timeFrameTypeOptions = this.reduceMapToOptions(DisplayTimeFrameType);
    const selectedTimeFrameTypeOption = timeFrameTypeOptions.find(option => {
      return option.value === this.state.selectedTimeFrameType;
    });
    let timeFrame;
    if (this.state.addTimeFrame) {
      timeFrame = this.getTimeFrameFromState();
    }
    let locationDivs = [];
    if (this.state.locations) {
      locationDivs = this.state.locations.map(location => {
        return (
          <div key={location.hash} className="event-input">
            <div>{location.mapPath} {location.color} {location.features.length}</div>            

          <EditActionButton 
            type="edit"
            onClick={() => this.props.showMapFeatureGroupForm(location.hash)}
            title="Edit"
          /> 
          <EditActionButton 
            type="remove"
            onClick={() => this.removeFeatureGroup(location.hash)}
            title="Remove"
          /> 
          </div>
        );
      });
    }
    const colorOptions = ['red', 'green', 'blue', 'orange', 'purple'];
  	return (
    	<div>
      	<div className="event-input">
          <label className="block-label">Description *</label>
          <LimitedTextarea
            limit={140}
            value={this.state.description} 
            charsPerRow={30}
            updateValue={description => this.updateStringField('description', description)}
          />
        </div>
        <div className="event-input">
          <label className="block-label">Source</label>
          <ExpandableTextarea
            value={this.state.sourceLink} 
            charsPerRow={30}
            updateValue={sourceLink => this.updateStringField('sourceLink', sourceLink)}
          />
        </div>
        <div className="additional-fields"
          style={{boxSizing: 'border-box', padding: '10px', border: '1px solid white'}}>
          <div className={classnames({'sub-header': true})}>
            Time Frame 
            <button
              style={{float: 'right'}}
              onClick={() => this.toggleTimeFrame('addTimeFrame')}>
              { this.state.addTimeFrame ? 'Remove' : 'Add'} 
            </button>
            { this.state.addTimeFrame &&
              <button
                style={{float: 'right'}}
                onClick={() => this.toggleTimeFrame('showTimeFrame')}>
                { this.state.showTimeFrame ? 'Set' : 'Edit'} 
              </button>
            }
          </div>
          { (this.state.addTimeFrame && !this.state.showTimeFrame) &&
            <DisplayTimeFrame
              timeFrame={timeFrame}
            />
          }
          { (this.state.addTimeFrame && this.state.showTimeFrame) &&
            <div>
              <div className="event-input">
                <label>Type</label>
                <Dropdown
                  options={timeFrameTypeOptions}
                  onChange={this.selectTimeFrameType}
                  value={selectedTimeFrameTypeOption}
                  placeholder="Select Map" />
              </div>
              <Dropdown
                options={colorOptions}
                onChange={this.selectTimeFrameColor}
                value={this.state.timeFrameColor}
                placeholder="" />
              <div className="event-input">
                { this.state.selectedTimeFrameType === TimeFrameType.HAS_END_DATE &&
                  <label>Start Date</label>
                }
                { this.state.selectedTimeFrameType === TimeFrameType.IS_INSTANT &&
                  <label>Date</label>
                }
                { this.state.selectedTimeFrameType === TimeFrameType.ONGOING_EVENT &&
                  <label>Start Date</label>
                }
                <DatePicker
                  selected={this.state.startDate}
                  popperPlacement="left"
                  onChange={date => this.handleDateChange('startDate', date)}
                />
              </div>
              { this.state.selectedTimeFrameType === TimeFrameType.HAS_END_DATE &&
                <div className="event-input">
                  <label>End Date</label>
                  <DatePicker
                    selected={this.state.endDate}
                    popperPlacement="left"
                    onChange={date => this.handleDateChange('endDate', date)}
                  />
                </div>
              }
            </div>
          }
        </div>
        { this.props.mapPaths.length > 0 &&
          <div className="additional-fields"
            style={{boxSizing: 'border-box', padding: '10px', border: '1px solid white'}}>
            <div className={classnames({'sub-header': true})}>
              Location 
              <button
                style={{float: 'right'}}
                onClick={() => this.props.showMapFeatureGroupForm()}>
                Add
              </button>
            </div>
            {this.state.locations.length > 0 &&
              <div>
                {locationDivs}
              </div>
            }
          </div>
        }
        <div style={{width: '100%', padding: '5px', textAlign: 'right'}}>
          { this.props.event && 
            <button
              style={{marginRight: '10px'}}
              onClick={this.props.hideForm}>
              Cancel
            </button>
          }
          <button
            style={{marginRight: '10px'}}
            onClick={this.submitForm}>
            { this.props.event ? 'Save' : 'Create'} 
          </button>
        </div>
    	</div>
    );
  }
}
export default EditEventForm;
